import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  VStack,
  Box,
  Text,
  Image,
  IconButton,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Center,
  Link,
  useToast,
  useDisclosure,
  useMediaQuery,
  PopoverCloseButton,
  Icon,
  useClipboard,
} from "@chakra-ui/react";

import { User } from "polyverse-sdk-dev/dist/api/user";
import { Wallet } from "polyverse-sdk-dev/dist/api/wallet";

import { NFT } from "polyverse-sdk-dev/dist/api/nft";

import {
  PiArrowDownBold,
  PiArrowUpBold,
  PiBellBold,
  PiCheckBold,
  PiCopySimpleBold,
  PiCopySimpleFill,
} from "react-icons/pi";

function Notifications() {
  const { t } = useTranslation();
  const userAddress = localStorage.getItem("userAddress") || "";
  const [isMobile] = useMediaQuery("(max-width: 480px)");

  const toast = useToast();
  const { onCopy, hasCopied } = useClipboard("");

  const [networks, setNetworks] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [allTransactions, setAllTransactions] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loadingTransactionId, setLoadingTransactionId] = useState(null);
  const [notificationsOpened, setNotificationsOpened] = useState(false);

  useEffect(() => {
    const handleStorageChange = () => {
      fetchNetworks();
    };

    window.addEventListener("showTestNetChanged", handleStorageChange);

    // Component unmount olduğunda event listener'ı kaldır
    return () => {
      window.removeEventListener("showTestNetChanged", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    fetchNetworks();
  }, []);

  const fetchAllTransactions = async () => {
    setIsLoading(true);
    let allTrans = {};

    try {
      const transactionsPromises = networks.map((network) =>
        Wallet.transactions(network.key, true).then((response) => {
          allTrans[network.key] = response.items;
        })
      );

      await Promise.all(transactionsPromises);
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }

    setAllTransactions(allTrans);
    setIsLoading(false);
  };

  const fetchNetworks = async () => {
    try {
      const fetchedNetworks = await User.networks();
      let showTestNetValue = localStorage.getItem("showTestNet") === "true";
      const showTestNet = showTestNetValue === null ? false : showTestNetValue;
      const filteredNetworks = showTestNet
        ? fetchedNetworks
        : fetchedNetworks.filter((network) => !network.isTestNet);
      setNetworks(filteredNetworks);
    } catch (error) {
      console.error("Error fetching networks", error);
    }
  };

  const handleModalClose = () => {
    onClose();
    setSelectedActivity(null);
  };

  const handleBellClick = () => {
    console.log("Bell clicked.", isOpen);
    if (!notificationsOpened) {
      if (networks.length) {
        console.log("Fetching transactions...");
        fetchAllTransactions();
        setNotificationsOpened(true);
      } else {
        console.log("Fetching not required.");
      }
    } else {
      setNotificationsOpened(false);
    }
  };

  const handleGridItemClick = async (transaction, index) => {
    setSelectedActivity(null);
    setLoadingTransactionId(index);

    const { networkName, contract } = extractNetworkAndContract(transaction);
    let tokenData;
    let tokenValue;
    let tokenSymbol;

    // Sondaki sıfırları temizleyen fonksiyon
    const trimTrailingZeros = (value) => {
      return value.replace(/(\.\d*?[1-9])0+$/, "$1").replace(/\.$/, "");
    };
    // Eğer transaction.value 0'dan farklıysa ve 'native' bir transfer ise
    if (transaction.value !== "0" && !transaction.log_events) {
      const rawTokenValue = transaction.value / 10 ** 18;
      tokenValue =
        rawTokenValue < 0.01
          ? trimTrailingZeros(rawTokenValue.toFixed(18))
          : rawTokenValue.toFixed(2);
      tokenSymbol = transaction.networkSymbol; // AVAX, ETH vb

      setSelectedActivity({
        ...transaction,
        displayValue: tokenValue,
        displaySymbol: tokenSymbol,
        tokenData: null, // Bu bir NFT değil
      });
    } else {
      // Log event'ler içinde 'Transfer' eventi ara
      const transferEvent = transaction.log_events?.find(
        (e) =>
          e.decoded &&
          (e.decoded.name === "Transfer" || e.decoded.name === "LogTransfer") //Logtransfer MATIC için
      );

      if (transferEvent) {
        const tokenIdParam = transferEvent.decoded.params.find(
          (p) => p.name === "tokenId"
        );
        const valueParam = transferEvent.decoded.params.find(
          (p) => p.name === "value" || p.name === "amount"
        );

        if (tokenIdParam) {
          // Eğer tokenId parametresi varsa NFT transferidir
          tokenData = await fetchNFTData(
            networkName,
            contract,
            tokenIdParam.value
          );

          setSelectedActivity({
            ...transaction,
            tokenData,
            displayValue: "N/A", // NFT için miktar yok
            displaySymbol: "NFT",
          });
        } else if (valueParam) {
          // Eğer tokenId yok ama value parametresi varsa ERC20 token transferidir
          const rawTokenValue =
            valueParam.value / 10 ** transferEvent.sender_contract_decimals;
          tokenValue =
            rawTokenValue < 0.01 //0.01'den küçükse noktadan sonra rakamlar bitene kadar bastır
              ? trimTrailingZeros(
                  rawTokenValue.toFixed(transferEvent.sender_contract_decimals)
                )
              : rawTokenValue.toFixed(2);
          tokenSymbol =
            transferEvent.sender_contract_ticker_symbol ||
            `${t("unknown")} Token`;
          console.log(valueParam.value);
          setSelectedActivity({
            ...transaction,
            displayValue: tokenValue,
            displaySymbol: tokenSymbol,
            tokenData: null,
          });
        }
      } else {
        // Log event bulunamazsa veya 'Transfer' eventi yoksa unknown
        setSelectedActivity({
          ...transaction,
          displayValue: t("unknown"),
          displaySymbol: t("unknown"),
          tokenData: null,
        });
      }
    }

    setLoadingTransactionId(null);
    onOpen();
  };

  const sortByDate = (transactions) => {
    return transactions.sort((a, b) => {
      let dateA = new Date(a.block_signed_at);
      let dateB = new Date(b.block_signed_at);
      return dateB - dateA;
    });
  };

  const combinedAndSortedTransactions = () => {
    let allTransactionsArray = [];
    Object.entries(allTransactions).forEach(([networkKey, transactions]) => {
      const network = networks.find((n) => n.key === networkKey) || {};
      transactions.forEach((transaction) => {
        const logEvents = transaction.log_events || [];
        let displayText;
        let isMintTransaction = false;

        // Mint işlemleri için özel kontrol
        for (const event of logEvents) {
          if (
            event.decoded &&
            event.decoded.name === "Transfer" &&
            event.decoded.params.some(
              (param) =>
                param.name === "from" &&
                param.value === "0x0000000000000000000000000000000000000000"
            )
          ) {
            isMintTransaction = true;
            const tokenIdParam = event.decoded.params.find(
              (p) => p.name === "tokenId" || p.name === "_id"
            );
            const tokenId = tokenIdParam ? tokenIdParam.value : t("unknown");
            const tokenSymbol = event.sender_contract_ticker_symbol || "Token";
            displayText = `Mint ${tokenSymbol} #${tokenId}`;
            break; // Mint işlemi bulunduğunda döngüyü sonlandır
          }
        }

        if (!isMintTransaction) {
          // Mint işlemi değilse send receive gösterimi
          if (transaction.value === "0" && logEvents.length > 0) {
            const logEvent = logEvents[0];
            const tokenIdParam = logEvent.decoded.params.find(
              (p) => p.name === "tokenId" || p.name === "_id"
            );
            if (tokenIdParam) {
              displayText = `${
                logEvent.sender_contract_ticker_symbol ||
                `${t("unknown")} Token`
              } #${tokenIdParam.value}`;
            } else {
              const rawTokenValue =
                logEvent.decoded &&
                logEvent.decoded.params.find((p) => p.name === "value")
                  ? (
                      logEvent.decoded.params.find((p) => p.name === "value")
                        .value /
                      10 ** logEvent.sender_contract_decimals
                    ).toFixed(2)
                  : t("unknown") + " " + t("amount");
              displayText =
                rawTokenValue < 0.01 &&
                rawTokenValue !== `${t("unknown")} ${t("amount")}`
                  ? `<0.01 ${
                      logEvent.sender_contract_ticker_symbol ||
                      `${t("unknown")} Token`
                    }`
                  : `${rawTokenValue} ${
                      logEvent.sender_contract_ticker_symbol ||
                      `${t("unknown")} Token`
                    }`;
            }
          } else {
            // Eğer 'value' sıfır değilse, normal değerleri kullan
            const rawTokenValue = (transaction.value / 10 ** 18).toFixed(2);
            displayText =
              rawTokenValue < 0.01
                ? `<0.01 ${network.symbol || "Unknown Token"}`
                : `${rawTokenValue} ${network.symbol || "Unknown Token"}`;
          }
        }

        allTransactionsArray.push({
          ...transaction,
          networkName: network.name || "Network",
          networkSymbol: network.symbol || "Network",
          networkExplorer: network.explorerUrl || "Network",
          displayText,
          isMintTransaction,
        });
      });
    });
    return sortByDate(allTransactionsArray);
  };

  const extractTokenId = (activity) => {
    if (!activity || !activity.log_events) return null;
    for (let event of activity.log_events) {
      if (event.decoded && Array.isArray(event.decoded.params)) {
        const tokenIdParam = event.decoded.params.find(
          (p) => p.name === "tokenId"
        );
        if (tokenIdParam) {
          return tokenIdParam.value;
        }
      }
    }

    return null;
  };

  const extractNetworkAndContract = (activity) => {
    let networkName = activity.networkName || "unknown";
    let contract = "not found";
    const logEvent = activity.log_events && activity.log_events[0];
    if (logEvent) {
      contract = logEvent.sender_address || contract;
    }
    return { networkName, contract };
  };

  const fetchNFTData = async (networkName, contract, tokenId) => {
    try {
      const itemsResponse = await NFT.items(networkName, contract);
      const tokenData = itemsResponse.find((item) => item.tokenId === tokenId);
      if (tokenData && tokenData.image) {
        const imageUrl = new URL("https://api.polyverse.life/NFT/Image");
        imageUrl.searchParams.append("url", tokenData.image);
        imageUrl.searchParams.append("width", "200");
        return { ...tokenData, imageUrl: imageUrl.href };
      }
      return tokenData;
    } catch (error) {
      console.error("Error fetching NFT data:", error);
      return null;
    }
  };

  const formatDateAndTime = (isoString) => {
    const date = new Date(isoString);
    const optionsDate = { year: "numeric", month: "2-digit", day: "2-digit" };
    const optionsTime = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };
    const formattedDate = new Intl.DateTimeFormat("en-GB", optionsDate)
      .format(date)
      .replace(/\//g, ".");
    const formattedTime = new Intl.DateTimeFormat("en-GB", optionsTime).format(
      date
    );
    return `${formattedDate} ${formattedTime}`;
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        toast({
          title: "Copied!",
          description: "Copied to clipboard.",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        onCopy();
      },
      (err) => {
        console.error("Failed to copy text: ", err);
        toast({
          title: "Failed",
          description: "Failed to copy text to clipboard.",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    );
  };

  // DetailSection Component
  const DetailSection = ({ title, value, isLink, link, canCopy }) => (
    <Box mb={4}>
      <Text fontWeight="bold">{title}:</Text>
      <Flex justify="space-between" align="center">
        {isLink && link ? (
          <Link href={link} isExternal>
            <Text fontSize="sm" color="#5263F0" isTruncated>
              {value}
            </Text>
          </Link>
        ) : (
          <Text
            fontWeight="normal"
            color="#888"
            fontSize={["13px", "13px", "15px"]}
            isTruncated
          >
            {value}
          </Text>
        )}

        {canCopy && (
          <>
            {hasCopied ? (
              <PiCopySimpleFill
                ml="4"
                size="20"
                color="#42CF78" // Başarı durumu için renk değişimi
                cursor="pointer"
              />
            ) : (
              <PiCopySimpleBold
                ml="4"
                size="20"
                color="#888"
                cursor="pointer"
                onClick={() => copyToClipboard(value)}
              />
            )}
          </>
        )}
      </Flex>
    </Box>
  );

  // TransactionText Component
  const TransactionText = ({ item, userAddress }) => {
    const { t } = useTranslation();

    const transactionType = item.isMintTransaction
      ? "transaction.mint"
      : item.from_address.toLowerCase() === userAddress.toLowerCase()
      ? "transaction.send"
      : "transaction.receive";

    const transactionStatus =
      item.successful === true
        ? t("transaction.status.succeeded")
        : item.successful === false
        ? t("transaction.status.failed")
        : t("transaction.status.unknown");

    const message = t(transactionType, {
      displayText: item.displayText,
      status: transactionStatus,
      networkName: item.networkName,
    });

    return (
      <Text fontSize={["12px", "12px", "13px", "13px"]} fontWeight="medium">
        {message}
      </Text>
    );
  };

  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          mx="4"
          variant="ghost"
          aria-label="Bell"
          icon={<PiBellBold color="white" size="22" />}
          _hover={{ bg: "#333" }}
          _active={{ bg: "#222" }}
          height={isMobile ? "42px" : "48px"}
          width={isMobile ? "42px" : "48px"}
          onClick={handleBellClick}
        />
      </PopoverTrigger>
      <PopoverContent
        py="3"
        mt={["3", "4", "5"]}
        border="1px solid #333"
        borderRadius="10px"
        bg="#111"
        mr="6"
        w="auto"
        minW="280px"
        overflowY={"auto"}
        px="5"
        h={["550px", "600px", "600px"]}
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          w="full"
          mb="4"
        >
          <Text fontSize={["16px", "16px", "18px"]}>{t("notifications")}</Text>
          <PopoverCloseButton p="4" mt="2" />
        </Flex>

        {/* Transaction Display */}
        <VStack spacing={0}>
          {isLoading ? (
            <Spinner align="center" justifyContent="center" mt="10" />
          ) : (
            combinedAndSortedTransactions().map((item, index) => (
              <Box
                onClick={(e) => handleGridItemClick(item, index, e)}
                border="1px solid #333"
                borderRadius="10px"
                key={index}
                w="full"
                maxWidth="280px"
                bg="transparent"
                mb={4}
                p="3"
                minH="85px"
                _hover={{ bg: "#222" }}
                cursor={loadingTransactionId === index ? "wait" : "pointer"}
              >
                {loadingTransactionId === index ? (
                  <Center>
                    <Spinner
                      textAlign="center"
                      align="center"
                      justifyContent="center"
                    />
                  </Center>
                ) : (
                  <>
                    <Flex align="center" mb="1">
                      <Icon
                        as={
                          item.isMintTransaction
                            ? PiArrowUpBold
                            : item.from_address.toLowerCase() ===
                              userAddress.toLowerCase()
                            ? PiArrowUpBold
                            : PiArrowDownBold
                        }
                        color="#888"
                        mr="1"
                        boxSize={["13px", "13px", "15px", "15px"]}
                      />
                      <Text
                        color="#888"
                        fontSize={["13px", "13px", "15px", "15px"]}
                      >
                        {item.isMintTransaction
                          ? "Mint"
                          : item.from_address.toLowerCase() ===
                            userAddress.toLowerCase()
                          ? t("outgoing")
                          : t("incoming")}
                      </Text>
                    </Flex>
                    <TransactionText item={item} userAddress={userAddress} />
                  </>
                )}
              </Box>
            ))
          )}
        </VStack>

        <Modal isOpen={isOpen} onClose={handleModalClose}>
          <ModalOverlay backdropFilter="blur(2px)" />
          <ModalContent w="84%" bg="#111">
            <ModalHeader pt="6" align="center">
              {t("details")}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody px={[5, 6, 8]} borderRadius="10px">
              {selectedActivity ? (
                <>
                  <Flex justifyContent="space-between" mb="4">
                    <Text fontWeight="bold" fontSize={["md", "md", "lg"]}>
                      {selectedActivity.isMintTransaction
                        ? "Mint"
                        : selectedActivity.from_address.toLowerCase() ===
                          userAddress.toLowerCase()
                        ? t("sent")
                        : t("received")}
                    </Text>
                    <Text
                      fontWeight="medium"
                      color="#888"
                      fontSize={["13px", "13px", "15px"]}
                    >
                      {formatDateAndTime(selectedActivity.block_signed_at)}
                    </Text>
                  </Flex>
                  <Flex align="center">
                    {selectedActivity.tokenData?.imageUrl && (
                      <Box
                        w={["75px", "80px", "85px"]}
                        h={["75px", "80px", "85px"]}
                        border="1px solid #888"
                        borderRadius="10px"
                        mr="4"
                        mb="6"
                        overflow="hidden"
                      >
                        <Image
                          src={selectedActivity.tokenData.imageUrl}
                          alt="NFT"
                          width="auto"
                        />
                      </Box>
                    )}
                    <Text
                      fontWeight="medium"
                      fontSize={["md", "md", "lg"]}
                      mb="6"
                    >
                      {selectedActivity.tokenData
                        ? selectedActivity.tokenData.name
                        : `${
                            selectedActivity.from_address.toLowerCase() ===
                            userAddress.toLowerCase()
                              ? ""
                              : ""
                          } ${selectedActivity.displayValue} ${
                            selectedActivity.displaySymbol
                          }`}
                    </Text>
                  </Flex>
                  {selectedActivity.tokenData && (
                    <DetailSection
                      title={t("tokenId")}
                      value={
                        selectedActivity.tokenData?.tokenId ||
                        extractTokenId(selectedActivity)
                      }
                      canCopy={false}
                    />
                  )}
                  <DetailSection
                    title={t("network")}
                    value={selectedActivity.networkName}
                    canCopy={false}
                  />
                  <DetailSection
                    title={t("transactionStatus")}
                    value={t("viewOnExplorer")}
                    isLink={true}
                    link={`${selectedActivity.networkExplorer}/tx/${selectedActivity.tx_hash}`}
                    canCopy={false}
                  />
                  <DetailSection
                    title={t("transactionHash")}
                    value={selectedActivity.tx_hash}
                    canCopy={true}
                  />
                </>
              ) : (
                <Text>No activity selected.</Text>
              )}
            </ModalBody>

            <ModalFooter
              mt={["6", "10", "12"]}
              mb={["2", "3", "4"]}
              color="#42CF78"
              justifyContent="center"
              fontSize={["md", "md", "lg"]}
            >
              <Flex alignItems="center" justifyContent="center">
                <Box
                  p="0.5"
                  bg="#42CF78"
                  borderRadius="6px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mr="2"
                >
                  <PiCheckBold boxSize="20px" color="white" />
                </Box>
                <Text>
                  {selectedActivity?.successful === true
                    ? t("status") + " " + t("successful")
                    : selectedActivity?.successful === false
                    ? t("status") + " " + t("failed")
                    : t("status") + " " + t("unknown")}
                </Text>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </PopoverContent>
    </Popover>
  );
}

export default Notifications;
