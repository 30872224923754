import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { User } from "polyverse-sdk-dev/dist/api/user";

import {
  useMediaQuery,
  Box,
  Flex,
  Image,
  Text,
  HStack,
  VStack,
  Stack,
  Input,
  Button,
  useToast,
} from "@chakra-ui/react";

import AuthBg from "./AuthBg";
import { Formik, Field, Form } from "formik";
import logo from "../../static/polywallet-logo.svg";
import footerText from "../../static/icons/footer-text.svg";

const VerifyEmail = () => {
  const toast = useToast();
  const { t } = useTranslation();

  const [isMobile] = useMediaQuery("(max-width: 480px)");
  const [isDesktop] = useMediaQuery("(min-width: 1025px)");

  const [showMessage, setShowMessage] = useState(false);

  const userid = localStorage.getItem("userid");
  const email = localStorage.getItem("username");

  //const [timeLeft, setTimeLeft] = useState(120);
  const navigate = useNavigate();
  const initialValues = {
    code1: "",
    code2: "",
    code3: "",
    code4: "",
  };

  const sendCode = async (email) => {
    console.log("userid:", userid);
    console.log("email:", email);
    try {
      const sendCodeResponse = await User.verifyEmailRequest(
        2251,
        userid,
        email
      );
      console.log(sendCodeResponse);
    } catch (error) {
      console.error("Error sending email verification request:", error);
    }
  };

  const handleVerifyEmail = async (value, actions) => {
    const verificationCode = `${value.code1}${value.code2}${value.code3}${value.code4}`;

    try {
      const responseVerify = await User.verifyEmail(
        2251,
        userid,
        verificationCode,
        email
      );

      toast({
        title: t("emailVerified"),
        description: t("emailVerifiedDescription"),
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/");
    } catch (error) {
      console.log(error);

      toast({
        title: t("error"),
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    setShowMessage(true);
    actions.setSubmitting(false);
  };

  const validateForm = () => {
    const errors = {};
    let verificationCode = "";

    if (verificationCode.length > 0 && verificationCode.length !== 4) {
      errors.general = t("verificationCodeLengthError");
    } else if (
      verificationCode.length === 4 &&
      !/^\d{4}$/i.test(verificationCode)
    ) {
      errors.general = t("verificationCodeInvalid");
    }
    return errors;
  };

  const getStyleValue = (desktopValue, mobileValue, isTablet) =>
    isDesktop ? desktopValue : isMobile ? mobileValue : isTablet;

  const paddingX = getStyleValue("200px", "30px", "20px");
  const paddingY = getStyleValue("35px", "30px", "50px");
  const boxWidth = getStyleValue("65%", "90%", "80%");
  const boxHeight = getStyleValue("580px", "80%", "590px");

  const boxStyles = {
    position: "absolute",
    width: boxWidth,
    height: boxHeight,
    px: paddingX,
    py: paddingY,
    borderRadius: "50px",
    border: "3px solid #333",
    background: "#111111B2",
    zIndex: 1,
  };

  return (
    <Flex justifyContent="center" alignItems="center">
      <Box {...boxStyles}>
        <Image
          src={logo}
          position="absolute"
          top={{ base: "30px", md: "30px", lg: "30px" }}
          left={{ base: "30px", md: "40px", lg: "45px" }}
          width={{ base: "100px", md: "130px", lg: "163px" }}
        />
        <Flex justifyContent="center" alignItems="center" direction="column">
          <Stack align="center" mt={{ base: "40px", md: "65px", lg: "80px" }}>
            <Text
              fontWeight="700"
              fontSize="2xl"
              width={{ base: "250px", md: "350px", lg: "393px" }}
            >
              {t("verifyYourEmail")}
            </Text>
            <Text
              fontWeight="400"
              color="#888888"
              fontSize="sm"
              width={{ base: "250px", md: "350px", lg: "393px" }}
              mb={{ base: "10px", md: "15px", lg: "20px" }}
            >
              {t("verifyYourEmailDescription")}
            </Text>
            <VStack spacing={10}>
              <Text
                // mb={{ base: "18px", md: "20px", lg: "25px" }}
                color="#888"
                fontSize="md"
                fontWeight="700"
              >
                {t("verifyCodeEnter")}
              </Text>

              <Formik
                initialValues={initialValues}
                validate={validateForm}
                onSubmit={(values, actions) =>
                  handleVerifyEmail(values, actions)
                }
              >
                {({ setFieldValue, handleSubmit, values, validateForm }) => {
                  return (
                    <Form>
                      <Flex
                        justify="space-between"
                        maxW="300px"
                        gap={{ base: "10px", md: "15px", lg: "20px" }}
                      >
                        {Array.from({ length: 4 }).map((_, index) => (
                          <Field key={index} name={`code${index + 1}`}>
                            {({ field, form }) => (
                              <Box>
                                <Input
                                  {...field}
                                  type="text"
                                  inputMode="numeric"
                                  pattern="[0-9]*"
                                  width={{
                                    base: "45px",
                                    md: "50px",
                                    lg: "55px",
                                  }}
                                  height={{
                                    base: "45px",
                                    md: "50px",
                                    lg: "55px",
                                  }}
                                  textAlign="center"
                                  fontSize={{ base: "lg", md: "xl", lg: "2xl" }}
                                  fontWeight="bold"
                                  color="#1E1E1E"
                                  backgroundColor="white"
                                  borderColor={
                                    form.errors[field.name] &&
                                    form.touched[field.name]
                                      ? "red.500"
                                      : "gray.300"
                                  }
                                  borderRadius="md"
                                  maxLength={1}
                                  _focus={{
                                    borderColor: "blue.500",
                                    boxShadow: "0 0 0 1px blue.500",
                                  }}
                                  onChange={async (e) => {
                                    const value = e.target.value;
                                    console.log(
                                      `Input ${index + 1} changed: ${value}`
                                    );
                                    if (/^\d$/.test(value)) {
                                      setFieldValue(field.name, value);
                                      if (index < 3) {
                                        const nextInput =
                                          e.target.form.elements[index + 1];
                                        nextInput && nextInput.focus();
                                      } else {
                                        const allInputsFilled = Array.from(
                                          e.target.form.elements
                                        )
                                          .slice(0, 4)
                                          .every((input) =>
                                            /^\d$/.test(input.value)
                                          );

                                        //

                                        if (allInputsFilled) {
                                          const errors = await validateForm();
                                          console.log(
                                            "Validation errors:",
                                            errors
                                          );
                                          if (
                                            Object.keys(errors).length === 0
                                          ) {
                                            console.log(
                                              "Form is valid, submitting"
                                            );
                                            handleSubmit();
                                          } else {
                                            console.log(
                                              "Form is invalid, not submitting"
                                            );
                                          }
                                        }
                                      }
                                    }
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === "Backspace") {
                                      const currentInput = e.target;
                                      const prevInput =
                                        e.target.form.elements[index - 1];
                                      if (index > 0 && !currentInput.value) {
                                        prevInput && prevInput.focus();
                                      } else {
                                        setFieldValue(field.name, "");
                                      }
                                    }
                                  }}
                                />
                                {form.errors[field.name] &&
                                  form.touched[field.name] && (
                                    <Text fontSize="sm" color="red.500">
                                      {form.errors[field.name]}
                                    </Text>
                                  )}
                              </Box>
                            )}
                          </Field>
                        ))}
                      </Flex>
                    </Form>
                  );
                }}
              </Formik>
              <Flex alignItems="center" justifyContent="space-between">
                <HStack spacing={2}>
                  <Text fontSize="md">Didn't get a code? </Text>
                  <Button
                    variant="link"
                    onClick={() => sendCode(email)}
                    fontSize="md"
                    color="#753CC5"
                  >
                    {t("resendCode")}
                  </Button>
                </HStack>
              </Flex>
            </VStack>

            <Image
              src={footerText}
              bottom="30px"
              right="50px"
              position="absolute"
              width={{ base: "100px", md: "120px", lg: "150px" }}
              height={{ base: "auto", md: "auto", lg: "20px" }}
            />
          </Stack>
        </Flex>
      </Box>
      <AuthBg />
    </Flex>
  );
};
export default VerifyEmail;
