import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import {
  Grid,
  Box,
  Image,
  Text,
  Spinner,
  Flex,
  AspectRatio,
} from "@chakra-ui/react";

import { NFT } from "polyverse-sdk-dev/dist/api/nft";
import { Contract } from "polyverse-sdk-dev/dist/api/contract";

const Card = (drop) => {
  const navigate = useNavigate();
  const [imgPreview, setImgPreview] = useState("");

  const getImage = async (network, contract) => {
    try {
      const resultURI = await Contract.execute(
        network,
        contract,
        "tokenURI",
        { tokenId: "0" },
        "raw"
      );
      if (resultURI.data) {
        const ipfsUrl = `https://api.polyverse.life/storage/IPFS/${resultURI.data.replace(
          "ipfs://",
          ""
        )}`;
        const resultIPFS = await axios.get(ipfsUrl);
        setImgPreview(
          `https://api.polyverse.life/nft/image?url=${resultIPFS.data.image}&width=600`
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getImage(drop.item.network, drop.item.contract);
  }, []);

  return (
    <Box
      position="relative"
      bg="#010101"
      border="2px"
      borderColor="#333"
      borderRadius="10px"
      overflow="hidden"
      p={4}
      maxH="320px"
      _hover={{ bg: "#222" }}
      cursor={"pointer"}
      onClick={() => {
        navigate(
          `/nft-mint?network=${drop.item.network}&contract=${drop.item.contract}`
        );
      }}
    >
      <Box bg={"#753cc5"} w="full" h="170px" borderRadius={"10px"}>
        {drop.item.banner ? (
          <AspectRatio ratio={16 / 9} w="full" h="170px">
            <Image borderRadius="lg" src={drop.item.banner} objectFit="cover" />
          </AspectRatio>
        ) : (
          <>
            {imgPreview !== "" ? (
              <AspectRatio ratio={16 / 9} w="full" h="170px">
                <Image borderRadius="lg" src={imgPreview} objectFit="cover" />
              </AspectRatio>
            ) : (
              <Flex
                w="full"
                h="170px"
                justifyContent="center"
                alignItems="center"
              >
                <Spinner />
              </Flex>
            )}
          </>
        )}
      </Box>
      <Box pt="2" pb="5" m="1">
        <Text
          fontWeight="bold"
          fontSize="xl"
          mb={1}
          textAlign="center"
          color="white"
        >
          {drop.item.name}
        </Text>
        <Text fontSize="sm" textAlign="center" fontWeight="normal" color="#888">
          {drop.item.description}
        </Text>
      </Box>
    </Box>
  );
};

function NFTDrops() {
  const [drops, setDrops] = useState([]);

  useEffect(() => {
    const fetchDrops = async () => {
      const dropsResult = await NFT.drops();
      setDrops(dropsResult);
    };

    fetchDrops();
  }, []);

  return (
    <Grid
      templateColumns={{
        sm: "repeat(1, 1fr)",
        md: "repeat(2, 1fr)",
        lg: "repeat(4, 1fr)",
      }}
      gap={6}
    >
      {drops.map((drop, index) => (
        <Card key={index} item={drop} />
      ))}
    </Grid>
  );
}

export default NFTDrops;
