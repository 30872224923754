import React, { useState, useEffect, useCallback } from "react";

import { useTranslation } from "react-i18next";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  Image,
  AspectRatio,
  Flex,
  Box,
  Input,
  Divider,
  Grid,
  Button,
  Spinner,
  Stack,
  ModalFooter,
  FormLabel,
  Tooltip,
  Link,
} from "@chakra-ui/react";

import { User } from "polyverse-sdk-dev/dist/api/user";
import { System } from "polyverse-sdk-dev/dist/api/system";
import { Wallet } from "polyverse-sdk-dev/dist/api/wallet";
import { Security } from "polyverse-sdk-dev/dist/api/security";

import { useToast } from "@chakra-ui/react";
import {
  PiArrowLeftBold,
  PiShieldCheckBold,
  PiShieldSlashBold,
  PiShieldWarningBold,
  PiWalletBold,
} from "react-icons/pi";

function SendNfts({ isOpen, onClose, item, networks, onBack, handleNFTSent }) {
  const toast = useToast();
  const { t } = useTranslation();

  const [address, setAddress] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [initialTransferDone, setInitialTransferDone] = useState(false);
  const [gasFee, setGasFee] = useState("");
  const [totalFeeInUSDT, setTotalFeeInUSDT] = useState(null);
  const [isCalculating, setIsCalculating] = useState(false);
  const selectedNetwork = networks.find(
    (network) => network.key === item.networkKey
  );
  const networkIcon = selectedNetwork ? selectedNetwork.icon : "";
  const [transactionHash, setTransactionHash] = useState("");
  const [toAddress, setToAddress] = useState("");
  const [estimatedFee, setEstimatedFee] = useState(null);
  const [securityInfo, setSecurityInfo] = useState({
    rate: null,
    riskResult: null,
  });
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tokenId, setTokenId] = useState(null);

  useEffect(() => {
    if (transactionHash) {
      // transactionHash güncellendiğinde yapılacak işlemler
      console.log("Transaction Hash:", transactionHash);
      // Burada modal açma veya başka bir eylemi tetikleyebilirsiniz
    }
  }, [transactionHash]);

  useEffect(() => {
    const addressType = checkAddressType(address);
    if (addressType === "web3") {
      // Eğer adres tipi web3 ise, gas hesaplamasını yap
      calculateTransferNFTGas(address);
    } else {
      setGasFee("");
      setTotalFeeInUSDT(null);
    }
  }, [address]);

  useEffect(() => {
    const fetchPriceAndCalculateFee = async () => {
      if (selectedNetwork && gasFee) {
        setIsCalculating(true);
        try {
          const pair = `${item.networkSymbol.toUpperCase()}USDT`;
          const price = await checkPrice(pair);

          if (price) {
            const totalFeeInUSDTValue = gasFee * price;

            setTotalFeeInUSDT(totalFeeInUSDTValue.toFixed(2));
            console.log(totalFeeInUSDT);
          } else {
            setTotalFeeInUSDT(null); // If price information can't be fetched, show nothing
          }
        } catch (error) {
          console.error("Error in fetching price and calculating fee: ", error);
          setTotalFeeInUSDT(null); // reset total fee
        } finally {
          setIsCalculating(false);
        }
      } else {
        setTotalFeeInUSDT(null);
      }
    };

    fetchPriceAndCalculateFee();
  }, [gasFee]);

  const resolveImageUrl = (nftMeta) => {
    let imageUrl =
      nftMeta && nftMeta.image ? nftMeta.image : "defaultImagePath";

    // IPFS linkini kontrol et
    if (
      imageUrl.startsWith("https://ipfs.io/ipfs/") ||
      imageUrl.startsWith("ipfs://")
    ) {
      // URL'i "/" ile böl ve parçaları al
      const parts = imageUrl.split("/");
      // Son parçayı al (IPFS hash veya dosya adı)
      const lastPart = parts[parts.length - 1];

      // Eğer son parça bir dosya adıysa veya ekstra bir yol içeriyorsa, URL'i olduğu gibi kullan
      if (lastPart.includes(".") || parts.length > 5) {
        return `https://api.polyverse.life/NFT/Image?url=${imageUrl.replace(
          "https://ipfs.io",
          "http://192.248.179.139:8080"
        )}&width=600`;
      }

      // Sadece IPFS hash içeriyorsa, yeni URL formatını kullan
      return `https://api.polyverse.life/storage/ipfs/${lastPart.replace(
        "https://ipfs.io",
        "http://192.248.179.139:8080"
      )}/600`;
    }

    return `https://api.polyverse.life/NFT/Image?url=${imageUrl.replace(
      "https://ipfs.io",
      "http://192.248.179.139:8080"
    )}&width=600`;
  };

  const checkPrice = async (pair) => {
    try {
      const priceResponse = await System.price(pair);

      // priceResponse dizisinin ilk elemanını kontrol et
      if (priceResponse && priceResponse.length > 0) {
        const priceData = priceResponse[0];
        return priceData.price; // Fiyatı döndür
      } else {
        console.error("Fiyat bilgisi bulunamadı.");
        return null;
      }
    } catch (error) {
      console.error("Price Error: ", error);
      return null;
    }
  };

  //#region Security
  const SecurityCheck = useCallback(
    async (walletAddress) => {
      try {
        const networkKey = selectedNetwork.key;
        const addressCheckResult = await Security.AddressCheck(
          networkKey,
          walletAddress,
          2251,
          "wxcguoTzCmuuBdezpAVElaCeZd"
        );
        setSecurityInfo({
          rate: addressCheckResult.rate,
          riskResult: addressCheckResult.riskResult,
        });
      } catch (err) {
        console.error(err);
      }
    },
    [selectedNetwork.key]
  );

  useEffect(() => {
    if (checkAddressType(address) === "web3") {
      SecurityCheck(address);
    }
  }, [address, SecurityCheck]);

  useEffect(() => {
    // securityInfo güncellendiğinde yapılacak işlemler
    //Icon değiştir
  }, [securityInfo.rate]); // Sadece securityInfo.rate değiştiğinde çalışacak

  const getSecurityIcon = () => {
    if (securityInfo.rate === null) {
      return null; // Eğer rate null ise hiçbir şey gösterme
    } else if (securityInfo.rate <= 3) {
      return <PiShieldSlashBold color="green" size="20" />; // 0-3 için Tick İkonu
    } else if (securityInfo.rate <= 7) {
      return <PiShieldWarningBold color="yellow" size="20" />; // 4-7 için Arrow İkonu
    } else {
      return <PiShieldCheckBold color="green" size="20" />; // 8-10 için ArrowRight İkonu
    }
  };
  //#endregion

  const checkAddressType = (address) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const walletAddressRegex = /^0x[a-fA-F0-9]{40}$/;

    if (emailRegex.test(address)) {
      return "email";
    } else if (walletAddressRegex.test(address)) {
      return "web3";
    } else if (
      !isNaN(address) &&
      address.length >= 10 &&
      address.length <= 15
    ) {
      return "phone";
    } else {
      //console.error("Invalid address format.");
      return null;
    }
  };

  const handleProfile = async (type, value) => {
    try {
      const profileData =
        type === "email"
          ? await User.profileByEmail(2251, "wxcguoTzCmuuBdezpAVElaCeZd", value)
          : await User.profileByPhone(
              2251,
              "wxcguoTzCmuuBdezpAVElaCeZd",
              value
            );

      return { walletAddress: profileData.walletAddress, isNewUser: false };
    } catch (error) {
      console.error(`${type} query error:`, error);
      //profile bulunamazsa varolan bilgilerle userCreate ile yeni user yarat
      if (error.message.includes("Sequence contains no elements")) {
        return userCreate(
          type === "email" ? value : null,
          type === "phone" ? value : null
        );
      }
      return { walletAddress: null, isNewUser: false };
    }
  };

  const generateRandomPassword = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 8; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const userCreate = async (email, phone) => {
    const generatedPassword = generateRandomPassword();
    const name = email
      ? email.split("@")[0]
      : `User${phone.substring(0, 3)}...${phone.slice(-3)}`;

    const userData = {
      email,
      password: generatedPassword,
      name,
      phone,
      projectId: 2251,
      projectSecret: "wxcguoTzCmuuBdezpAVElaCeZd",
    };

    try {
      const createdUser = await User.create(
        userData.email,
        userData.password,
        userData.name,
        userData.phone,
        "",
        userData.projectId,
        userData.projectSecret
      );

      if (createdUser && createdUser.walletAddress) {
        return {
          walletAddress: createdUser.walletAddress,
          isNewUser: true,
          generatedPassword,
        };
      }
      return { walletAddress: null, isNewUser: false };
    } catch (error) {
      console.error("User creation error:", error);
    }
    return null;
  };

  //#region notifications
  const sendNotification = async (address, message) => {
    const addressType = checkAddressType(address);
    if (addressType === "email") {
      await sendEmail(address, message);
    } else if (addressType === "phone") {
      await sendSMS(address, message);
    }
  };

  const sendEmail = async (email, message) => {
    try {
      const emailResponse = await System.email(
        email,
        "PolyWallet Transfer Notification",
        message
      );
      console.log("Email sent successfully", emailResponse);
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  const sendSMS = async (phone, message) => {
    try {
      const smsResponse = await System.SMS(phone, message);
      console.log("SMS sent successfully:", smsResponse);
    } catch (error) {
      console.error("Error sending SMS:", error);
    }
  };
  //#endregion

  //#region  Transfer
  //transfer tipine karar ver ve transferi tamamlayıp ilgili bildirim kanalını çalıştır
  const performTransferNFT = async (
    walletAddress,
    isNewUser,
    generatedPassword
  ) => {
    try {
      setIsLoading(true); // Yükleme başladı
      await transferNFT(walletAddress);
      // Transfer başarılıysa email-sms gönderimi
      const message = isNewUser
        ? t("message.welcomeNewUser", { password: generatedPassword })
        : t("message.newTransfer");
      sendNotification(address, message);
      // Başarılı işlem mesajı ve durum sıfırlama işlemleri
    } catch (error) {
      console.error("Transfer error:", error);
      // Hata mesajı ve durum sıfırlama işlemleri
    } finally {
      setIsLoading(false); // Yükleme bitti
    }
  };

  const calculateTransferNFTGas = async (walletAddress) => {
    try {
      const transferNFTResponse = await Wallet.transferNFT(
        item.networkKey,
        item.contract,
        walletAddress,
        item.nftMeta.tokenId,
        undefined,
        true
      );
      setGasFee(transferNFTResponse.gas.estimateTransactionFee);
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });

      console.error("Transfer error:", error);
      setGasFee(""); // Hata durumunda gas ücretini sıfırla
    }
  };

  const transferNFT = async (walletAddress) => {
    try {
      const transferNFTResponse = await Wallet.transferNFT(
        item.networkKey,
        item.contract,
        walletAddress,
        item.nftMeta.tokenId,
        undefined,
        false
      );

      if (transferNFTResponse.transaction.transactionHash != null) {
        setTransactionHash(transferNFTResponse.transaction.transactionHash);
        setTokenId(item.nftMeta.tokenId);

        setIsSuccessModalOpen(true);

        console.log("response", transferNFTResponse);
      }
    } catch (error) {
      console.error("Transfer error:", error);
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  //#endregion

  const handleAddressChange = (e) => {
    setAddress(e.target.value);

    const addressType = checkAddressType(e.target.value);
    if (addressType === "phone" && !e.target.value.startsWith("+")) {
      // "+" işareti ile başlamayan telefon numarası için açıklayıcı bir hata toast mesajı göster
      toast({
        title: "Invalid Phone Number",
        description: "Phone numbers should start with the country code (+).",
        status: "error",
        duration: 5000, // Toast mesajının ne kadar süre görüneceğini belirtin (ms cinsinden)
        isClosable: true, // Kullanıcının toast mesajını kapatmasına izin verin
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const addressType = checkAddressType(address);

    if (addressType === "web3") {
      await performTransferNFT(address);
    } else if (addressType === "email" || addressType === "phone") {
      if (addressType === "phone") {
        if (!address.startsWith("+")) {
          toast({
            title: "Invalid Phone Number",
            description:
              "Phone numbers should start with the country code (+).",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          // "+" ile başlayan telefon numarasını "00" ile başlayan formata dönüştür
          const modifiedAddress = address.replace("+", "00");

          const profileResult = await handleProfile(
            addressType,
            modifiedAddress
          ); // modifiedAddress'i kullan
          if (profileResult.walletAddress) {
            await performTransferNFT(
              profileResult.walletAddress,
              profileResult.isNewUser,
              profileResult.generatedPassword
            );
            console.log(profileResult.walletAddress);
          }
        }
      } else {
        const profileResult = await handleProfile(addressType, address);
        if (profileResult.walletAddress) {
          await performTransferNFT(
            profileResult.walletAddress,
            profileResult.isNewUser,
            profileResult.generatedPassword
          );
          console.log(profileResult.walletAddress);
        }
      }
    } else {
      console.error("Invalid address type.");
    }
  };

  const handleModalClose = () => {
    setInitialTransferDone(false);
    setEstimatedFee(null);
    setToAddress("");
    setErrorMessage("");
    onClose();
  };

  const handleBackToSend = () => {
    setInitialTransferDone(false);
    setEstimatedFee(null);
    setToAddress("");
    setErrorMessage("");
    onBack();
    onClose();
  };

  const closeAllModals = () => {
    setIsSuccessModalOpen(false);
    setInitialTransferDone(false);
    setEstimatedFee(null);
    setToAddress("");
    setErrorMessage("");
    onClose();
    handleNFTSent(tokenId);
  };

  useEffect(() => {
    setErrorMessage("");
  }, [toAddress]);

  if (!item) return null;

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={handleBackToSend}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <ModalOverlay />
        <ModalContent w="84%" bg="#111" mx="auto" maxW="450px">
          <ModalHeader>
            <Flex alignItems="center">
              <PiArrowLeftBold
                onClick={
                  initialTransferDone ? handleBackToSend : handleModalClose
                }
                boxSize="30px"
                cursor="pointer"
              />
              <Text flex={1} textAlign="center" mx={4}>
                {t("backToDetails")}
              </Text>
              <Box width="24px" />
            </Flex>
          </ModalHeader>

          <ModalBody
            fontSize="sm"
            textAlign="center"
            pb="6"
            px={[5, 6, 8]}
            borderRadius="10px"
          >
            <AspectRatio ratio={1} maxW="200px" minW="120px" margin="0 auto">
              <Image
                src={resolveImageUrl(item.nftMeta)}
                alt={item.nftMeta.name}
                objectFit="cover"
                borderRadius="md"
              />
            </AspectRatio>
            <Text mt="2">#{item.nftMeta.tokenId}</Text>
            <Text
              color="white"
              fontWeight="bold"
              fontSize={["16px", "16px", "18px"]}
            >
              {item.nftMeta.name} NFT
            </Text>
            <Flex
              direction="column"
              fontSize={["13px", "14px"]}
              fontWeight="medium"
              mb="4"
            >
              <Flex justifyContent="space-between">
                {(isCalculating || totalFeeInUSDT) && (
                  <Text>{t("estGasFee")}:</Text>
                )}

                <Flex justifyContent="flex-end">
                  {isCalculating ? (
                    <Text>Checking...</Text>
                  ) : totalFeeInUSDT ? (
                    <Text>
                      {Number(gasFee).toFixed(8)} {item.networkSymbol}
                    </Text>
                  ) : (
                    <Text>{"\u00A0"}</Text>
                  )}
                </Flex>
              </Flex>

              {totalFeeInUSDT && (
                <Flex justifyContent="flex-end" mt={1}>
                  <Text
                    color="#888"
                    fontWeight="medium"
                    fontSize={["12px", "13px"]}
                  >
                    {Number(totalFeeInUSDT).toFixed(4)} USDT
                  </Text>
                </Flex>
              )}
            </Flex>

            <Box
              mt="1"
              border="1px"
              borderColor="#333333"
              borderRadius="md"
              bg="#111111"
            >
              <Grid
                templateColumns="auto minmax(0, 1fr) auto"
                gap={2}
                p="3"
                alignItems="center"
              >
                <Text align="start" fontSize="sm" color="white">
                  {t("network")}
                </Text>
                <Flex alignItems="center" gridGap="2">
                  <Image
                    src={`data:image/svg+xml;base64,${networkIcon}`}
                    boxSize={"24px"}
                  />
                  <Text fontSize="sm">{item.networkSymbol}</Text>
                </Flex>
              </Grid>

              <Divider borderColor="#333333" opacity="1.0" />

              <Grid
                templateColumns="auto minmax(0, 1fr)"
                gap={2}
                p="3"
                alignItems="center"
              >
                <Flex alignItems="center" gridGap="1">
                  <Text align="start" fontSize="sm" color="white">
                    {t("to")}
                  </Text>
                  <Flex align="center" mb="1">
                    {securityInfo.rate !== null && (
                      <Tooltip
                        placement="auto"
                        label={`Rate: ${securityInfo.rate}, Risk: ${securityInfo.riskResult}`}
                        fontSize="md"
                      >
                        <Box>{getSecurityIcon()}</Box>
                      </Tooltip>
                    )}
                  </Flex>
                  <PiWalletBold boxSize={"24px"} />
                </Flex>
                <Input
                  placeholder="Mobile, email or address"
                  variant="unstyled"
                  _placeholder={{ color: "#888888" }}
                  color="white"
                  fontSize="sm"
                  width="100%"
                  //value={toAddress}
                  onChange={handleAddressChange}
                  disabled={initialTransferDone} // disable when "Send Now"
                />
              </Grid>
            </Box>
            {errorMessage && (
              <Text color="red" fontSize="sm" textAlign="center">
                {errorMessage}
              </Text>
            )}
            {estimatedFee && (
              <Flex justifyContent="space-between" my="4">
                <Text fontSize="xs">{t("networkFee")}</Text>
                <Text fontSize="xs">{estimatedFee}</Text>
              </Flex>
            )}
            <Flex justifyContent="center" mt={6} mb={2}>
              <Button
                w={"60%"}
                variant={"pvOutlined"}
                disabled={!toAddress.trim() || isLoading}
                bg="#111"
                _disabled={{
                  bgColor: "#888888",
                  _hover: { bg: "#888888" },
                  cursor: "not-allowed",
                }}
                onClick={handleSubmit}
              >
                {isLoading ? <Spinner size="sm" color="white" /> : "Send"}
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      {isSuccessModalOpen && (
        <Modal
          display="flex"
          justifyContent="center"
          alignItems="center"
          isOpen={isSuccessModalOpen}
          onClose={closeAllModals}
        >
          <ModalOverlay backdropFilter="blur(2px)" />
          <ModalContent w="84%" bg="#111" mx="auto" maxW="450px" py="4">
            <ModalHeader />
            <ModalCloseButton />
            <ModalBody
              px="6"
              pb="6"
              fontSize="sm"
              color="#888888"
              textAlign="center"
              position="relative"
            >
              <AspectRatio ratio={1} maxW="200px" minW="120px" margin="0 auto">
                <Image
                  src={resolveImageUrl(item.nftMeta)}
                  alt={item.nftMeta.name}
                  objectFit="cover"
                  borderRadius="md"
                />
              </AspectRatio>

              <Stack
                position="absolute"
                bottom="40%"
                right={["16%", "20%", "22%"]}
                zIndex="2"
              >
                <Box
                  bg="#111111"
                  borderRadius="50%"
                  border="1px solid #333333"
                  width="70px"
                  height="70px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  padding="2px"
                >
                  {/* tick */}
                  <svg height="100%" width="100%" viewBox="0 0 50 50">
                    <defs>
                      <linearGradient
                        id="grad1"
                        x1="0%"
                        y1="0%"
                        x2="100%"
                        y2="0%"
                      >
                        <stop
                          offset="50%"
                          style={{
                            stopColor: "#753CC5",
                            stopOpacity: 1,
                            animation:
                              "gradientShimmer 1.8s infinite alternate",
                          }}
                        />
                        <stop
                          offset="100%"
                          style={{
                            stopColor: "#10F2B0",
                            stopOpacity: 1,
                            animation:
                              "gradientShimmerReverse 1.8s infinite alternate",
                          }}
                        />
                      </linearGradient>
                    </defs>
                    <path
                      id="animatedPath"
                      d="M10 25 L20 35 L40 15"
                      stroke="url(#grad1)"
                      strokeWidth="6"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="transparent"
                    />
                  </svg>
                </Box>
              </Stack>

              <Text color="white" fontSize="lg" fontWeight="bold" mt="9">
                {item.nftMeta.name}
              </Text>
              <Text>#{item.nftMeta.tokenId}</Text>
              <Text color="white" fontSize="lg" fontWeight="bold" mt="8">
                {t("txSucceded")}
              </Text>
              <Link
                fontSize="sm"
                color="#5263F0"
                isTruncated
                href={`${item.networkExplorerUrl}tx/${transactionHash}`}
                isExternal
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("viewOnExplorer")}
              </Link>
            </ModalBody>
            <ModalFooter
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                w={"60%"}
                variant="pvOutlined"
                bg="#111"
                colorScheme="blue"
                onClick={closeAllModals}
              >
                {t("done")}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

export default SendNfts;
