import React from "react";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { User } from "polyverse-sdk-dev/dist/api/user";

import {
  useMediaQuery,
  Box,
  Flex,
  Image,
  Text,
  Stack,
  Input,
  VStack,
  useToast,
} from "@chakra-ui/react";

import { Formik, Field, Form } from "formik";
import AuthBg from "./AuthBg";
import logo from "../../static/polywallet-logo.svg";
import footerText from "../../static/icons/footer-text.svg";

const TwoFactorAuth = () => {
  const { t } = useTranslation();

  const [isMobile] = useMediaQuery("(max-width: 480px)");
  const [isDesktop] = useMediaQuery("(min-width: 1025px)");
  const navigate = useNavigate();
  const toast = useToast();

  const initialValues = {
    code1: "",
    code2: "",
    code3: "",
    code4: "",
  };

  const validateForm = (values) => {
    const errors = {};
    for (let i = 1; i <= 4; i++) {
      const fieldName = `code${i}`;
      if (!values[fieldName]) {
        errors[fieldName] = t("required");
      } else if (!/^\d$/i.test(values[fieldName])) {
        errors[fieldName] = t("invalidCodeTwoFactor");
      }
    }
    return errors;
  };

  const handleLogin = async (code) => {
    const phone = localStorage.getItem("phone");
    if (phone) {
      try {
        var loginResponse = await User.login(
          2251,
          "wxcguoTzCmuuBdezpAVElaCeZd",
          undefined,
          undefined,
          phone,
          code
        );

        console.log(loginResponse);
        localStorage.setItem("token", loginResponse.token);
        navigate("/");
      } catch (error) {
        toast({
          title: t("loginFailed"),
          description: error.message || "An error occurred while logging in.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: t("loginFailed"),
        description: "An error occurred while logging in.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const onSubmit = (values) => {
    const concatenatedValues = Object.values(values).join("");
    handleLogin(concatenatedValues);
  };

  const getStyleValue = (desktopValue, mobileValue, isTablet) =>
    isDesktop ? desktopValue : isMobile ? mobileValue : isTablet;

  const paddingX = getStyleValue("200px", "30px", "20px");
  const paddingY = getStyleValue("60px", "30px", "50px");
  const boxWidth = getStyleValue("65%", "90%", "80%");
  const boxHeight = getStyleValue("580px", "80%", "590px");

  const boxStyles = {
    position: "absolute",
    width: boxWidth,
    height: boxHeight,
    px: paddingX,
    py: paddingY,
    borderRadius: "50px",
    border: "3px solid #333",
    background: "#111111B2",
    zIndex: 1,
  };

  return (
    <Flex justifyContent="center" alignItems="center">
      <Box {...boxStyles}>
        <Image
          src={logo}
          position="absolute"
          top={{ base: "30px", md: "30px", lg: "30px" }}
          left={{ base: "30px", md: "40px", lg: "45px" }}
          width={{ base: "100px", md: "130px", lg: "163px" }}
        />
        <Flex justifyContent="center" alignItems="center" direction="column">
          <Stack align="center" mt={{ base: "40px", md: "45px", lg: "50px" }}>
            <Text
              fontWeight="700"
              fontSize="2xl"
              width={{ base: "250px", md: "350px", lg: "393px" }}
            >
              {t("twoFactorAuthTitle")}
            </Text>
            <Text
              fontWeight="400"
              color="#888888"
              fontSize="sm"
              width={{ base: "250px", md: "350px", lg: "393px" }}
              mb={{ base: "10px", md: "15px", lg: "20px" }}
            >
              {t("twoFactorPlease")}
            </Text>

            {/*buradan sonra input girme alanı */}
            <VStack spacing={{ base: "10px", md: "14px", lg: "18px" }}>
              <Text
                //mb={{ base: "15px", md: "26px", lg: "18px" }}
                color="#888"
                fontSize="md"
                fontWeight="700"
              >
                {t("enterVerificationCode")}
              </Text>

              <Formik
                initialValues={initialValues}
                validate={validateForm}
                onSubmit={onSubmit}
              >
                {({ handleSubmit, setFieldValue, values, errors, touched }) => (
                  <Form>
                    <Flex
                      justify="space-between"
                      gap={{ base: "15px", md: "18px", lg: "20px" }}
                    >
                      {Array.from({ length: 4 }, (_, index) => (
                        <Field key={index} name={`code${index + 1}`}>
                          {({ field, form }) => (
                            <Input
                              {...field}
                              type="text"
                              inputMode="numeric"
                              pattern="[0-9]*"
                              width={{ base: "50px", md: "50px", lg: "55px" }}
                              height={{ base: "40px", md: "50px", lg: "55px" }}
                              textAlign="center"
                              fontSize={{ base: "lg", md: "xl", lg: "2xl" }}
                              fontWeight="bold"
                              color="#1E1E1E"
                              backgroundColor="white"
                              borderColor={
                                form.errors[field.name] &&
                                form.touched[field.name]
                                  ? "red.500"
                                  : "gray.300"
                              }
                              borderRadius="md"
                              maxLength={1}
                              _focus={{
                                borderColor: "blue.500",
                                boxShadow: "0 0 0 1px blue.500",
                              }}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d$/.test(value)) {
                                  setFieldValue(field.name, value).then(() => {
                                    if (index === 3) {
                                      // 4. input alanını kontrol et
                                      form.validateForm().then((errors) => {
                                        if (Object.keys(errors).length === 0) {
                                          handleSubmit();
                                        }
                                      });
                                    } else {
                                      const nextInput =
                                        e.target.form[index + 1];
                                      nextInput && nextInput.focus();
                                    }
                                  });
                                }
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "Backspace") {
                                  const currentInput = e.target;
                                  const prevInput = e.target.form[index - 1];
                                  if (index > 0 && !currentInput.value) {
                                    prevInput && prevInput.focus();
                                  } else {
                                    setFieldValue(field.name, "");
                                  }
                                }
                              }}
                              onPaste={(e) => {
                                e.preventDefault();

                                // Pano içeriğini al
                                const paste = e.clipboardData.getData("text");

                                // Pano içeriği sadece rakamlardan oluşuyorsa ve uzunluğu 4 ise işlem yap
                                if (
                                  paste.length === 4 &&
                                  /^\d{4}$/.test(paste)
                                ) {
                                  const fieldUpdates = Array.from(paste).map(
                                    (char, index) => {
                                      const fieldName = `code${index + 1}`;

                                      return Promise.all([
                                        form.setFieldValue(
                                          fieldName,
                                          char,
                                          false
                                        ),
                                        form.setFieldTouched(
                                          fieldName,
                                          true,
                                          false
                                        ),
                                      ]);
                                    }
                                  );

                                  // Tüm alanlar güncellendikten sonra formu doğrula ve gerekirse submit et
                                  Promise.all(fieldUpdates).then(() => {
                                    form.validateForm().then((errors) => {
                                      if (Object.keys(errors).length === 0) {
                                        form.submitForm();
                                      }
                                    });
                                  });
                                }
                              }}
                            />
                          )}
                        </Field>
                      ))}
                    </Flex>
                    <button type="submit" style={{ display: "none" }}>
                      {t("submit")}
                    </button>
                  </Form>
                )}
              </Formik>
            </VStack>

            <Flex
              position="absolute"
              alignItems="center"
              textAlign="center"
              justify="center"
              //mb={{ base: "10px", md: "15px", lg: "20px" }}
            ></Flex>
            <Image
              src={footerText}
              bottom="30px"
              right="50px"
              position="absolute"
              width={{ base: "100px", md: "120px", lg: "150px" }}
              height={{ base: "auto", md: "auto", lg: "20px" }}
            />
          </Stack>
        </Flex>
      </Box>
      <AuthBg />
    </Flex>
  );
};
export default TwoFactorAuth;
