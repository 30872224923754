import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import { shortenAddress } from "../../services/Helpers";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Center,
  Spinner,
  Avatar,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Grid,
  Image,
  Link,
  useToast,
  Flex,
  useClipboard,
  useBreakpointValue,
  useMediaQuery,
} from "@chakra-ui/react";

import {
  PiArrowDownBold,
  PiArrowUpBold,
  PiCheckBold,
  PiCopySimpleBold,
} from "react-icons/pi";

function PortfolioNfts({ allTokens, isLoading }) {
  const toast = useToast();
  const { t } = useTranslation();

  const [isSmallScreen] = useMediaQuery("(max-width: 680px)");
  const isLgScreen = useBreakpointValue({ base: false, md: false, lg: true });
  const [selectedToken, setSelectedToken] = useState(null);
  const [clipboardText, setClipboardText] = useState("");
  const { hasCopied, onCopy } = useClipboard(clipboardText);

  const [copiedStates, setCopiedStates] = useState({});

  const groupedTokens = groupByContract(
    allTokens.filter(
      (token) => token.type === "ERC-1155" || token.type === "ERC-721"
    )
  );

  function groupByContract(tokens) {
    return tokens.reduce((acc, token) => {
      const contract = token.contract;
      if (!acc[contract]) {
        acc[contract] = {
          contract: contract,
          collectionName: token.name,
          price: token.price,
          currency: token.priceCurrency,
          vol: token.vol24h,
          change24h: token.change24h,
          icon: token.icon,
          networkIcon: token.networkIcon,
          tokens: [],
          network: token.network,
        };
      }
      acc[contract].tokens.push(token);
      return acc;
    }, {});
  }

  const nftCollections = Object.values(groupedTokens);

  const handleTokenClick = (token) => {
    console.log("Token clicked: ", token); // Check if this logs correctly
    setSelectedToken(token);
  };

  // 24H Change Format
  const formatChange24h = (change24h) => {
    if (change24h === null) return { text: "—", color: "#888", arrow: null };
    const isPositive = change24h >= 0;
    const color = isPositive ? "#4CAF50" : "#F44336"; // Green or Red
    const sign = isPositive ? "+" : "-";
    const arrow = isPositive ? PiArrowUpBold : PiArrowDownBold;
    return { text: `${sign}${Math.abs(change24h).toFixed(2)}%`, color, arrow };
  };

  const resolveImageUrl = (nftMeta) => {
    let imageUrl =
      nftMeta && nftMeta.image ? nftMeta.image : "defaultImagePath";

    // IPFS linkini kontrol et
    if (
      imageUrl.startsWith("https://ipfs.io/ipfs/") ||
      imageUrl.startsWith("ipfs://")
    ) {
      // URL'i "/" ile böl ve parçaları al
      const parts = imageUrl.split("/");
      // Son parçayı al (IPFS hash veya dosya adı)
      const lastPart = parts[parts.length - 1];

      // Eğer son parça bir dosya adıysa veya ekstra bir yol içeriyorsa, URL'i olduğu gibi kullan
      if (lastPart.includes(".") || parts.length > 5) {
        return `https://api.polyverse.life/NFT/Image?url=${imageUrl.replace(
          "https://ipfs.io",
          "http://192.248.179.139:8080"
        )}&width=600`;
      }

      // Sadece IPFS hash içeriyorsa, yeni URL formatını kullan
      return `https://api.polyverse.life/storage/ipfs/${lastPart.replace(
        "https://ipfs.io",
        "http://192.248.179.139:8080"
      )}/600`;
    }

    return `https://api.polyverse.life/NFT/Image?url=${imageUrl.replace(
      "https://ipfs.io",
      "http://192.248.179.139:8080"
    )}&width=600`;
  };

  const handleCopyClick = (text, itemId) => {
    setClipboardText(text);
    onCopy();

    setCopiedStates((prevStates) => ({ ...prevStates, [itemId]: true }));

    // Reset the copied state after a certain time if needed
    setTimeout(() => {
      setCopiedStates((prevStates) => ({ ...prevStates, [itemId]: false }));
    }, 2000); // Reset after 2 seconds

    const displayText = text.length > 10 ? `${text.slice(0, 10)}...` : text;
    toast({
      title: "Copied!",
      description: `${displayText} copied to clipboard.`,
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  // DetailSection Component
  const DetailSection = ({ title, value, hasCopy, isLink, link }) => (
    <Box mb={4}>
      <Text fontWeight="bold">{title}:</Text>
      <Flex justify="space-between" align="center">
        {isLink && link ? (
          <Link href={link} isExternal>
            <Text fontSize="sm" color="#5263F0">
              {value}
            </Text>
          </Link>
        ) : (
          <Text
            fontWeight="normal"
            color="#888"
            fontSize={["13px", "13px", "15px"]}
          >
            {hasCopy ? shortenAddress(value) : value}
          </Text>
        )}
        {hasCopy && (
          <Box onClick={() => handleCopyClick(value, value)} cursor="pointer">
            {copiedStates[value] ? (
              <PiCheckBold color="white" h="6" w="6" />
            ) : (
              <PiCopySimpleBold color="white" h="6" w="6" />
            )}
          </Box>
        )}
      </Flex>
    </Box>
  );

  return (
    <>
      <Box>
        {isLoading ? (
          <Center>
            <Spinner size="lg" color="#753CC5" mt="3" mb="14" />
          </Center>
        ) : nftCollections.length === 0 ? (
          <Center w="100%">
            <Text
              my="10"
              align="center"
              fontWeight="normal"
              fontSize={isLgScreen ? "16px" : "14px"}
            >
              {t("noNFTs")}
            </Text>
          </Center>
        ) : (
          <>
            <Flex
              fontSize={["16px", "16px", "19px"]}
              color="#888"
              fontWeight="thin"
              w="full"
              align="center"
              textAlign="center"
              justifyContent="space-between"
              px={["2", "4", "12"]}
              py={["1", "2", "3"]}
            >
              {/* Header titles */}
              <Text isTruncated align="start" w="24%">
                {t("collection")}
              </Text>

              <Text isTruncated w="24%">
                {t("floorPrice")}
              </Text>

              <Text isTruncated w="24%">
                {t("currency")}
              </Text>

              <Text align="center" isTruncated w="28%">
                {t("volume")}
              </Text>

              <Text isTruncated align="center" w="17%">
                {isLgScreen ? t("_24HChange") : t("_24H")}
              </Text>
            </Flex>
            <Accordion borderTop="1px solid #2B2B2B" allowToggle>
              {nftCollections.map((collection, index) => {
                const { text, color } = formatChange24h(collection.change24h);
                return (
                  <AccordionItem
                    key={index}
                    borderBottom="1px solid #2B2B2B"
                    border="0"
                  >
                    <AccordionButton px={["2", "4", "12"]}>
                      <Flex
                        w="full"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box
                          w={"24%"}
                          display="flex"
                          alignItems="flex-start"
                          overflow={"hidden"}
                        >
                          <Box position="relative">
                            <Avatar
                              name={collection.collectionName}
                              w={"53px"}
                              h={"53px"}
                              borderRadius="11px"
                              src={collection.icon}
                            />
                            <Image
                              src={`data:image/svg+xml;base64,${collection.networkIcon}`}
                              alt="Network Icon"
                              boxSize="20px" // Boyutunu isteğinize göre ayarlayın
                              position="absolute"
                              right="0" // Sağ alt köşeye yaslamak için
                              bottom="-3" // Sağ alt köşeye yaslamak için
                              transform="translate(50%, -50%)" // Sağ alt köşenin biraz üzerine getirmek için
                              zIndex="1"
                              bgColor={"#fff"}
                              borderRadius={"full"}
                            />
                          </Box>
                          <Box
                            ml={4}
                            mt={3}
                            maxWidth="calc(100% - 53px - 16px)"
                            overflow="hidden"
                            alignItems="center"
                          >
                            {!isSmallScreen && (
                              <Text
                                isTruncated
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                {collection.collectionName}
                              </Text>
                            )}
                          </Box>
                        </Box>

                        <Box w={"24%"}>
                          <Text textAlign="center" flex="1">
                            {collection.price === null ||
                            collection.price === undefined
                              ? "—"
                              : collection.price}
                          </Text>
                        </Box>

                        <Box w={"24%"}>
                          <Text textAlign="center" flex="1">
                            {collection.currency || "—"}
                          </Text>
                        </Box>

                        <Box w={"28%"}>
                          <Text textAlign="center" flex="1">
                            {collection.vol === null ||
                            collection.vol === undefined
                              ? "—"
                              : collection.vol}
                          </Text>
                        </Box>

                        <Box w={"17%"}>
                          <Flex
                            textAlign="end"
                            flex="0.5"
                            w={"21%"}
                            justifyContent="center"
                            align="center"
                            width="100%"
                            height="100%"
                          >
                            {isLgScreen ? (
                              <Box
                                textAlign="center"
                                px="4"
                                py="2"
                                borderRadius="6px"
                                w="auto"
                                h="auto"
                                bg={text !== "—" ? color : "transparent"}
                              >
                                <Flex
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <Text
                                    isTruncated
                                    fontSize={["14px", "14px", "18px"]}
                                    fontWeight="bold"
                                    color={text !== "—" ? "white" : color}
                                  >
                                    {text}
                                  </Text>
                                </Flex>
                              </Box>
                            ) : (
                              <Text
                                fontSize={["14px", "14px", "18px"]}
                                fontWeight="bold"
                                color={color}
                              >
                                {text}
                              </Text>
                            )}
                          </Flex>
                        </Box>
                      </Flex>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel
                      px={["10", "16", "20"]}
                      justifyContent="center"
                      align="center"
                    >
                      <Grid
                        width="full"
                        templateColumns={{
                          base: "repeat(auto-fill, minmax(120px, 1fr))",
                          md: "repeat(4, 1fr)",
                          lg: "repeat(7, 1fr)",
                        }}
                        autoRows="minmax(100px, auto)"
                        autoFlow="row dense"
                        gap={4}
                        justifyItems="center"
                        justifyContent="center"
                        alignItems="center"
                      >
                        {collection.tokens.map((token, tokenIndex) => {
                          const imageUrl = resolveImageUrl(token.nftMeta);
                          return (
                            <Flex
                              key={tokenIndex}
                              direction="column"
                              align="center"
                              mb={4}
                              justifyItems="center"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Box
                                _hover={{ cursor: "pointer", opacity: 0.5 }}
                                cursor="pointer" // Make it look clickable
                                onClick={() => handleTokenClick(token)}
                                position="relative"
                                borderRadius="11px"
                                bg="#888"
                                w={["75px", "70px", "80px"]}
                                h={["75px", "70px", "80px"]}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                overflow="visible"
                              >
                                <Image
                                  src={imageUrl}
                                  alt={`NFT ${token.nftMeta.name}`}
                                  objectFit="cover"
                                  borderRadius="11px"
                                  boxSize="full"
                                />
                                {token.networkIcon && (
                                  <Box
                                    position="absolute"
                                    bottom="3px"
                                    right="-4px"
                                    w={["15px", "19px", "18px"]}
                                    h={["15px", "19px", "18px"]}
                                    borderRadius="full"
                                    zIndex="1"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    overflow="visible"
                                    p="0"
                                  >
                                    <Image
                                      src={`data:image/svg+xml;base64,${token.networkIcon}`}
                                      alt="Network Icon"
                                      boxSize="full"
                                      zIndex="2"
                                    />
                                  </Box>
                                )}
                              </Box>

                              <Text
                                mt={2}
                                textAlign="center"
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                {token.nftMeta.name}
                              </Text>
                            </Flex>
                          );
                        })}
                      </Grid>
                    </AccordionPanel>
                  </AccordionItem>
                );
              })}
            </Accordion>
          </>
        )}
      </Box>

      <Modal
        isOpen={selectedToken !== null}
        onClose={() => setSelectedToken(null)}
      >
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent w="84%" bg="#111">
          <ModalHeader pt="6" align="center">
            {t("details")}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody px={[5, 6, 8]} borderRadius="10px" pb={[5, 6, 8]}>
            {selectedToken ? (
              <>
                <Text mb="4">{selectedToken?.nftMeta.name || "No Name"}</Text>
                <Image
                  src={
                    selectedToken ? resolveImageUrl(selectedToken.nftMeta) : ""
                  }
                  width="auto"
                />
                <Text fontWeight="medium" fontSize={["md", "md", "lg"]} my="6">
                  {selectedToken?.name || "No Name"} {"-"}
                  {selectedToken?.nftMeta.name || "No Name"}
                </Text>
                <DetailSection
                  isTruncated
                  title={t("description")}
                  value={selectedToken?.nftMeta.description || ""}
                />
                <DetailSection
                  title={t("tokenId")}
                  value={`#${selectedToken?.nftMeta.tokenId || ""}`}
                  hasCopy={true}
                />
                <DetailSection
                  title={t("contract")}
                  value={selectedToken?.networkName}
                />
                <DetailSection
                  title={t("contractAddress")}
                  value={selectedToken?.contract}
                  hasCopy={true}
                />
              </>
            ) : (
              <Text>No NFT selected.</Text>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default PortfolioNfts;
