import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import {
  Box,
  Text,
  Button,
  Flex,
  useBreakpointValue,
  Spinner,
} from "@chakra-ui/react";

import { User } from "polyverse-sdk-dev/dist/api/user";
import { Wallet } from "polyverse-sdk-dev/dist/api/wallet";

const defaultChartData = [
  { timestamp: "2024-01-01", totalQuote: 900 },
  { timestamp: "2024-01-02", totalQuote: 750 },
  { timestamp: "2024-01-03", totalQuote: 500 },
  { timestamp: "2024-01-04", totalQuote: 570 },
  { timestamp: "2024-01-05", totalQuote: 450 },
  { timestamp: "2024-01-06", totalQuote: 900 },
  { timestamp: "2024-01-07", totalQuote: 700 },
  { timestamp: "2024-01-08", totalQuote: 500 },
  { timestamp: "2024-01-09", totalQuote: 700 },
  { timestamp: "2024-01-10", totalQuote: 900 },
  { timestamp: "2024-01-11", totalQuote: 400 },
];

const PortfolioBalance = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [data, setData] = useState([]);
  const [activeView, setActiveView] = useState("MONTHLY");
  const [networks, setNetworks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const currency = localStorage.getItem("userCurrency") || "usd";
  const [totalPortfolioBalances, setTotalPortfolioBalances] = useState([]);
  const isMd = useBreakpointValue({ base: true, lg: false });
  const [isBalanceError, setIsBalanceError] = useState(false);

  const handleTabChange = (tabName) => {
    navigate(`/transfer?tab=${tabName}`);
  };

  useEffect(() => {
    const fetchNetworks = async () => {
      try {
        const networksResponse = await User.networks();
        if (networksResponse && networksResponse.length > 0) {
          const filteredNetworks = networksResponse.filter(
            (network) => !network.isTestNet
          );
          setNetworks(filteredNetworks);
          await fetchBalances(filteredNetworks);
        } else {
          setData(defaultChartData);
        }
      } catch (error) {
        console.error("Network fetch error:", error);
        setData(defaultChartData);
      } finally {
        setIsLoading(false);
      }
    };
    fetchNetworks();
  }, []);

  const fetchBalances = async (networks) => {
    try {
      const allPortfolios = await Promise.all(
        networks.map((network) => fetchWalletBalance(network.key))
      );
      calculateTotalPortfolioBalances(allPortfolios);
    } catch (error) {
      console.error("Error fetching all balances:", error);
    }
  };

  const fetchWalletBalance = async (networkKey) => {
    try {
      const pBalance = await Wallet.balance(
        networkKey,
        currency,
        false,
        false,
        true
      );
      return pBalance.portfolio;
    } catch (error) {
      if (error) {
        setIsBalanceError(true);
      }

      console.error("Wallet balance fetch error:", error);
      return [];
    }
  };

  const calculateTotalPortfolioBalances = (allPortfolios) => {
    const totalsByDate = {};

    allPortfolios.flat().forEach((entry) => {
      try {
        const date = entry.timestamp.split("T")[0];
        if (!totalsByDate[date]) {
          totalsByDate[date] = {
            timestamp: date,
            totalQuote: 0,
            currency: entry.currency,
          };
        }
        totalsByDate[date].totalQuote += entry.quote;
      } catch (error) {}
    });

    const sortedTotals = Object.values(totalsByDate).sort(
      (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
    );
    setTotalPortfolioBalances(sortedTotals);
  };

  useEffect(() => {
    updateChartData(activeView);
  }, [totalPortfolioBalances, activeView]);

  const updateChartData = (range) => {
    try {
      const slicedData =
        range === "WEEKLY"
          ? totalPortfolioBalances.slice(-7)
          : totalPortfolioBalances;
      setData(slicedData.length > 0 ? slicedData : defaultChartData);
    } catch (error) {
      console.error("Error updating chart data:", error);
      setData(defaultChartData);
    }
  };

  const formatDate = (date) => {
    if (!date) {
      return t("invalidDate");
    }

    const parsedDate = new Date(date);
    if (isNaN(parsedDate)) {
      return t("invalidDate");
    }

    const locale = i18n.language || "en";
    const formatter = new Intl.DateTimeFormat(locale, {
      month: "long",
      day: "numeric",
    });
    return formatter.format(parsedDate);
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "rgba(0,0,0,0.9)",
            padding: "5px",
            border: "1px solid #ccc",
          }}
        >
          <p className="label">{`${t("balance")}: ${payload[0].value.toFixed(
            2
          )} ${currency}`}</p>
          <p className="intro">{`${t("date")}: ${formatDate(label)}`}</p>
        </div>
      );
    }
    return null;
  };
  const latestTotal = data[data.length - 1]?.totalQuote || 0;
  const baseInterval = useBreakpointValue({ base: 7, md: 2 });
  const wInterval = useBreakpointValue({ base: 1, md: 0 });
  const xAxisInterval = activeView === "WEEKLY" ? wInterval : baseInterval;
  const xAxisAngle = useBreakpointValue({ base: -45, md: 0 });
  const chartMarginBottom = useBreakpointValue({ base: 35, md: 5 });
  const containerWidth = useBreakpointValue({ base: "100%", md: "100%" });
  const axisMargin = useBreakpointValue({ base: 20, md: 30 });
  const axisLMargin = useBreakpointValue({ base: -20, md: -10 });
  const tickMargin = useBreakpointValue({ base: 10, md: 5 });
  const xAxisFontSize = useBreakpointValue({ base: "13px", md: "15px" });
  const showOverlay = (!isLoading && networks.length === 0) || isBalanceError;

  return (
    <Box position="relative" my="10">
      {isLoading ? (
        <Flex width="100%" height="320px" align="center" justify="center">
          <Spinner size="xl" color="#753CC5" />
        </Flex>
      ) : (
        <>
          <ResponsiveContainer width={containerWidth} height={320}>
            <LineChart
              data={data}
              margin={{
                top: 50,
                right: axisMargin,
                left: axisLMargin,
                bottom: chartMarginBottom,
              }}
            >
              <XAxis
                dataKey="timestamp"
                tickFormatter={(value) => formatDate(value)}
                interval={xAxisInterval}
                angle={xAxisAngle}
                textAnchor={xAxisAngle ? "end" : "middle"}
                tick={{ fontSize: xAxisFontSize }}
                tickMargin={tickMargin}
              />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Line
                type="monotone"
                dataKey="totalQuote"
                stroke="#8884d8"
                strokeWidth={2}
                dot={false}
              />
            </LineChart>
            <Flex>
              <Box
                style={{
                  position: "absolute",
                  top: 0,
                  left: 20,
                  zIndex: 0,
                }}
              >
                {`${t("total")} ${t("balance")}: ${latestTotal.toFixed(
                  2
                )} ${currency}`}
              </Box>
              <Box
                style={{ position: "absolute", top: 0, right: 20, zIndex: 0 }}
              >
                <Flex borderRadius="lg" gap="2" px="2">
                  <Button
                    bg={activeView === "MONTHLY" ? "#333" : "transparent"}
                    border="1px solid #333"
                    _hover={{ bg: "#222", border: "1px solid #222" }}
                    p="2"
                    color="white"
                    size="md"
                    onClick={() => setActiveView("MONTHLY")}
                  >
                    {isMd ? "1" + t("shortMonth") : t("lastMonth")}
                  </Button>
                  <Button
                    color="white"
                    bg={activeView === "WEEKLY" ? "#333" : "transparent"}
                    border="1px solid #333"
                    _hover={{ bg: "#222", border: "1px solid #222" }}
                    size="md"
                    p="2"
                    onClick={() => setActiveView("WEEKLY")}
                  >
                    {isMd ? "1" + t("shortWeek") : t("lastWeek")}
                  </Button>
                </Flex>
              </Box>
            </Flex>
          </ResponsiveContainer>

          {showOverlay && (
            <Flex
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              w="100%"
              h="100%"
              direction="column"
              textAlign="center"
              align="center"
              justify="center"
              backdropFilter="blur(2.5px)"
              zIndex={2}
            >
              <Text fontSize={["20px", "24px", "32px"]} mb="14px">
                {t("trackYourPortfolio")}
              </Text>
              <Button
                paddingX="8"
                w="auto"
                fontSize={{ base: "md", lg: "lg" }}
                bg="#111"
                border="1px solid #333"
                variant="pvGray"
                onClick={() => handleTabChange("receive")}
                _hover={{ bg: "#333" }}
                _active={{ bg: "#222" }}
              >
                {t("deposit")}
              </Button>
            </Flex>
          )}
        </>
      )}
    </Box>
  );
};

export default PortfolioBalance;
