import React, { useState, useEffect, useRef } from "react";

import axios from "axios";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { User } from "polyverse-sdk-dev/dist/api/user";

import {
  Text,
  useMediaQuery,
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
  VStack,
  Button,
  HStack,
  Image,
  Switch,
  FormControl,
  FormLabel,
  Divider,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  InputLeftElement,
  useToast,
} from "@chakra-ui/react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

import flags from "../datas/Flags";
import countryCodes from "../datas/CountryCodes";

import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useDisclosure } from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";
import { FaMediumM } from "react-icons/fa";

import {
  PiCaretRightBold,
  PiCheckBold,
  PiMagnifyingGlassBold,
  PiSealCheckBold,
} from "react-icons/pi";
import { FaDiscord, FaXTwitter } from "react-icons/fa6";

const Styles = {
  panelButton: {
    variant: "ghost",
    justifyContent: "space-between",
    width: "full",
    height: "80px",
    borderRadius: "10px",
    border: "2px solid #333",
    background: "#111111",
    _hover: { color: "white", bg: "#333" },
    _active: { color: "white", bg: "#222" },
  },
  boxStyles: {
    bg: "#888",
    width: "full",
    height: "full",
    minHeight: "650px",
    //overflowY: "auto",
    overflow: "hidden",
    borderRadius: "8px ",
    border: "2px solid #333333",
    background: "#010101",
    zIndex: 1,
  },
  inputStyles: {
    width: {
      base: "300px",
      md: "350px",
      lg: "394px",
    },
    height: {
      base: "45px",
      md: "50px",
      lg: "55px",
    },
    border: "1px solid",
    fontSize: "md",
    fontWeight: "400",
    background: "white",
    color: "#888",
  },
  continueButtonStyle: {
    width: {
      base: "200px",
      md: "275px",
      lg: "325px",
    },
    height: {
      base: "45px",
      md: "50px",
      lg: "58px",
    },
    borderRadius: "10px",
    border: "1px solid #333",
    background: "#111",
    color: "#888",
    fontWeight: "700",
    fontSize: "md",
    _hover: { color: "white", bg: "#111" },
    _active: { color: "white", bg: "#222" },
  },
};

const textStyles = {
  tabTextStyle: {
    fontWeight: "700",
    fontSize: { base: "16px", md: "18px", lg: "20px" },
    mr: { base: "-15px", md: "20px", lg: "80px" },
    _selected: { color: "white" },
    color: "#888888",
  },
  boxNormalText: {
    fontSize: { base: "15px", md: "15px", lg: "16px" },
    fontWeight: "400",
    color: "#FFF",
  },
  boxBoldText: {
    fontSize: { base: "16px", md: "16px", lg: "18px" },
    fontWeight: "700",
    color: "#FFF",
  },
};

function PinInputComponent({ onComplete, isCodeSent }) {
  const [pin, setPin] = useState(new Array(4).fill(""));
  const inputs = useRef([]);

  const focusNext = (index, value) => {
    // Eğer mevcut input doluysa ve değer silinmişse, bir sonraki input'a odaklan.
    if (value) {
      if (index < 3) {
        inputs.current[index + 1].focus();
      }
    } else if (index > 0) {
      // Eğer mevcut input boşsa ve backspace'e basıldıysa, bir önceki input'a odaklan.
      inputs.current[index - 1].focus();
    }
    updatePin(index, value);
  };

  const backspaceHandler = (index, e) => {
    if (e.key === "Backspace" && !pin[index] && index > 0) {
      // Yeni eklenen kontrol, şimdiki ve bir önceki input'u temizlemek için.
      const newPin = [...pin];
      newPin[index] = "";
      newPin[index - 1] = "";
      setPin(newPin);
      inputs.current[index - 1].focus();
    }
  };

  const updatePin = (index, value) => {
    const newPin = [...pin];
    newPin[index] = value;
    setPin(newPin);
    if (newPin.every((num) => num.length === 1)) {
      onComplete(newPin.join(""));
    }
  };

  return (
    <HStack justifyContent={"center"}>
      {pin.map((digit, index) => {
        return (
          <Input
            mt="50px"
            key={index}
            ref={(ref) => (inputs.current[index] = ref)}
            type="tel"
            maxLength="1"
            value={digit}
            onChange={(e) => focusNext(index, e.target.value)}
            onKeyDown={(e) => backspaceHandler(index, e)}
            placeholder=""
            textAlign="center"
            bg="white"
            color="black"
            caretColor="black"
            fontWeight="bold"
            width="56px"
            height="56px"
            borderRadius="md"
            border="1px"
            borderColor="gray.300"
            _focus={{
              borderColor: "blue.500",
              boxShadow: "0 0 0 1px #3182ce",
            }}
          />
        );
      })}
    </HStack>
  );
}

const Profile = ({
  isKYCVerified,
  isMobile,
  name,
  userid,
  email,
  phone,
  emailVerified,
  phoneVerified,
  verified,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isEmailVerified, setIsEmailVerified] = useState(emailVerified);
  const [isPhoneVerified, setIsPhoneVerified] = useState(phoneVerified);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [askGas, setAskGas] = useState(false);
  const [showTestNet, setShowTestNet] = useState(
    localStorage.getItem("showTestNet") === "true"
  );
  const [currencies, setCurrencies] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const toast = useToast();
  const [selectedCurrency, setSelectedCurrency] = useState("USD");
  const [emailCooldown, setEmailCooldown] = useState(0);

  const [phoneCooldown, setPhoneCooldown] = useState(0);
  const [phoneState, setPhoneState] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("TR");
  const [phoneCode, setPhoneCode] = useState("+90");
  const [phoneNumber, setPhoneNumber] = useState(phoneCode);

  const {
    isOpen: isEmailModalOpen,
    onOpen: onEmailModalOpen,
    onClose: onEmailModalClose,
  } = useDisclosure();

  const {
    isOpen: isPhoneModalOpen,
    onOpen: onPhoneModalOpen,
    onClose: onPhoneModalClose,
  } = useDisclosure();

  const {
    isOpen: isCurrencyModalOpen,
    onOpen: onCurrencyModalOpen,
    onClose,
  } = useDisclosure();

  const handleCountrySelect = (country) => {
    setSelectedCountry(country.short);
    setPhoneCode(country.code);
    // Ülke seçildiğinde, telefon numarasını güncelle
    setPhoneNumber(country.code + phoneNumber.substring(phoneCode.length));
  };

  const handlePhoneNumberChange = (e) => {
    const input = e.target.value;

    if (input.startsWith(phoneCode)) {
      const userInput = input.slice(phoneCode.length);

      const onlyDigits = userInput.replace(/[^0-9]/g, "");

      setPhoneNumber(phoneCode + onlyDigits);
    } else if (input.length < phoneCode.length) {
      setPhoneNumber(phoneCode);
    }
  };

  // Modal kapatıldığında çalışacak fonksiyon
  const onCurrencyModalClose = () => {
    setSearchTerm(""); // searchTerm state'ini sıfırla
    onClose(); // Modal'ı kapat
  };

  // Modal'ın kapanma durumunu izle ve searchTerm'ü sıfırla
  useEffect(() => {
    if (!isCurrencyModalOpen) {
      setSearchTerm(""); // Modal kapandığında searchTerm'ü sıfırla
    }
  }, [isCurrencyModalOpen]);

  // Filtering currencies based on search term
  const filteredCurrencies = currencies.filter((currency) => {
    const name = currency.name?.toLowerCase() || ""; // Safeguarding with optional chaining and fallback
    const code = currency.code?.toLowerCase() || ""; // Safeguarding with optional chaining and fallback
    const searchLower = searchTerm.toLowerCase();

    return name.includes(searchLower) || code.includes(searchLower);
  });

  const sendCode = async (email) => {
    if (emailCooldown > 0) {
      toast({
        title: t("pleaseWait"),
        description: t("message.waitEmailCooldown", { emailCooldown }),
        status: "info",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    try {
      var response = await User.verifyEmailRequest(2251, userid, email);
      setIsCodeSent(true);

      toast({
        title: t("message.vefiricationCodeSent"),
        description: response,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: t("message.verificationCodeFailed"),
        description: error.message || "An unknown error occurred",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setEmailCooldown(60);
    startCountdown(setEmailCooldown);
  };

  const handleVerifyComplete = async (verifyCode) => {
    if (verifyCode === "" || !verifyCode) {
      console.log(verifyCode);
      toast({
        title: t("message.verificationFailed"),
        description: t("message.verificationPleaseEnterCode"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });

      return;
    }

    try {
      var response = await User.verifyEmail(2251, userid, verifyCode, email);

      setIsEmailVerified(true);
      localStorage.setItem("emailVerified", true);

      onEmailModalClose();

      toast({
        title: t("message.verifiedEmail"),
        description: response,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: t("message.verificationFailed"),
        description: error.message || "An unknown error occurred",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const startCountdown = (setCooldown) => {
    const intervalId = setInterval(() => {
      setCooldown((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(intervalId);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
  };

  const sendCodePhone = async (phone) => {
    if (phoneCooldown > 0) {
      toast({
        title: t("pleaseWait"),
        description: t("message.waitEmailCooldown"),
        status: "info",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      if (phone === "" || !phone) {
        toast({
          title: t("message.verificationFailed"),
          description: t("message.pleaseEnterPhone"),
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      const formattedPhoneNumber = phoneNumber.replace("+", "00");

      console.log(formattedPhoneNumber);
      var response = await User.verifyPhoneRequest(2251, formattedPhoneNumber);

      toast({
        title: t("message.verificationCodeSent"),
        description: response,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      setIsCodeSent(true);
    } catch (error) {
      toast({
        title: t("message.verificationFailed"),
        description: error.message || "An unknown error occurred",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setPhoneCooldown(60);
    startCountdown(setPhoneCooldown);
    setPhoneState(phone);
  };

  const handleVerifyCompletePhone = async (verifyCodePhone) => {
    if (verifyCodePhone === "" || !verifyCodePhone) {
      toast({
        title: t("message.verificationFailed"),
        description: t("message.verificationPleaseEnterCode"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });

      return;
    }

    if (phoneState === null) {
      toast({
        title: t("message.verificationFailed"),
        description: t("message.verificationPleaseEnterPhone"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });

      return;
    }

    try {
      const formattedPhoneNumber = phoneNumber.replace("+", "00");
      console.log(formattedPhoneNumber);
      const response = await User.verifyPhone(
        2251,
        verifyCodePhone,
        formattedPhoneNumber
      );

      toast({
        title: t("message.verifiedPhone"),
        description: response.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      setIsPhoneVerified(true);
      onPhoneModalClose();

      localStorage.setItem("phoneVerified", true);
    } catch (error) {
      toast({
        title: t("message.verificationFailed"),
        description: error.message || "An unknown error occurred",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Fetching currencies from API on component mount
  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const response = await axios.get(
          "https://api.polyverse.life/system/ExchangeRates"
        );
        const rates = response.data.data.rates;
        const fiatCurrencies = Object.keys(rates)
          .map((key) => ({ code: key, ...rates[key] }))
          .filter((currency) => currency.type === "fiat");

        setCurrencies(fiatCurrencies);

        // Attempt to load the saved currency from local storage
        const savedCurrency = localStorage.getItem("userCurrency");
        const foundCurrency = fiatCurrencies.find(
          (currency) => currency.code === savedCurrency
        );

        if (foundCurrency) {
          setSelectedCurrency(savedCurrency); // Set the saved currency if it's found
        } else {
          // Find and set to "USD" from fetched data as default if no valid saved currency
          const defaultCurrency = fiatCurrencies.find(
            (currency) => currency.code === "usd"
          );
          if (defaultCurrency) {
            setSelectedCurrency(defaultCurrency.code);
            localStorage.setItem("userCurrency", defaultCurrency.code); // Save "USD" as default
          } else {
            console.error("USD currency not found in API data"); // Handle case where "USD" isn't found
          }
        }
      } catch (error) {
        console.error("Error fetching currencies", error);
      }
    };

    fetchCurrencies();
  }, []);

  const handleCurrencyClick = (currencyCode) => {
    const newSelectedCurrency =
      selectedCurrency === currencyCode ? selectedCurrency : currencyCode;

    setSelectedCurrency(newSelectedCurrency);
    localStorage.setItem("userCurrency", newSelectedCurrency);
    onCurrencyModalClose();
  };

  const navigagetToKYC = () => {
    navigate("/kyc");
  };

  useEffect(() => {
    setShowTestNet(localStorage.getItem("showTestNet") === "true");
  }, []);

  const handleTestnetSwitchChange = () => {
    setShowTestNet((prev) => {
      const newValue = !prev;
      localStorage.setItem("showTestNet", newValue.toString());
      window.dispatchEvent(new Event("showTestNetChanged"));
      return newValue;
    });
  };

  return (
    <VStack spacing="20px">
      {/* Name Surname Button */}
      <Button
        onClick={isKYCVerified ? undefined : navigagetToKYC}
        {...Styles.panelButton}
        paddingY={isMobile ? "14px" : "15px"}
        paddingX={isMobile ? "15px" : "27px"}
      >
        <VStack spacing={isMobile ? "1px" : "2px"} alignItems="start">
          <Text {...textStyles.boxNormalText}>{t("name")}</Text>
          <Text {...textStyles.boxBoldText}>{name}</Text>
        </VStack>
        {isKYCVerified ? (
          <HStack>
            <Text
              {...textStyles.boxBoldText}
              alignSelf="center"
              color="#5263F0"
            >
              {t("verified")}
            </Text>
            <Image src={verified} w="24px" h="24px" />
          </HStack>
        ) : (
          <HStack>
            <Link
              {...textStyles.boxBoldText}
              alignSelf="center"
              color="#F14F44"
              minWidth="auto"
              _hover={{ textDecoration: "none" }}
              onClick={navigagetToKYC}
            >
              {t("unverified")}
            </Link>
            <PiCaretRightBold color="white" w="20px" h="20px" />
          </HStack>
        )}
      </Button>

      {/* User ID Box */}
      <Button
        {...Styles.panelButton}
        paddingY={isMobile ? "14px" : "15px"}
        paddingX={isMobile ? "15px" : "27px"}
        _hover={{}}
        _active={{}}
      >
        <VStack spacing={isMobile ? "1px" : "2px"} alignItems="start">
          <Text {...textStyles.boxNormalText}>{t("userID")}</Text>
          <Text {...textStyles.boxBoldText}>{userid}</Text>
        </VStack>
      </Button>

      {/* Email Button */}
      <Button
        onClick={isEmailVerified ? undefined : onEmailModalOpen}
        {...Styles.panelButton}
        paddingY={isMobile ? "14px" : "15px"}
        paddingX={isMobile ? "15px" : "27px"}
      >
        <HStack spacing={4} justify="flex-start" align="center" width="full">
          <VStack align="flex-start" flex="1">
            <Text {...textStyles.boxNormalText}>{t("email")}</Text>
            <Text
              {...textStyles.boxBoldText}
              w={isMobile ? 138 : "auto"}
              isTruncated
            >
              {email}
            </Text>
          </VStack>
          {isEmailVerified ? (
            <HStack spacing="2">
              <Text {...textStyles.boxBoldText} color="#5263F0">
                {t("verified")}
              </Text>
              <PiSealCheckBold color="#5263F0" size="22" />
            </HStack>
          ) : (
            <HStack spacing="2">
              <Link
                onClick={onEmailModalOpen}
                {...textStyles.boxBoldText}
                color="#F14F44"
                _hover={{ textDecoration: "none" }}
              >
                {t("unverified")}
              </Link>
              <PiCaretRightBold color="white" w="20px" h="20px" />
            </HStack>
          )}
        </HStack>
      </Button>

      <Modal size="lg" isOpen={isEmailModalOpen} onClose={onEmailModalClose}>
        <ModalOverlay
          bg="rgba(0, 0, 0, 0.30)"
          sx={{
            backdropFilter: "blur(2px)",
          }}
        />
        <ModalContent
          bg="#010101"
          border="2px solid #333"
          sx={{
            width: { base: "350px", md: "480px", lg: "536px" },
            height: { base: "450px", md: "560px", lg: "630px" },
          }}
          px={{ base: "10px", md: "40px", lg: "50px" }}
        >
          <Text textAlign="center" mt={10} fontWeight="700" fontSize="xl">
            {t("verifyEmail")}
          </Text>
          <Text textAlign="center" fontSize="sm" color="#888" mt={4}>
            {t("safetyEmail")}
          </Text>
          <ModalBody>
            <VStack mt={10}>
              <Formik
                initialValues={{ email: email }}
                onSubmit={(values, { setSubmitting }) => {
                  sendCode(values.email);
                  setSubmitting(false);
                }}
              >
                {({ handleSubmit }) => (
                  <Form>
                    <FormControl id="email">
                      <Field as={Input} name="email">
                        {({ field, form }) => (
                          <InputGroup>
                            <Input
                              {...field}
                              {...Styles.inputStyles}
                              placeholder="Email Address"
                              onChange={(e) => {
                                form.setFieldValue("email", e.target.value);
                              }}
                            />
                            <InputRightElement
                              width={{
                                base: "40px",
                                md: "60px",
                                lg: "65px",
                              }}
                              mr={{
                                base: "15px",
                                md: "18px",
                                lg: "20px",
                              }}
                              mt={{
                                base: "2px",
                                md: "5px",
                                lg: "7px",
                              }}
                              textAlign="center"
                              justifySelf="center"
                            >
                              <Button
                                zIndex="1"
                                width="full"
                                height="full"
                                variant="link"
                                onClick={() => {
                                  handleSubmit();
                                }}
                                fontSize={{
                                  base: "sm",
                                  md: "sm",
                                  lg: "md",
                                }}
                                color="#FD9F65"
                                _hover={{
                                  color: "#888",
                                  textDecoration: "underline",
                                }}
                              >
                                {isCodeSent ? "Resend" : "Get code"}
                              </Button>
                            </InputRightElement>
                          </InputGroup>
                        )}
                      </Field>
                    </FormControl>
                  </Form>
                )}
              </Formik>
              {isCodeSent && (
                <PinInputComponent onComplete={handleVerifyComplete} />
              )}
            </VStack>
          </ModalBody>
          <ModalCloseButton />
        </ModalContent>
      </Modal>

      {/* Phone Button */}
      <Button
        onClick={isPhoneVerified ? undefined : onPhoneModalOpen}
        {...Styles.panelButton}
        paddingY={isMobile ? "14px" : "15px"}
        paddingX={isMobile ? "15px" : "27px"}
      >
        <VStack spacing={isMobile ? "1px" : "2px"} align="start">
          <Text {...textStyles.boxNormalText}>{t("phoneNumber")}</Text>
          <Text {...textStyles.boxBoldText}>{phone.replace(/^00/, "+")}</Text>
        </VStack>
        {isPhoneVerified ? (
          <HStack>
            <Text
              {...textStyles.boxBoldText}
              alignSelf="center"
              color="#5263F0"
            >
              {t("verified")}
            </Text>
            <PiSealCheckBold color="#5263F0" size="22" />
          </HStack>
        ) : (
          <HStack>
            <Link
              onClick={onPhoneModalOpen}
              alignSelf="center"
              {...textStyles.boxBoldText}
              color="#F14F44"
              minWidth="auto"
              _hover={{ textDecoration: "none" }}
            >
              {t("unverified")}
            </Link>
            <PiCaretRightBold color="white" w="20px" h="20px" />
          </HStack>
        )}
        <Modal size="lg" isOpen={isPhoneModalOpen} onClose={onPhoneModalClose}>
          <ModalOverlay
            bg="rgba(0, 0, 0, 0.30)"
            sx={{
              backdropFilter: "blur(2px)",
            }}
          />
          <ModalContent
            bg="#010101"
            border="2px solid #333"
            sx={{
              width: { base: "350px", md: "480px", lg: "536px" },
              height: { base: "450px", md: "560px", lg: "630px" },
            }}
            px={{ base: "10px", md: "40px", lg: "50px" }}
          >
            <Text textAlign="center" mt={10} fontWeight="700" fontSize="xl">
              {t("bindPhoneNumber")}
            </Text>
            <Text textAlign="center" fontSize="sm" color="#888" mt={4}>
              {t("safetyPhone")}
            </Text>
            <ModalCloseButton />
            <Box mt={10} background="#111111">
              <InputGroup>
                <InputLeftElement width="4.5rem" height="100%">
                  <Menu>
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon color={"black"} />}
                      width="100%"
                      height="100%"
                    >
                      {selectedCountry ? (
                        <>
                          <Image
                            boxSize="20px"
                            src={flags[`flag_${selectedCountry}`]}
                            alt={selectedCountry}
                            mr="10px"
                          />
                        </>
                      ) : (
                        "Select country"
                      )}
                    </MenuButton>
                    <MenuList
                      width="240px"
                      maxHeight="300px"
                      overflowY="auto"
                      background={"#111111"}
                      borderRadius={"10px"}
                      border={"2px solid #333"}
                    >
                      {countryCodes.map((country) => (
                        <MenuItem
                          key={country.short}
                          onClick={() => handleCountrySelect(country)}
                          minH="40px"
                          background={"#111111"}
                        >
                          <Image
                            boxSize="20px"
                            borderRadius="full"
                            src={flags[`flag_${country.short}`]}
                            alt={country.name}
                            mr="12px"
                          />
                          <Box flex="1" overflow="hidden">
                            <Text isTruncated>{country.name}</Text>
                            <Text fontSize="sm" color="gray.500">
                              {country.code}
                            </Text>
                          </Box>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                  <Box
                    position="absolute"
                    left="4.5rem" // Sol tarafa doğru uzaklık
                    top="0"
                    bottom="0"
                    borderRight="1px" // Sağ kenar için bir piksel çizgi
                    borderColor="gray.400" // Çizgi rengi gri
                  ></Box>
                </InputLeftElement>
                <Input
                  ml="5px"
                  pl="4.5rem"
                  pr="4.5rem"
                  type="tel"
                  placeholder="Phone number"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  bg="white" // Arka plan rengi beyaz
                  color="black" // Yazı rengi siyah
                  border="2px solid" // Kenarlık kalınlığı
                  borderColor="gray.200" // Kenarlık rengi
                  _hover={{
                    borderColor: "gray.300", // Üzerine gelince kenarlık rengi
                  }}
                  _focus={{
                    borderColor: "blue.400", // Odaklandığında kenarlık rengi
                    // Odaklandığında gölge rengi
                  }}
                  height="48px" // Input alanı yüksekliği
                />
                <InputRightElement
                  width={{
                    base: "40px",
                    md: "60px",
                    lg: "65px",
                  }}
                  mr={{
                    base: "15px",
                    md: "18px",
                    lg: "20px",
                  }}
                  mt={{
                    base: "2px",
                    md: "5px",
                    lg: "7px",
                  }}
                  textAlign="center"
                  justifySelf="center"
                >
                  <Button
                    zIndex="1"
                    width="full"
                    height="full"
                    variant="link"
                    onClick={() => {
                      sendCodePhone(phoneNumber);
                    }}
                    fontSize={{
                      base: "sm",
                      md: "sm",
                      lg: "md",
                    }}
                    color="#FD9F65"
                    _hover={{
                      color: "#888",
                      textDecoration: "underline",
                    }}
                  >
                    {isCodeSent ? "Resend" : "Get code"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </Box>
            {isCodeSent && (
              <PinInputComponent onComplete={handleVerifyCompletePhone} />
            )}
          </ModalContent>
        </Modal>
      </Button>

      {/*ASK GAS */}
      <Box
        {...Styles.panelButton}
        paddingY={isMobile ? "24px" : "25px"}
        paddingX={isMobile ? "15px" : "27px"}
        _hover={{}}
        _active={{}}
      >
        <FormControl>
          <Flex justifyContent="space-between">
            <Text
              {...textStyles.boxBoldText}
              alignItems="stretch"
              htmlFor="askGas"
            >
              {t("askGas")}
            </Text>
            <Switch
              colorScheme="custom"
              size="lg"
              isChecked={askGas}
              onChange={() => setAskGas((prev) => !prev)}
            />
          </Flex>
        </FormControl>
      </Box>
      <Box
        {...Styles.panelButton}
        paddingY={isMobile ? "24px" : "25px"}
        paddingX={isMobile ? "15px" : "27px"}
        _hover={{}}
        _active={{}}
      >
        {/* testnet Button */}
        <FormControl>
          <Flex justifyContent="space-between">
            <Text
              {...textStyles.boxBoldText}
              alignItems="stretch"
              htmlFor="showTestnets"
            >
              {t("showTestnets")}
            </Text>
            <Switch
              colorScheme="custom"
              size="lg"
              isChecked={showTestNet}
              onChange={handleTestnetSwitchChange}
            />
          </Flex>
        </FormControl>
      </Box>

      {/* Currency Button */}
      <Button
        {...Styles.panelButton}
        paddingY={isMobile ? "24px" : "25px"}
        paddingX={isMobile ? "15px" : "27px"}
        onClick={onCurrencyModalOpen}
      >
        <Text
          spacing={isMobile ? "1px" : "2px"}
          alignItems="stretch"
          {...textStyles.boxBoldText}
        >
          {t("currency")}
        </Text>

        <HStack>
          <Link
            alignSelf="center"
            {...textStyles.boxBoldText}
            color="#FFF"
            minWidth="auto"
            _hover={{ textDecoration: "none" }}
          >
            {selectedCurrency.toUpperCase()}
          </Link>
          <PiCaretRightBold color="white" w="20px" h="20px" />
        </HStack>
        <Modal
          size="lg"
          isOpen={isCurrencyModalOpen}
          onClose={onCurrencyModalClose}
        >
          <ModalOverlay
            bg="rgba(0, 0, 0, 0.30)"
            sx={{ backdropFilter: "blur(2px)" }}
          />
          <ModalContent
            bg="#010101"
            border="2px solid #333"
            sx={{
              width: { base: "350px", md: "480px", lg: "536px" },
              height: { base: "450px", md: "560px", lg: "630px" },
            }}
            px={{ base: "10px", md: "40px", lg: "50px" }}
            py={{ base: "10px", md: "40px", lg: "50px" }}
          >
            <Text textAlign="center" fontWeight="700" fontSize="xl">
              {t("selectCurrency")}
            </Text>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing="5" mt={10}>
                <FormControl>
                  <InputGroup>
                    <InputLeftElement height="full">
                      <PiMagnifyingGlassBold color="#888" />
                    </InputLeftElement>
                    <Input
                      border="none"
                      width={{
                        base: "258px",
                        md: "350px",
                        lg: "394px",
                      }}
                      height={{
                        base: "30px",
                        md: "35px",
                        lg: "45px",
                      }}
                      background="#1F1E1E"
                      color="#888"
                      type="SearchBar"
                      placeholder="Search a Currency"
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </InputGroup>
                </FormControl>
                <Box
                  width={{
                    base: "258px",
                    md: "350px",
                    lg: "394px",
                  }}
                  maxHeight={{
                    base: "300px",
                    md: "400px",
                    lg: "380px",
                  }}
                  overflowY="auto"
                  overflowX="hidden"
                >
                  <Menu
                    justifyContent="center"
                    width={{
                      base: "258px",
                      md: "350px",
                      lg: "394px",
                    }}
                    height={{
                      base: "330px",
                      md: "350px",
                      lg: "380px",
                    }}
                  >
                    {filteredCurrencies.length > 0 ? (
                      filteredCurrencies.map((currency) => (
                        <MenuItem
                          value={currency.code}
                          key={currency.code}
                          onClick={() => handleCurrencyClick(currency.code)}
                          borderRadius="6px"
                          bg={
                            selectedCurrency === currency.code
                              ? "rgba(255, 255, 255, 0.2)"
                              : "rgba(0, 0, 0, 0.2)"
                          }
                          width={{
                            base: "100%", // Burada yüzde kullanıyoruz ki her zaman container'a sığsın
                            md: "350px",
                            lg: "394px",
                          }}
                          height={{
                            base: "auto", // Mobilde içeriğe bağlı olarak yüksekliği ayarla
                            md: "35px",
                            lg: "40px",
                          }}
                          _hover={{
                            bg: "rgba(255, 255, 255, 0.1)", // Menü ögesi üzerine geldiğinde arka plan rengini belirle
                          }}
                        >
                          <Flex
                            justifyContent="space-between"
                            width="full"
                            align="center"
                            px={4}
                          >
                            <Box>
                              <Text as="span" fontWeight="bold" mr="2">
                                {currency.name}
                              </Text>
                              <Text
                                as="span"
                                color="#888"
                                fontWeight="400"
                                fontSize={{
                                  base: "sm",
                                  md: "md",
                                }}
                              >
                                {currency.code.toUpperCase()}
                              </Text>
                            </Box>
                            {selectedCurrency === currency.code && (
                              <PiCheckBold alt="Selected" boxSize="20px" />
                            )}
                          </Flex>
                        </MenuItem>
                      ))
                    ) : (
                      <Text>{t("noCurrencyFound")}</Text>
                    )}
                  </Menu>
                </Box>
              </VStack>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Button>
    </VStack>
  );
};

const Security = ({ isMobile, phoneVerified, isSmsAuthEnabled, email }) => {
  const toast = useToast();
  const { t } = useTranslation();

  const [isSwitchChecked, setIsSwitchChecked] = useState(
    localStorage.getItem("isSmsAuthEnabled") === "true"
  );

  const {
    isOpen: isPassChangeModalOpen,
    onOpen: onPassChangeModalOpen,
    onClose: onPassChangeModalClose,
  } = useDisclosure();

  const {
    isOpen: isTFAModalOpen,
    onOpen: onTFAModalOpen,
    onClose: onTFAModalClose,
  } = useDisclosure();

  const handleSwitchChange = () => {
    if (!phoneVerified) {
      onTFAModalClose();

      toast({
        title: t("phoneVerificationNeed"),
        description: t("pleaseVerifyPhoneTwoFactor"),
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
    } else if (!isSmsAuthEnabled && phoneVerified) {
      onTFAModalOpen();
      //handleTFA();
    } else {
      if (isSwitchChecked) {
        handleTFADisable();
        setIsSwitchChecked(false);
      } else {
        onTFAModalOpen();
      }
    }
  };

  const handleTFADisable = async () => {
    try {
      await User.SMS2FADisable(2251);

      setIsSwitchChecked(false);
      localStorage.setItem("isSmsAuthEnabled", false);

      toast({
        title: t("twoFactorDisabled"),
        description: t("twoFactorDisabledDescription"),
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onTFAModalClose();
    } catch (error) {
      toast({
        title: "Error",
        description: t("twoFactorDisabledError"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleTFA = async () => {
    try {
      await User.SMS2FAEnable(2251);

      toast({
        title: t("twoFactorEnabled"),
        description: t("twoFactorEnabledDescription"),
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsSwitchChecked(true);
      localStorage.setItem("isSmsAuthEnabled", true);
      onTFAModalClose();
    } catch (error) {
      toast({
        title: "Error",
        description: t("twoFactorEnabledError"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  const handleChangePassword = async (values, actions) => {
    if (
      !values.currentPassword ||
      !values.newPassword ||
      !values.confirmPassword
    ) {
      toast({
        title: "Error",
        description: t("fieldsMustFilled"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (values.newPassword.length < 8) {
      toast({
        title: "Error",
        description: t("newPasswordLeast"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (values.newPassword.length > 50) {
      toast({
        title: "Error",
        description: t("newPasswordExceed"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (values.newPassword !== values.confirmPassword) {
      toast({
        title: "Error",
        description: t("newPasswordNotMatch"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      // Check if new password and confirm password match
      if (values.newPassword === values.confirmPassword) {
        await User.passwordChange(
          2251,
          email,
          values.currentPassword,
          values.newPassword
        );

        onPassChangeModalClose();

        toast({
          title: t("success"),
          description: t("newPasswordChangedSuccessfully"),
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: t("newPasswordNotMatch"),
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "An unknown error occurred.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <VStack spacing="20px">
        <Box
          paddingY={isMobile ? "24px" : "25px"}
          paddingX={isMobile ? "15px" : "27px"}
          {...Styles.panelButton}
          _hover={{}}
          _active={{}}
        >
          <FormControl>
            <Flex justifyContent="space-between" alignContent="center">
              <FormLabel
                {...textStyles.boxBoldText}
                alignSelf="center"
                htmlFor="isSmsAuthEnabled"
              >
                {t("twoFactorAuthTitle")}
              </FormLabel>
              <Switch
                onClick={(e) => e.preventDefault()}
                colorScheme="custom"
                size="lg"
                isChecked={isSwitchChecked}
                onChange={handleSwitchChange}
              />
              <Modal
                size="lg"
                isOpen={isTFAModalOpen}
                onClose={onTFAModalClose}
              >
                <ModalOverlay
                  bg="rgba(0, 0, 0, 0.30)"
                  sx={{
                    backdropFilter: "blur(2px)",
                  }}
                />
                <ModalContent
                  bg="#010101"
                  border="2px solid #333"
                  sx={{
                    width: { base: "350px", md: "480px", lg: "536px" },
                    height: { base: "450px", md: "560px", lg: "630px" },
                  }}
                  px={{ base: "10px", md: "40px", lg: "50px" }}
                >
                  <Text
                    textAlign="center"
                    mt={10}
                    fontWeight="700"
                    fontSize="xl"
                  >
                    {t("twoFactorAuthTitle")}
                  </Text>
                  <Text textAlign="center" fontSize="sm" color="#888" mt={4}>
                    {t("twoFactorAuthDescription")}
                  </Text>

                  <ModalCloseButton />
                  <ModalBody>
                    <Flex justifyContent="center" mt={10}>
                      <VStack spacing={10}>
                        <Formik
                          initialValues={{ password: "" }}
                          onSubmit={(values, actions) => {
                            handleTFA();
                            actions.setSubmitting(false);
                          }}
                        >
                          {(props) => (
                            <Form>
                              <Flex justifyContent="center">
                                <Button
                                  type="submit"
                                  mt={5}
                                  width={{
                                    base: "200px",
                                    md: "275px",
                                    lg: "325px",
                                  }}
                                  height={{
                                    base: "45px",
                                    md: "50px",
                                    lg: "58px",
                                  }}
                                  borderRadius="10px"
                                  border="1px solid #333"
                                  background="#111"
                                  color="#888"
                                  fontWeight="700"
                                  fontSize="md"
                                  _hover={{
                                    color: "white",
                                    bg: "#111",
                                  }}
                                  _active={{
                                    color: "white",
                                    bg: "#222",
                                  }}
                                  isLoading={props.isSubmitting}
                                >
                                  {t("continue")}
                                </Button>
                              </Flex>
                            </Form>
                          )}
                        </Formik>
                      </VStack>
                    </Flex>
                  </ModalBody>
                </ModalContent>
              </Modal>
            </Flex>
          </FormControl>
        </Box>

        <Button
          {...Styles.panelButton}
          paddingY={isMobile ? "24px" : "25px"}
          paddingX={isMobile ? "15px" : "27px"}
          onClick={onPassChangeModalOpen}
        >
          <Text {...textStyles.boxBoldText} alignItems="stretch">
            {t("password")}
          </Text>
          <HStack>
            <Link
              alignSelf="center"
              {...textStyles.boxBoldText}
              color="#FFF"
              minWidth="auto"
              _hover={{ textDecoration: "none" }}
            >
              {t("change")}
            </Link>
            <PiCaretRightBold color="white" w="20px" h="20px" />
          </HStack>
        </Button>
        <Modal
          size="lg"
          isOpen={isPassChangeModalOpen}
          onClose={onPassChangeModalClose}
        >
          <ModalOverlay
            bg="rgba(0, 0, 0, 0.30)"
            sx={{ backdropFilter: "blur(2px)" }}
          />
          <ModalContent
            bg="#010101"
            border="2px solid #333"
            sx={{
              width: { base: "350px", md: "480px", lg: "536px" },
              height: { base: "450px", md: "560px", lg: "630px" },
            }}
            px={{ base: "10px", md: "40px", lg: "50px" }}
          >
            <Text textAlign="center" mt={10} fontWeight="700" fontSize="xl">
              {t("changePassword")}
            </Text>

            <ModalCloseButton />
            <ModalBody>
              <Flex justifyContent="center" mt={10}>
                <Formik
                  initialValues={{
                    currentPassword: "",
                    newPassword: "",
                    confirmPassword: "",
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    handleChangePassword(values);
                    setSubmitting(false);
                  }}
                >
                  {({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit} noValidate>
                      <FormControl id="currentPassword" mt={4}>
                        <Field name="currentPassword">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="password"
                              placeholder="Current Password"
                              maxLength="50"
                              minLength="8"
                              background="white"
                              color="#888"
                              width={{
                                base: "300px",
                                md: "350px",
                                lg: "394px",
                              }}
                              height={{
                                base: "45px",
                                md: "50px",
                                lg: "55px",
                              }}
                            />
                          )}
                        </Field>
                      </FormControl>

                      <FormControl id="newPassword" mt={4}>
                        <Field name="newPassword">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="password"
                              placeholder="New Password"
                              maxLength="50"
                              minLength="8"
                              background="white"
                              color="#888"
                              width={{
                                base: "300px",
                                md: "350px",
                                lg: "394px",
                              }}
                              height={{
                                base: "45px",
                                md: "50px",
                                lg: "55px",
                              }}
                            />
                          )}
                        </Field>
                      </FormControl>

                      <FormControl id="confirmPassword" mt={4}>
                        <Field name="confirmPassword">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="password"
                              placeholder="Confirm Password"
                              maxLength="50"
                              minLength="8"
                              background="white"
                              color="#888"
                              width={{
                                base: "300px",
                                md: "350px",
                                lg: "394px",
                              }}
                              height={{
                                base: "45px",
                                md: "50px",
                                lg: "55px",
                              }}
                            />
                          )}
                        </Field>
                      </FormControl>

                      <Flex justifyContent="center" mt={5}>
                        <Button
                          type="submit"
                          width={{
                            base: "200px",
                            md: "275px",
                            lg: "325px",
                          }}
                          height={{
                            base: "45px",
                            md: "50px",
                            lg: "58px",
                          }}
                          borderRadius="10px"
                          border="1px solid #333"
                          background="#111"
                          color="#888"
                          fontWeight="700"
                          fontSize="md"
                          _hover={{ color: "white", bg: "#111" }}
                          _active={{ color: "white", bg: "#222" }}
                        >
                          {t("continue")}
                        </Button>
                      </Flex>
                    </Form>
                  )}
                </Formik>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      </VStack>
      <Flex
        align="bottom"
        justifyContent="flex-end"
        paddingY={isMobile ? "2" : "4"}
      >
        <HStack>
          <Button
            mt="350px"
            variant="link"
            justifyContent="space-between"
            color="white"
          >
            <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
              {t("termsOfUse")}
            </Text>
          </Button>
          <Text mt="350px"> | </Text>
          <Button mt="350px" variant="link" color="white">
            <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
              {t("privacyPolicy")}
            </Text>
          </Button>
        </HStack>
      </Flex>
    </>
  );
};

const Support = (isMobile) => {
  const { t } = useTranslation();
  return (
    <>
      <VStack spacing="20px">
        <Box
          {...Styles.panelButton}
          paddingY={!isMobile ? "24px" : "25px"}
          paddingX={!isMobile ? "15px" : "27px"}
        >
          <Link
            href="https://docs.polyverse.life/docs/PolyWallet/SSS/"
            isExternal
          >
            <HStack justifyContent="space-between">
              <Text color="#FFF" {...textStyles.boxBoldText}>
                {t("faq")}
              </Text>

              <PiCaretRightBold color="white" w="20px" h="20px" />
            </HStack>
          </Link>
        </Box>

        <Box
          {...Styles.panelButton}
          paddingY={!isMobile ? "24px" : "25px"}
          paddingX={!isMobile ? "15px" : "27px"}
        >
          <Link href={t("link.contactUs")} isExternal>
            <HStack justifyContent="space-between" alignSelf="center">
              <Text alignSelf="start" color="#FFF" {...textStyles.boxBoldText}>
                {t("contactUs")}
              </Text>
              <PiCaretRightBold color="white" w="20px" h="20px" />
            </HStack>
          </Link>
        </Box>
      </VStack>
    </>
  );
};

const Community = (isMobile) => {
  const { t } = useTranslation();

  return (
    <>
      <VStack spacing="20px">
        <Box
          paddingY={!isMobile ? "24px" : "25px"}
          paddingX={!isMobile ? "15px" : "27px"}
          {...Styles.panelButton}
        >
          <Link href={t("link.x")} isExternal>
            <HStack justifyContent="space-between">
              <Flex alignItems="center">
                <FaXTwitter color="white" />
                <Text color="#FFF" ml="5px" {...textStyles.boxBoldText}>
                  Twitter
                </Text>
              </Flex>
              <PiCaretRightBold
                color="white"
                justifyContent="flex-end"
                w="20px"
                h="20px"
              />
            </HStack>
          </Link>
        </Box>
        <Box
          paddingY={!isMobile ? "24px" : "25px"}
          paddingX={!isMobile ? "15px" : "27px"}
          {...Styles.panelButton}
        >
          <Link href="https://medium.com/@polyverse.life" isExternal>
            <HStack justifyContent="space-between" alignSelf="center">
              <Flex alignItems="center">
                <FaMediumM color="white" />
                <Text ml="5px" {...textStyles.boxBoldText}>
                  Medium
                </Text>
              </Flex>
              <PiCaretRightBold
                color="white"
                justifyContent="flex-end"
                w="20px"
                h="20px"
              />
            </HStack>
          </Link>
        </Box>
        <Box
          {...Styles.panelButton}
          paddingY={!isMobile ? "24px" : "25px"}
          paddingX={!isMobile ? "15px" : "27px"}
        >
          <Link href="https://discord.com/invite/polyverselife" isExternal>
            <HStack justifyContent="space-between" alignSelf="center">
              <Flex alignItems="center">
                <FaDiscord color="white" />
                <Text color="#FFF" ml="5px" {...textStyles.boxBoldText}>
                  Discord
                </Text>
              </Flex>
              <PiCaretRightBold color="white" w="20px" h="20px" />
            </HStack>
          </Link>
        </Box>
      </VStack>
    </>
  );
};

function AccountV2() {
  const { t } = useTranslation();

  const [isMobile] = useMediaQuery("(max-width: 480px)");

  const email = localStorage.getItem("username");
  const phone = localStorage.getItem("phone");
  const userid = localStorage.getItem("userid");
  const emailVerified = localStorage.getItem("emailVerified") === "true";
  const phoneVerified = localStorage.getItem("phoneVerified") === "true";
  const isSmsAuthEnabled = localStorage.getItem("isSmsAuthEnabled") === "true";
  const kycVerified = localStorage.getItem("kycVerified") === "true";
  const name = localStorage.getItem("name");
  const [selectedCountryCode, setSelectedCountryCode] = useState("+90");
  const [displayedCountryCode, setDisplayedCountryCode] = useState("");
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("USD");

  return (
    <Flex>
      <Box {...Styles.boxStyles}>
        <Tabs variant="unstyled">
          <TabList
            paddingY={isMobile ? "2" : "4"}
            paddingX={isMobile ? "1" : "27px"}
          >
            {" "}
            <Tab
              {...textStyles.tabTextStyle}
              mr={{ base: "-10px", md: "20px", lg: "80px" }}
            >
              {t("profile")}
            </Tab>
            <Tab
              {...textStyles.tabTextStyle}
              mr={{ base: "-10px", md: "20px", lg: "80px" }}
            >
              {t("security")}
            </Tab>
            <Tab
              {...textStyles.tabTextStyle}
              mr={{ base: "-10px", md: "20px", lg: "80px" }}
            >
              {t("support")}
            </Tab>
            <Tab {...textStyles.tabTextStyle}>{t("community")}</Tab>
          </TabList>
          <Divider width="full" border="1px solid #333333" opacity="1.0" />

          <TabPanels
            paddingY={isMobile ? "8px" : "15px"}
            paddingX={isMobile ? "10px" : "27px"}
          >
            <TabPanel>
              <Profile
                isKYCVerified={kycVerified}
                isMobile={isMobile}
                userid={userid}
                email={email}
                phone={phone}
                name={name}
                setDisplayedCountryCode={setDisplayedCountryCode}
                setSelectedCountryCode={setSelectedCountryCode}
                setSelectedCurrency={setSelectedCurrency}
                isCodeSent={isCodeSent}
                verified={PiSealCheckBold}
                emailVerified={emailVerified}
                phoneVerified={phoneVerified}
              />
            </TabPanel>
            <TabPanel variant="unstyled">
              <Security
                isCodeSent={isCodeSent}
                phoneVerified={phoneVerified}
                isMobile={isMobile}
                isSmsAuthEnabled={isSmsAuthEnabled}
                email={email}
              />
            </TabPanel>
            <TabPanel>
              <Support isMobile={isMobile} />
            </TabPanel>
            <TabPanel>
              <Community
                isMobile={isMobile}
                discord={FaDiscord}
                twitter={FaXTwitter}
                medium={FaMediumM}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Flex>
  );
}
export default AccountV2;
