import { useTranslation } from "react-i18next";

import {
  Flex,
  Select,
  Button,
  Box,
  Text,
  Center,
  useToast,
} from "@chakra-ui/react";

function Buy() {
  const toast = useToast();
  const { t } = useTranslation();

  const comingSoon = () => {
    toast({
      title: t("comingSoon"),
      description: t("toolsNotActivatedYet"),
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  const Overlay = () => (
    <Center
      position="absolute"
      top={0}
      right={0}
      bottom={0}
      left={0}
      backgroundColor="#222"
      opacity="20%"
      cursor={"not-allowed"}
      onClick={comingSoon}
    />
  );

  // Buy.js içinde kullanılacak state'ler ve fonksiyonlar burada tanımlanabilir.
  // Örneğin: const [selectedNetwork, setSelectedNetwork] = useState('');

  return (
    <Flex align="center" justify="center" mt={30}>
      <Box
        backgroundColor="#000"
        border={"2px solid #333"}
        borderRadius="10px"
        padding={8}
        m={"auto"}
        w={[248, 448, 448, 448]}
        position="relative"
      >
        <Flex direction="column" gap={4}>
          <Select
            placeholder={t("network")}
            variant="filled"
            _focus={{ borderColor: "whiteAlpha.500" }}
            _hover={{ backgroundColor: "#333" }}
            border={"2px solid #333"}
            borderRadius="10px"
            backgroundColor={"#111"}
          >
            {/* Blok zincirleri burada listelenecek */}
          </Select>

          <Flex
            gap={2}
            border={"2px solid #333"}
            borderRadius="10px"
            backgroundColor={"#111"}
          >
            <Select
              placeholder={t("selectToken")}
              _hover={{ backgroundColor: "#333" }}
              border={"none"}
              //onChange={(e) => setSelectedToken(e.target.value)}
              // value={selectedToken} // Eğer seçilen tokenin state'ini tutmak isterseniz
            >
              {/* Buy yapılacak tokenler burada listelenecek */}
            </Select>
          </Flex>

          <Flex justify="center" mt={4}>
            <Text fontSize={"3xl"} color={"#888"}>
              -
            </Text>
          </Flex>

          <Flex justify="center" mt={4}>
            <Button
              border="2px solid #333"
              borderRadius="10px"
              backgroundColor="#111"
              color="#888"
              _hover={{ backgroundColor: "#333" }}
              w="60%"
            >
              {t("buy")}
            </Button>
          </Flex>
        </Flex>
        <Overlay />
      </Box>
    </Flex>
  );
}

export default Buy;
