import React from "react";
import { Box } from "@chakra-ui/react";

const AuthBg = () => {
  const colorArray = [
    ["#070c57", "#5e5b69", "#216156", "#783f24"],
    ["#783f24", "#070c57", "#5e5b69", "#216156"],
    ["#5e5b69", "#216156", "#783f24", "#070c57"],
    ["#216156", "#783f24", "#070c57", "#5e5b69"],
  ];

  return (
    <Box
      w="100vw"
      h="100vh"
      position="relative"
      overflow="hidden"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        width="fit-content"
        height="fit-content"
      >
        {colorArray.map((color, index) => (
          <Box
            key={index}
            position="relative"
            w="600px"
            h="600px"
            borderRadius="50%"
            filter="blur(75px)"
            animation={`colorChange${index} 4s ease-in-out infinite`}
            sx={{
              [`@keyframes colorChange${index}`]: {
                "0%": { backgroundColor: `${color[0]}`, opacity: 0.7 },
                "25%": { backgroundColor: `${color[1]}`, opacity: 0.7 },
                "50%": { backgroundColor: `${color[2]}`, opacity: 0.7 },
                "75%": { backgroundColor: `${color[3]}`, opacity: 0.7 },
                "100%": { backgroundColor: `${color[0]}`, opacity: 0.7 },
              },
            }}
            margin="-75px"
          />
        ))}
      </Box>
    </Box>
  );
};

export default AuthBg;
