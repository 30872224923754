import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  Box,
  SimpleGrid,
  Image,
  Text,
  Divider,
  useToast,
  VStack,
} from "@chakra-ui/react";

import nftDropIcon from "./../static/icons/nftDrop.svg";
import paymentReqIcon from "./../static/icons/paymentRequest.svg";
import giveawayIcon from "./../static/icons/giveaway.svg";

function Tools() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();

  const cards = [
    {
      title: t("tools.nftDrop.title"),
      description: t("tools.nftDrop.description"),
      imageUrl: nftDropIcon,
      link: "/nft-drops",
      isActive: true,
    },
    {
      title: t("tools.paymentRequest.title"),
      description: t("tools.paymentRequest.description"),
      imageUrl: paymentReqIcon,
      link: "/payment-request",
      isActive: false,
    },
    {
      title: t("tools.giveaway.title"),
      description: t("tools.giveaway.description"),
      imageUrl: giveawayIcon,
      link: "/giveaway",
      isActive: false,
    },
  ];

  const navigateTool = (card) => {
    if (card.isActive) {
      navigate(card.link);
    } else {
      toast({
        title: t("comingSoon"),
        description: t("toolsNotActivatedYet"),
        status: "info",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <SimpleGrid
      w="full"
      justifyContent="center"
      columns={{ sm: 1, md: 2, lg: 2, xl: 3 }}
      spacing={30}
      py={{ sm: 2, lg: 4 }}
      px={{ base: 3, sm: 2, lg: 12 }}
    >
      {cards.map((card, index) => (
        <Box
          key={index}
          px="4"
          py="6"
          borderRadius="10px"
          border="2px solid #333"
          bg={card.isActive ? "#010101" : "#22222250"}
          textAlign="center"
          maxW="400px"
          mx="auto"
          cursor={card.isActive ? "pointer" : "not-allowed"}
          _hover={{
            bg: card.isActive ? "#222" : "#22222250",
            ".content": { opacity: card.isActive ? 0.6 : 0.5 },
          }}
          onClick={() => navigateTool(card)}
        >
          <VStack
            className="content"
            spacing="2"
            opacity={card.isActive ? 1 : 0.5}
          >
            <Image
              src={card.imageUrl}
              alt={card.title}
              mx="auto"
              my="2"
              boxSize={["85px", "85px", "90px"]}
            />
            <Divider border="1px solid #333" opacity="1" my="2" />
            <Text fontSize={["14px", "16px", "18px"]} fontWeight="bold">
              {card.title}
            </Text>
            <Text
              fontSize={["12px", "13px", "14px"]}
              fontWeight="normal"
              color="#888"
            >
              {card.description}
            </Text>
          </VStack>
        </Box>
      ))}
    </SimpleGrid>
  );
}
export default Tools;
