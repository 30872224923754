import * as React from "react";
import { createRoot } from "react-dom/client"; // Corrected import statement
import { ChakraProvider } from "@chakra-ui/react";
import "./fonts.css";
import App from "./App";
import theme from "./themes/theme";

const root = document.getElementById("root");

// Corrected usage of createRoot
const rootElement = createRoot(root);

rootElement.render(
  <ChakraProvider theme={theme}>
    <App />
  </ChakraProvider>
);
