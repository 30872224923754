import React, { useState, useEffect } from "react";

import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Box,
  Flex,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";

import Receive from "./Receive";
import Send from "./Send";
import Activities from "./Activities";

function Transfer() {
  const location = useLocation();
  const { t } = useTranslation();

  const searchParams = new URLSearchParams(location.search);
  const tabQuery = searchParams.get("tab");

  const tabMap = { send: 0, receive: 1, activities: 2 };
  const initialTab = tabMap[tabQuery] !== undefined ? tabMap[tabQuery] : null;

  const [activeTab, setActiveTab] = useState(initialTab);
  const isTabSelected = (index) => activeTab === index || activeTab === null;

  useEffect(() => {
    document.body.style.overflowY = "auto";

    // URL'deki `tab` parametresine dayalı olarak `activeTab`'i ayarla
    const tabFromUrl = tabMap[tabQuery];
    setActiveTab(tabFromUrl !== undefined ? tabFromUrl : null);

    return () => {
      document.body.style.overflowY = "unset";
    };
  }, [location.search]);

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      mt={{ base: "9", sm: "6", md: "6", lg: "6" }}
      w="full"
      mx="auto"
    >
      <Box
        pt={{ base: "4", sm: "6", md: "6", lg: "6" }}
        w={{ base: "95%", sm: "420px", md: "450px", lg: "450px" }}
        h="full"
        px="0"
        bg="#010101"
        border="2px solid #333"
        borderRadius="10px"
        minH="590px"
      >
        <Tabs variant="unstyled">
          <Flex justifyContent="center">
            <TabList color="#888" bg="#1E1E1E" borderRadius="6px" p="3px" h="9">
              {Object.keys(tabMap).map(
                (key, index) =>
                  isTabSelected(index) && (
                    <Tab
                      key={key}
                      fontSize="sm"
                      _selected={{
                        bg: "#111111",
                        color: "white",
                        borderRadius: "5px",
                      }}
                      _focus={{ boxShadow: "none" }}
                      _hover={{ color: "#666" }}
                    >
                      {/* key.charAt(0).toUpperCase() + key.slice(1) */}
                      {t(key)}
                    </Tab>
                  )
              )}
            </TabList>
          </Flex>
          <Flex justifyContent="center">
            <TabPanels overflow="hidden">
              {isTabSelected(0) && (
                <TabPanel
                  px={{ base: "4", sm: "6", md: "8", lg: "8" }}
                  pt={{ base: "4", sm: "4", md: "6", lg: "6" }}
                >
                  <Send />
                </TabPanel>
              )}
              {isTabSelected(1) && (
                <TabPanel
                  px={{ base: "4", sm: "6", md: "8", lg: "8" }}
                  pt={{ base: "4", sm: "4", md: "6", lg: "6" }}
                >
                  <Receive />
                </TabPanel>
              )}
              {isTabSelected(2) && (
                <TabPanel px="0" pt={{ base: "4", sm: "4", md: "6", lg: "6" }}>
                  <Activities />
                </TabPanel>
              )}
            </TabPanels>
          </Flex>
        </Tabs>
      </Box>
    </Flex>
  );
}

export default Transfer;
