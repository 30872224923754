import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom"; // useNavigate hook'unu import edin

import { Box, Spinner, Flex, useToast } from "@chakra-ui/react";

import { User } from "polyverse-sdk-dev/dist/api/user";

const ValidateTwitterAuth = () => {
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const validateAuth = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const tweetinviAuthRequestId = queryParams.get(
        "tweetinvi_auth_request_id"
      );
      const oauthToken = queryParams.get("oauth_token");
      const oauthVerifier = queryParams.get("oauth_verifier");

      try {
        const response = await User.ValidateTwitterAuth(
          2251,
          "wxcguoTzCmuuBdezpAVElaCeZd",
          tweetinviAuthRequestId,
          oauthToken,
          oauthVerifier
        );

        //save token to localstrorage
        localStorage.setItem("token", response.token);
        //save address to localstrorage
        localStorage.setItem("userAddress", response.address);
        //save username to localstrorage
        localStorage.setItem("username", response.email);
        //save userid to localstrorage
        localStorage.setItem("userid", response.id);
        //navigate to main page
        navigate("/");
      } catch (error) {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });

        // forward to home page after 5 seconds
        setTimeout(() => {
          navigate("/");
        }, 5000);
      }
    };

    validateAuth();
  }, []);

  return (
    <Flex height="100vh" alignItems="center" justifyContent="center">
      <Box>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="#472478"
          color="#753cc5"
          size="xl"
        />
      </Box>
    </Flex>
  );
};

export default ValidateTwitterAuth;
