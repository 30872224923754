import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Text,
  Divider,
  Image,
  Spinner,
  Center,
  useClipboard,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Button,
  ModalBody,
  ModalCloseButton,
  Grid,
  useToast,
  GridItem,
  useBreakpointValue,
  Tag,
} from "@chakra-ui/react";

import { shortenAddress } from "../../services/Helpers";
import SendNfts from "./SendNfts";

import { PiCheckBold, PiCopySimpleBold } from "react-icons/pi";

function Nfts({ allTokens, isLoading, networks, handleNFTSentAssets }) {
  const { t } = useTranslation();

  const [selectedToken, setSelectedToken] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSendNftsModalOpen, setIsSendNftsModalOpen] = useState(false);
  const isLgScreen = useBreakpointValue({ base: false, md: false, lg: true });

  const handleNFTSent = (tokenId) => {
    console.log("handleNFTSent", tokenId);

    handleNFTSentAssets(tokenId);

    setIsModalOpen(false);
    setIsSendNftsModalOpen(false);
  };

  const handleImageClick = (token) => {
    setSelectedToken(token);
    setIsModalOpen(true);
    setIsSendNftsModalOpen(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    if (isSendNftsModalOpen) {
      setIsSendNftsModalOpen(false);
      setIsModalOpen(true); // Eğer SendNfts modalı açıksa, Nfts modalını tekrar aç
    } else {
    }
  };

  const openSendNftsModalWithItem = (item) => {
    setIsSendNftsModalOpen(true);
  };

  const DetailRow = ({ label, value, textToCopy, fontSize }) => {
    const toast = useToast();
    const { hasCopied, onCopy } = useClipboard(textToCopy || "");

    const handleCopy = () => {
      if (textToCopy) {
        onCopy();
        toast({
          title: t("copied"),
          description: `${textToCopy} ${t("copiedClipboard")}`,
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }
    };

    return (
      <Flex justifyContent="space-between" alignItems="center">
        <Text fontWeight="medium" fontSize={fontSize} color="#888">
          {label}
        </Text>
        <Flex alignItems="center">
          <Text fontWeight="medium" fontSize={fontSize} isTruncated mr="2">
            {value}
          </Text>
          {textToCopy && (
            <Box onClick={() => handleCopy(value)} cursor="pointer">
              {hasCopied ? (
                <PiCheckBold color="#888" h="6" w="6" />
              ) : (
                <PiCopySimpleBold h="6" w="6" color="#888" />
              )}
            </Box>
          )}
        </Flex>
      </Flex>
    );
  };

  const tokenDetails = [
    {
      label: t("tokenId"),
      value: shortenAddress(selectedToken?.nftMeta.tokenId),
      textToCopy: selectedToken?.nftMeta.tokenId,
    },
    {
      label: t("contractAddress"),
      value: shortenAddress(selectedToken?.contract),
      textToCopy: selectedToken?.contract,
    },
    {
      label: t("network"),
      value: selectedToken?.networkName,
    },
    {
      label: t("standard"),
      value: selectedToken?.type,
    },
  ];

  const resolveImageUrl = (nftMeta) => {
    let imageUrl =
      nftMeta && nftMeta.image ? nftMeta.image : "defaultImagePath";

    // IPFS linkini kontrol et
    if (
      imageUrl.startsWith("https://ipfs.io/ipfs/") ||
      imageUrl.startsWith("ipfs://")
    ) {
      // URL'i "/" ile böl ve parçaları al
      const parts = imageUrl.split("/");
      // Son parçayı al (IPFS hash veya dosya adı)
      const lastPart = parts[parts.length - 1];

      // Eğer son parça bir dosya adıysa veya ekstra bir yol içeriyorsa, URL'i olduğu gibi kullan
      if (lastPart.includes(".") || parts.length > 5) {
        return `https://api.polyverse.life/NFT/Image?url=${imageUrl.replace(
          "https://ipfs.io",
          "http://192.248.179.139:8080"
        )}&width=600`;
      }

      // Sadece IPFS hash içeriyorsa, yeni URL formatını kullan
      return `https://api.polyverse.life/storage/ipfs/${lastPart.replace(
        "https://ipfs.io",
        "http://192.248.179.139:8080"
      )}/600`;
    }

    return `https://api.polyverse.life/NFT/Image?url=${imageUrl.replace(
      "https://ipfs.io",
      "http://192.248.179.139:8080"
    )}&width=600`;
  };

  return (
    <>
      {isLoading ? (
        <Center>
          <Spinner size="lg" color="#753CC5" mt="3" mb="14" />
        </Center>
      ) : allTokens.length === 0 ? (
        <Center w="100%">
          <Text
            my="10"
            align="center"
            fontWeight="normal"
            fontSize={isLgScreen ? "16px" : "14px"}
          >
            {t("noNFTs")}
          </Text>
        </Center>
      ) : (
        <>
          <Text px="12" py="2" color="#888" fontWeight="normal" fontSize="lg">
            {t("nfts")}
          </Text>
          <Divider borderColor="#2B2B2B" opacity={1} />
          <Grid
            pt="2"
            px={["4px", "8px", "12px"]}
            templateColumns={{
              base: "repeat(auto-fit, minmax(120px, 1fr))",
              md: "repeat(auto-fill, minmax(150px, 1fr))",
              lg: "repeat(auto-fill, minmax(200px, 1fr))",
            }}
            gap={2}
            justifyContent="center"
          >
            {allTokens
              .filter(
                (token) => token.type === "ERC-1155" || token.type === "ERC-721"
              )
              .map((token, index) => {
                let imageUrl = resolveImageUrl(token.nftMeta);
                return (
                  <GridItem
                    key={index}
                    p={2}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Box
                      position="relative"
                      w={["80px", "90px", "100px"]}
                      h={["80px", "90px", "100px"]}
                      overflow="visible"
                    >
                      <Image
                        src={imageUrl}
                        alt={`NFT ${token.nftMeta.name}`}
                        objectFit="cover"
                        borderRadius="11px"
                        boxSize="full"
                        cursor="pointer"
                        onClick={() => handleImageClick(token)}
                        _hover={{ cursor: "pointer", opacity: 0.5 }}
                      />
                      <Image
                        src={`data:image/svg+xml;base64,${token.networkIcon}`}
                        alt="Network Icon"
                        position="absolute"
                        bottom="-1"
                        right="-1"
                        w={["4", "5", "6"]}
                        h={["4", "5", "6"]}
                        zIndex="1"
                      />
                    </Box>
                    <Box
                      mt={2}
                      direction="column"
                      textAlign="center"
                      overflow="hidden"
                      px={1}
                    >
                      <Text
                        fontWeight="bold"
                        fontSize={["14px", "16px", "16px"]}
                        color="white"
                        textAlign="center"
                        isTruncated
                      >
                        {token.nftMeta.name}
                      </Text>
                      <Text
                        fontWeight="medium"
                        fontSize={["12px", "13px", "14px"]}
                        color="#888"
                        textAlign="center"
                        isTruncated
                      >
                        {!token.price || Number(token.price) === 0
                          ? "—"
                          : token.price}{" "}
                        {token.priceCurrency}
                      </Text>
                    </Box>
                  </GridItem>
                );
              })}
          </Grid>
          <Modal
            display="flex"
            justifyContent="center"
            alignItems="center"
            isOpen={isModalOpen}
            onClose={closeModal}
          >
            <ModalOverlay backdropFilter="blur(2px)" />
            <ModalContent w="84%" bg="#111" mx="auto" maxW="450px">
              <ModalHeader pt="6" align="center">
                {t("details")}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody
                pb="6"
                px={[5, 6, 8]}
                borderRadius="10px"
                fontWeight="bold"
                fontSize="sm"
              >
                {selectedToken && (
                  <>
                    <Text
                      fontWeight="medium"
                      fontSize={["12px", "13px", "14x"]}
                    >
                      {selectedToken?.name}
                    </Text>
                    <Text fontSize={["16px", "16px", "18x"]} pb="4">
                      {selectedToken?.nftMeta.name} NFT
                    </Text>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      m="auto"
                      position="relative"
                      w={["140px", "160px", "180px"]}
                    >
                      <Image
                        src={
                          selectedToken
                            ? resolveImageUrl(selectedToken.nftMeta)
                            : ""
                        }
                        alt={`NFT ${selectedToken?.nftMeta.name}`}
                        objectFit="cover"
                        borderRadius="11px"
                        boxSize="full"
                        w="100%"
                        h="100%"
                      />
                    </Box>
                    <Box
                      borderRadius="10px"
                      border="2px solid #333"
                      p="3"
                      my="4"
                    >
                      <Flex justifyContent="space-between" textAlign="center">
                        <Flex textAlign="center" align="center">
                          <Image
                            src={`data:image/svg+xml;base64,${selectedToken?.networkIcon}`}
                            alt="Network Icon"
                            w={["4", "5", "6"]}
                            h={["4", "5", "6"]}
                          />
                          <Text ml="2" fontSize={["16px", "16px", "18px"]}>
                            {selectedToken?.price ? (
                              <>
                                {selectedToken.price}{" "}
                                {selectedToken.priceCurrency}
                              </>
                            ) : selectedToken?.isTestNet ? (
                              <Tag
                                outline={"1px solid #333333"}
                                size={"lg"}
                                bg="#1e1e1e"
                                color="white"
                              >
                                <Text fontSize={"sm"}>Testnet</Text>
                              </Tag>
                            ) : (
                              <Tag
                                outline={"1px solid #333333"}
                                size={"lg"}
                                bg="#1e1e1e"
                                color="white"
                              >
                                {t("priceNotFound")}
                              </Tag>
                            )}
                          </Text>
                        </Flex>
                        <Button
                          onClick={() =>
                            openSendNftsModalWithItem(selectedToken)
                          }
                          maxW="150px"
                          maxH="50px"
                          variant="pvGray"
                        >
                          {t("sendNFT")}
                        </Button>
                        <SendNfts
                          isOpen={isSendNftsModalOpen}
                          onClose={closeModal}
                          item={selectedToken}
                          networks={networks}
                          onBack={() => setIsSendNftsModalOpen(false)}
                          handleNFTSent={handleNFTSent}
                        />
                      </Flex>
                    </Box>
                    <Text
                      fontWeight="medium"
                      fontSize={["13px", "14px", "15x"]}
                      color="#888"
                    >
                      {t("description")}
                    </Text>
                    <Text
                      mb="2"
                      noOfLines={6}
                      fontWeight="medium"
                      fontSize={["14px", "15px", "16px"]}
                    >
                      {selectedToken?.nftMeta.description}
                    </Text>
                    {selectedToken &&
                      tokenDetails.map((detail, index) => (
                        <DetailRow
                          key={index}
                          label={detail.label}
                          value={detail.value}
                          textToCopy={detail.textToCopy} // Will be undefined except for the contract address
                          fontSize={["13px", "14px", "16px"]}
                        />
                      ))}
                  </>
                )}
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  );
}

export default Nfts;
