import { useTranslation } from "react-i18next";

import {
  Flex,
  Select,
  Input,
  Button,
  Box,
  FormLabel,
  FormControl,
  IconButton,
  Tooltip,
  Center,
  useToast,
} from "@chakra-ui/react";

import { MdOutlineSwapVert } from "react-icons/md";
import { IoMdInformationCircleOutline } from "react-icons/io";

function Swap() {
  const toast = useToast();
  const { t } = useTranslation();

  const comingSoon = () => {
    toast({
      title: t("comingSoon"),
      description: t("toolsNotActivatedYet"),
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  const Overlay = () => (
    <Center
      position="absolute"
      top={0}
      right={0}
      bottom={0}
      left={0}
      backgroundColor="#222"
      opacity="20%"
      cursor={"not-allowed"}
      onClick={comingSoon}
    />
  );

  // Swap.js içinde kullanılacak state'ler ve fonksiyonlar burada tanımlanabilir.
  // Örneğin: const [selectedNetwork, setSelectedNetwork] = useState('');

  return (
    <Flex align="center" justify="center" mt={30}>
      <Box
        backgroundColor="#000"
        border={"2px solid #333"}
        borderRadius="10px"
        padding={8}
        w={[248, 448, 448, 448]}
        m={"auto"}
        position="relative"
      >
        <Flex direction="column" gap={4}>
          <Select
            placeholder={t("network")}
            variant="filled"
            _focus={{ borderColor: "whiteAlpha.500" }}
            _hover={{ backgroundColor: "#333" }}
            border={"2px solid #333"}
            borderRadius="10px"
            backgroundColor={"#111"}
          >
            {/* Blok zincirleri burada listelenecek */}
          </Select>

          <FormControl id="from-token" mb={4}>
            <FormLabel fontSize={"sm"}>{t("fromToken")}</FormLabel>
            <Flex
              gap={2}
              border={"2px solid #333"}
              borderRadius="10px"
              backgroundColor={"#111"}
            >
              <Select
                placeholder={t("selectToken")}
                _hover={{ backgroundColor: "#333" }}
                border={"none"}
                borderRight={"2px solid #333"}
                borderRightRadius={0}
                //onChange={(e) => setSelectedToken(e.target.value)}
                // value={selectedToken} // Eğer seçilen tokenin state'ini tutmak isterseniz
              >
                {/* Swap yapılacak tokenler burada listelenecek */}
              </Select>
              <Input placeholder="0.0" type="number" border={"none"} />
            </Flex>
          </FormControl>

          <Flex justify="center">
            <IconButton
              aria-label="Search database"
              icon={<MdOutlineSwapVert />}
              size={"sm"}
              borderRadius={20}
              border={"2px solid #333"}
              backgroundColor={"#111"}
              _hover={{ backgroundColor: "#333" }}
              color={"#fff"}
            />
          </Flex>

          <FormControl id="to-token" mb={4}>
            <FormLabel fontSize={"sm"}>{t("toToken")}</FormLabel>
            <Flex
              gap={2}
              border={"2px solid #333"}
              borderRadius="10px"
              backgroundColor={"#111"}
            >
              <Select
                placeholder={t("selectToken")}
                _hover={{ backgroundColor: "#333" }}
                border={"none"}
                borderRight={"2px solid #333"}
                borderRightRadius={0}
                //onChange={(e) => setSelectedToken(e.target.value)}
                // value={selectedToken} // Eğer seçilen tokenin state'ini tutmak isterseniz
              >
                {/* Swap yapılacak tokenler burada listelenecek */}
              </Select>
              <Input placeholder="0.0" type="number" border={"none"} />
            </Flex>
          </FormControl>

          <Flex align="center" justify="space-between">
            <FormLabel fontSize={"sm"}>{t("slippageTolerance")}</FormLabel>
            <Flex gap={2}>
              <Button
                border={"2px solid #333"}
                borderRadius="10px"
                backgroundColor={"#111"}
                color={"#fff"}
                _hover={{ backgroundColor: "#333" }}
                size={"sm"}
              >
                2%
              </Button>
              <Button
                border={"2px solid #333"}
                borderRadius="10px"
                backgroundColor={"#111"}
                color={"#fff"}
                _hover={{ backgroundColor: "#333" }}
                size={"sm"}
              >
                5%
              </Button>
              {/* Custom Slippage Input */}
              <Input
                placeholder={t("custom")}
                border="2px solid #333"
                borderRadius="10px"
                backgroundColor="#111"
                color="#fff"
                size="sm"
                type="number"
                min={0} // Minimum değer
                max={100} // Maksimum değer
                width="100px" // Sabit genişlik
                _hover={{ backgroundColor: "#333" }}
                _placeholder={{ color: "gray.500" }} // Placeholder metin rengi
                display={{ base: "none", md: "block" }}
                /* onChange={(e) => setSlippage(e.target.value)} // Değişikliği yakalama */
                // value={slippage} // Eğer slippage state'ini tutmak isterseniz
              />

              <Tooltip label="Hover me">
                <IconButton
                  icon={<IoMdInformationCircleOutline />}
                  isRound={true}
                  variant="solid"
                  fontSize="24px"
                  size={"sm"}
                  backgroundColor={"#111"}
                  color={"#fff"}
                  display={{ base: "none", md: "block" }}
                  _hover={{ backgroundColor: "#333" }}
                />
              </Tooltip>
            </Flex>
          </Flex>
          <Flex justify="center" mt={4}>
            <Button
              border="2px solid #333"
              borderRadius="10px"
              backgroundColor="#111"
              color="#888"
              _hover={{ backgroundColor: "#333" }}
              w="60%"
            >
              Swap
            </Button>
          </Flex>
        </Flex>
        <Overlay />
      </Box>
    </Flex>
  );
}

export default Swap;
