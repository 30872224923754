import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Table,
  Thead,
  Th,
  Td,
  Tbody,
  Avatar,
  TableContainer,
  Tr,
  Flex,
  Button,
  Spinner,
  InputGroup,
  InputLeftElement,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
  Text,
  Spacer,
  IconButton,
  Popover,
  Tooltip,
  HStack,
  useBreakpointValue,
  PopoverTrigger,
  PopoverContent,
  Center,
} from "@chakra-ui/react";

import Nfts from "./NFTs";

import { Wallet } from "polyverse-sdk-dev/dist/api/wallet";
import { User } from "polyverse-sdk-dev/dist/api/user";
import {
  PiArrowDownBold,
  PiArrowUpBold,
  PiArrowsLeftRightBold,
  PiCaretDownBold,
  PiCheckBold,
  PiDotsThreeVerticalBold,
  PiFunnelBold,
  PiInfoBold,
  PiMagnifyingGlassBold,
} from "react-icons/pi";

const Assets = () => {
  const isLgScreen = useBreakpointValue({ base: false, md: false, lg: true });

  const navigate = useNavigate();
  const { t } = useTranslation();

  // State declarations
  const [isLoading, setIsLoading] = useState(true);

  const [allTokens, setAllTokens] = useState([]);
  const [networks, setNetworks] = useState([]);

  const [showFilter, setShowFilter] = useState(false);
  const [selectedNetworkName, setSelectedNetworkName] = useState(null);
  const [selectedNetwork, setSelectedNetwork] = useState([]);
  const [showOnlyNativeCurrency, setShowOnlyNativeCurrency] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [hideZeroBalance, setHideZeroBalance] = useState(true);

  const [popoverOpen, setPopoverOpen] = useState({});

  const [visibleRowsCount, setVisibleRowsCount] = useState(5);

  const maxRows = 5;

  const currency = localStorage.getItem("userCurrency") || "usd";

  async function fetchNetworksAndBalances() {
    setIsLoading(true);
    let tempAllTokens = [];

    // Bir Promise dizisi oluştur
    const balancePromises = networks.map(async (network) => {
      try {
        const balanceData = await Wallet.balance(
          network.key,
          currency,
          true,
          true,
          false
        );

        // Within your map function for networks
        return [
          // Handle Balance (as a single element array to concatenate with tokens)
          ...(balanceData.balance && typeof balanceData.balance === "object"
            ? [
                {
                  ...balanceData.balance,
                  networkIcon: network.icon,
                  isMainBalance: true,
                  isTestNet: network.isTestNet,
                  networkName: network.name,
                  networkKey: network.key,
                  networkSymbol: network.symbol,
                  networkExplorerUrl: network.explorerUrl,
                },
              ]
            : []),

          // Handle Tokens (spread the possibly empty array)
          ...(balanceData.tokens && Array.isArray(balanceData.tokens)
            ? balanceData.tokens.map((token) => ({
                ...token,
                isMainBalance: false,
                networkIcon: network.icon,
                networkName: network.name,
                isTestNet: network.isTestNet,
                networkKey: network.key,
                networkSymbol: network.symbol,
                networkExplorerUrl: network.explorerUrl,
              }))
            : []),
        ];
      } catch (error) {
        console.error(
          "Error fetching balance for network:",
          network.name,
          error
        );
        return [];
      }
    });

    try {
      const allResults = await Promise.all(balancePromises);

      tempAllTokens = allResults.flat();

      setAllTokens(tempAllTokens);
    } catch (error) {
      console.error("Error fetching all balances", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    async function fetchNetworks() {
      try {
        const fetchedNetworks = await User.networks();

        let showTestNetValue = localStorage.getItem("showTestNet");

        const showTestNet =
          showTestNetValue === null ? false : showTestNetValue === "true";

        let filteredNetworks = showTestNet
          ? fetchedNetworks
          : fetchedNetworks.filter((network) => !network.isTestNet);

        if (filteredNetworks.length === 0) {
          filteredNetworks = [fetchedNetworks[0]];
        }

        setNetworks(filteredNetworks);
      } catch (error) {
        console.error("Error fetching networks", error);
      }
    }
    fetchNetworks();
  }, []);

  useEffect(() => {
    fetchNetworksAndBalances();
  }, [currency, networks]);

  // Utility Functions

  const fixBalance = (balance, decimals) => {
    let numericBalance = Number(balance);

    let balanceString = numericBalance.toPrecision(decimals + 1);
    if (balanceString.includes("e")) {
      // For very large numbers
      return balance.toFixed(decimals); // Adjust the precision value as needed
    }

    if (!isNaN(numericBalance)) {
      // For very small numbers that are not effectively zero
      if (
        numericBalance !== 0 &&
        Math.abs(numericBalance) < Math.pow(10, -decimals)
      ) {
        return numericBalance.toPrecision(1); // Adjust the precision value as needed
      } else {
        // For all other numbers, continue as normal
        let floatBalance = numericBalance.toFixed(decimals); // Convert to string with fixed decimals
        let trimmedFloatBalance = parseFloat(floatBalance); // Remove unnecessary trailing zeros

        // Convert back to number then to string with up to 5 decimal places, removing trailing zeros
        return trimmedFloatBalance.toFixed(5).replace(/\.?0+$/, "");
      }
    } else {
      console.error("Invalid balance input:", balance);
      return "0";
    }
  };

  // Event Handlers
  const handleToggleRows = () => {
    setVisibleRowsCount(visibleRowsCount === 5 ? maxRows : 5);
  };

  const handleHideZeroBalanceClick = () => {
    setHideZeroBalance((prevHideZeroBalance) => !prevHideZeroBalance);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Send butonu için click handler
  const handleSendClick = (sendedNetwork, token) => {
    navigate(`/transfer?tab=send&network=${sendedNetwork}&token=${token}`);
    // setSelectedNetwork(network);
  };

  // Receive butonu için click handler
  const handleReceiveClick = (receivedNetwork) => {
    navigate(`/transfer?tab=receive&network=${receivedNetwork}`);
  };

  const handleSwapClick = () => {
    navigate("/swap");
  };

  const handleMenuItemClick = (networkName, network) => {
    setSelectedNetworkName(networkName);
    setSelectedNetwork(network);
  };

  const togglePopover = (key) => {
    console.log(`Toggling popover for key: ${key}`); // Check what key is received
    setPopoverOpen((prev) => {
      const newState = { ...prev, [key]: !prev[key] };
      console.log(`New popoverOpen state: `, newState); // Check the new state
      return newState;
    });
  };

  // Filter Tokens New
  const filteredTokens = allTokens.filter((item) => {
    // filter logic
    const hideZeroLogic = hideZeroBalance ? item.balance > 0 : true;
    const nativeCurrencyLogic = showOnlyNativeCurrency
      ? item.isMainBalance
      : true;
    const allNetworksLogic = selectedNetworkName
      ? item.networkName === selectedNetworkName
      : true;

    // search logic
    const searchLower = searchTerm.toLowerCase();
    const searchLogic =
      item.name.toLowerCase().includes(searchLower) ||
      item.symbol.toLowerCase().includes(searchLower) ||
      item.nftMeta?.name.toLowerCase().includes(searchLower);

    return (
      hideZeroLogic && nativeCurrencyLogic && allNetworksLogic && searchLogic
    );
  });

  // See More"/"See Less Button
  const renderToggleRowsButton = () => {
    return (
      filteredTokens.filter(
        (item) => item.type === "ERC20" || item.isMainBalance
      ).length > 5 && (
        <Flex justifyContent="center" my="4">
          <Button
            w={isLgScreen ? "300px" : "188px"}
            variant="pvGray"
            onClick={handleToggleRows}
          >
            {visibleRowsCount === 5 ? "See More" : "See Less"}
          </Button>
        </Flex>
      )
    );
  };

  const handleNFTSentAssets = (tokenId) => {
    console.log("handleNFTSent Asset", tokenId);
    fetchNetworksAndBalances();
  };

  return (
    <Box
      mt="4"
      border="2px solid #333333"
      borderRadius="10px"
      width="full"
      bg="#111"
    >
      <Tabs variant="unstyled" isFitted>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          p={isLgScreen ? "20px" : "14px"}
        >
          <Box overflowX="hidden" bg="#1E1E1E" borderRadius="10px" p={1}>
            <TabList>
              <Tab
                p={isLgScreen ? "2" : "2"}
                px={isLgScreen ? "4" : "2"}
                color="#888"
                _selected={{
                  bg: "#111111",
                  color: "white",
                  borderRadius: "5px",
                }}
                _focus={{ boxShadow: "none" }}
              >
                <Text fontSize={isLgScreen ? "lg" : "sm"}>{t("tokens")}</Text>
              </Tab>
              <Tab
                p={isLgScreen ? "2" : "2"}
                px={isLgScreen ? "4" : "2"}
                color="#888"
                _selected={{
                  bg: "#111111",
                  color: "white",
                  borderRadius: "5px",
                }}
                _focus={{ boxShadow: "none" }}
              >
                <Text fontSize={isLgScreen ? "lg" : "sm"}>{t("nfts")}</Text>
              </Tab>
            </TabList>
          </Box>

          <Spacer />

          {/* Filter dropdown */}
          <Menu
            closeOnSelect={false}
            isOpen={showFilter}
            onClose={() => setShowFilter(false)}
          >
            {isLgScreen ? (
              <MenuButton
                px="11px"
                height="37px"
                maxW={["100px", "110px", "128px"]}
                width="full"
                as={Button}
                mr="4"
                variant="outline"
                borderColor="#333333"
                onClick={() => setShowFilter(!showFilter)}
                _hover={{ bg: "#1E1E1E" }}
                _active={{ bg: "#111111" }}
              >
                <Flex
                  justifyContent="space-between"
                  textAlign="center"
                  align="center"
                >
                  <Flex align="center">
                    <PiFunnelBold w={4} h={4} color="#888" />
                    <Text
                      fontSize="sm"
                      fontWeight="thin"
                      color="#888888"
                      ml="7px"
                    >
                      {t("filter")}
                    </Text>
                  </Flex>
                  <PiCaretDownBold color="#888" w="14px" h="14px" />
                </Flex>
              </MenuButton>
            ) : (
              <MenuButton
                px="11px"
                height="37px"
                width="37px"
                as={Button}
                mr="2"
                variant="outline"
                borderColor="#333333"
                onClick={() => setShowFilter(!showFilter)}
                _hover={{ bg: "#1E1E1E" }}
                _active={{ bg: "#111111" }}
              >
                <PiFunnelBold w={4} h={4} color="#888" />
              </MenuButton>
            )}

            <MenuList
              borderColor="#333333"
              bg="#111111"
              px="11px"
              overflowY="auto"
              maxH={["220px", "230px", "260px"]}
              zIndex="3"
              fontSize={["xs", "sm", "md"]}
            >
              <MenuItem
                key="hide-zero-balance"
                borderRadius="6px"
                _hover={{ bg: "#1E1E1E" }}
                _active={{ bg: "#111111" }}
                textAlign="center"
                bg={hideZeroBalance ? "#1E1E1E" : "#111111"}
                my={1}
                onClick={handleHideZeroBalanceClick}
              >
                {t("filter.hideZeroBalance")}
                <Spacer /> {hideZeroBalance && <PiCheckBold ml="2" />}
              </MenuItem>

              <MenuItem
                key="toggle-native-currency"
                borderRadius="6px"
                _hover={{ bg: "#1E1E1E" }}
                _active={{ bg: "#111111" }}
                textAlign="center"
                bg={showOnlyNativeCurrency ? "#1E1E1E" : "#111111"}
                my={1}
                onClick={() =>
                  setShowOnlyNativeCurrency(!showOnlyNativeCurrency)
                }
              >
                {t("filter.showOnlyNativeCurrency")}
                <Spacer />
                {showOnlyNativeCurrency && <PiCheckBold ml="2" />}
              </MenuItem>

              <MenuItem
                key="all-networks"
                borderRadius="6px"
                _hover={{ bg: "#1E1E1E" }}
                _active={{ bg: "#111111" }}
                textAlign="center"
                bg={selectedNetworkName === null ? "#1E1E1E" : "#111111"}
                onClick={() => handleMenuItemClick(null)}
                my={1}
              >
                {t("filter.allNetworks")}
                <Spacer />
                {selectedNetworkName === null && <PiCheckBold ml="2" />}
              </MenuItem>

              {networks.map((network) => (
                <MenuItem
                  key={network.name}
                  borderRadius="6px"
                  _hover={{ bg: "#1E1E1E" }}
                  _active={{ bg: "#111111" }}
                  textAlign="center"
                  bg={
                    selectedNetworkName === network.name ? "#1E1E1E" : "#111111"
                  }
                  onClick={() => handleMenuItemClick(network.name, network)}
                  my={1}
                >
                  {network.icon ? (
                    <Image
                      w={4}
                      h={4}
                      src={`data:image/svg+xml;base64,${network.icon}`}
                      mr={2}
                    />
                  ) : (
                    <Avatar
                      w={4}
                      h={4}
                      color="white"
                      name={network.name}
                      size="xs"
                      mr="2"
                      fontSize={isLgScreen ? "xs" : "2xs"}
                    />
                  )}
                  {network.name} <Spacer />
                  {selectedNetworkName === network.name && (
                    <PiCheckBold ml="2" />
                  )}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
          {/* Search */}
          <Flex>
            {isLgScreen || isSearchExpanded ? (
              <InputGroup>
                <InputLeftElement height="100%" pl="1">
                  <PiMagnifyingGlassBold color="#888" w={5} h={5} />
                </InputLeftElement>
                <Input
                  placeholder={t("search")}
                  _placeholder={{
                    fontSize: "sm",
                    fontWeight: "thin",
                    color: "#666",
                  }}
                  _hover={{ borderColor: "#555" }}
                  value={searchTerm}
                  onChange={handleSearchChange}
                  onBlur={() => setIsSearchExpanded(false)}
                  height="37px"
                  lineHeight="37px"
                  maxW={isLgScreen ? "187px" : "134px"}
                  borderColor="#333333"
                  pl="10"
                />
              </InputGroup>
            ) : (
              <IconButton
                variant="outlined"
                border="1px solid"
                borderColor="#333333"
                icon={<PiMagnifyingGlassBold color="#888" w={5} h={5} />}
                onClick={() => setIsSearchExpanded(true)}
                height="37px"
                maxW="187px"
                _hover={{ bg: "#1E1E1E" }}
                _active={{ bg: "#111111" }}
              />
            )}
          </Flex>
        </Flex>

        <TabPanels overflow={"hidden"}>
          <TabPanel px="0">
            <TableContainer>
              {isLoading || networks.length === 0 ? (
                <Center>
                  <Spinner size="lg" color="#753CC5" mt="0" mb="9" />
                </Center>
              ) : filteredTokens.filter(
                  (item) => item.type === "ERC20" || item.isMainBalance
                ).length > 0 ? (
                <Table
                  variant="pvAssets"
                  size="sm"
                  style={{ tableLayout: "fixed", width: "100%" }}
                  width={["24%", "28%", "30%"]}
                >
                  <Thead>
                    <Tr>
                      <Th pb={["2", "3", "5"]} pl={["2", "4", "12"]}>
                        Token
                      </Th>
                      <Th
                        pb={["2", "3", "5"]}
                        width={["25%", "25%", "19%"]}
                        alignItems="center"
                        textAlign="center"
                        align="center"
                        justifyContent="center"
                      >
                        {t("balance")}
                      </Th>
                      <Th
                        pb={["2", "3", "5"]}
                        width={["0%", "0%", "18%"]}
                        textAlign="center"
                        display={{ base: "none", lg: "table-cell" }}
                      >
                        {t("price")}
                      </Th>
                      <Th
                        isTruncated
                        pb={["2", "3", "5"]}
                        textAlign="center"
                        width={["23%", "22%", "19%"]}
                      ></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {filteredTokens
                      .filter(
                        (item) => item.type === "ERC20" || item.isMainBalance
                      )
                      .slice(0, visibleRowsCount)
                      .map((item, index) => {
                        return (
                          <Tr key={index}>
                            <Td w="auto" pl={["2", "4", "12"]}>
                              <Flex align="center" textAlign="center">
                                {/* Token Icon */}
                                {item.isMainBalance ? (
                                  <Image
                                    w={["5", "6", "8"]}
                                    h={["5", "6", "8"]}
                                    src={`data:image/svg+xml;base64,${item.networkIcon}`}
                                    alt={`Network Icon`}
                                    borderRadius="full"
                                    mr={["2", "3", "4"]}
                                    size="sm"
                                  />
                                ) : (
                                  <>
                                    {item.icon ? (
                                      <Image
                                        w={["5", "6", "8"]}
                                        h={["5", "6", "8"]}
                                        src={item.icon}
                                        alt={`Token Icon`}
                                        borderRadius="full"
                                        mr={["2", "3", "4"]}
                                        size="sm"
                                      />
                                    ) : (
                                      <Avatar
                                        name={item.name}
                                        mr={["2", "3", "4"]}
                                        w={["5", "6", "8"]}
                                        h={["5", "6", "8"]}
                                        p={["1", "2", "3"]}
                                      />
                                    )}
                                  </>
                                )}

                                <Flex
                                  w="auto"
                                  gap="2"
                                  isTruncated
                                  textAlign="center"
                                  direction="column"
                                >
                                  <Flex gap={isLgScreen ? "3" : "1"}>
                                    <Text
                                      isTruncated
                                      fontSize={["16px", "16px", "18px"]}
                                    >
                                      {item.name}
                                    </Text>
                                    <Text
                                      isTruncated
                                      color="#888"
                                      fontWeight="regular"
                                      fontSize={["13px", "13px", "14px"]}
                                    >
                                      {item.symbol}
                                    </Text>
                                  </Flex>

                                  <Flex>
                                    {/* Network Icon */}
                                    {item.networkIcon ? (
                                      <Image
                                        src={`data:image/svg+xml;base64,${item.networkIcon}`}
                                        alt="Network Icon"
                                        borderRadius="full"
                                        mr="2"
                                        w={["3", "4", "4"]}
                                        h={["3", "4", "4"]}
                                      />
                                    ) : (
                                      <Avatar
                                        w={["3", "4", "4"]}
                                        h={["3", "4", "4"]}
                                        size="xs"
                                        fontSize={isLgScreen ? "2xs" : "2xs"}
                                        mr="2"
                                        color="white"
                                        name={item.networkName}
                                      />
                                    )}
                                    <Text
                                      isTruncated
                                      fontWeight="thin"
                                      color="#888"
                                      fontSize={["13px", "13px", "14px"]}
                                    >
                                      {item.networkName}
                                    </Text>
                                  </Flex>
                                </Flex>
                                {item.isMainBalance && (
                                  <Tooltip
                                    placement="right"
                                    py="2"
                                    color="black"
                                    borderRadius="md"
                                    bg="#DDD"
                                    fontWeight="bold"
                                    fontSize={isLgScreen ? "sm" : "xs"}
                                    label={t("gasTokenTooltip", {
                                      networkName: item.networkName,
                                    })}
                                  >
                                    <Box
                                      w={isLgScreen ? "82px" : "22px"}
                                      ml="4"
                                      p="1"
                                      align="center"
                                      bg="#111"
                                      borderRadius="md"
                                      display="flex"
                                      alignItems="center"
                                      border="1px solid #333"
                                      whiteSpace="nowrap"
                                    >
                                      <PiInfoBold
                                        color="#888"
                                        w="12px"
                                        h="12px"
                                      />
                                      {isLgScreen ? (
                                        <Text
                                          ml="1"
                                          color="#888"
                                          fontSize="xs"
                                          fontWeight="normal"
                                        >
                                          Gas Token
                                        </Text>
                                      ) : (
                                        ""
                                      )}
                                    </Box>
                                  </Tooltip>
                                )}
                              </Flex>
                            </Td>
                            <Td
                              fontWeight="medium"
                              p="0"
                              width={["18%", "18%", "18%"]}
                              textAlign="center"
                              isTruncated
                            >
                              <Flex direction="column" gap="2">
                                <Text
                                  isTruncated
                                  fontSize={["16px", "16px", "18px"]}
                                >
                                  {fixBalance(item.balance, item.decimals)}
                                </Text>
                                <Text
                                  isTruncated
                                  fontWeight="thin"
                                  color="#888"
                                  fontSize={["13px", "13px", "14px"]}
                                >
                                  {typeof item.balance === "number" &&
                                  typeof item.price === "number"
                                    ? (item.balance * item.price).toFixed(2) +
                                      " " +
                                      item.priceCurrency.toUpperCase()
                                    : "—"}
                                </Text>
                              </Flex>
                            </Td>
                            <Td
                              display={{ base: "none", lg: "table-cell" }}
                              textAlign="center"
                              direction="column"
                              isTruncated
                            >
                              {item.isTestNet ? (
                                <Tooltip
                                  py="1"
                                  color="#888"
                                  border="1px solid #333"
                                  borderRadius="md"
                                  bg="#111"
                                  fontWeight="normal"
                                  fontSize={isLgScreen ? "sm" : "xs"}
                                  label={
                                    <Flex align="center" gap="1">
                                      <Image alt="Info" w={4} h={4} />
                                      No value for Testnets
                                    </Flex>
                                  }
                                >
                                  —
                                </Tooltip>
                              ) : (
                                <Text
                                  textTransform="uppercase"
                                  fontWeight="medium"
                                  fontSize={["16px", "16px", "18px"]}
                                  isTruncated
                                >
                                  {typeof item.price === "number"
                                    ? item.price.toFixed(2) +
                                      " " +
                                      item.priceCurrency.toUpperCase()
                                    : "—"}
                                </Text>
                              )}
                            </Td>
                            {/* Actions Column */}
                            <Td
                              px={["0", "2", "6"]}
                              textAlign="right"
                              borderColor="#333"
                              w="auto"
                            >
                              <Flex justifyContent="end" gap="4">
                                <Box
                                  display={{ base: "inline-flex", md: "none" }}
                                >
                                  <Popover
                                    isOpen={
                                      popoverOpen[
                                        `${item.symbol}-${item.networkKey}`
                                      ]
                                    }
                                    onClose={() =>
                                      setPopoverOpen({
                                        ...popoverOpen,
                                        [`${item.symbol}-${item.networkKey}`]: false,
                                      })
                                    }
                                  >
                                    <PopoverTrigger>
                                      <IconButton
                                        variant="ghost"
                                        aria-label="More actions"
                                        icon={
                                          <PiDotsThreeVerticalBold
                                            color="white"
                                            size="22"
                                          />
                                        }
                                        _hover={{
                                          bg: "#1E1E1E",
                                        }}
                                        _active={{}}
                                        onClick={() =>
                                          togglePopover(
                                            `${item.symbol}-${item.networkKey}`
                                          )
                                        }
                                      />
                                    </PopoverTrigger>
                                    <PopoverContent
                                      bg="#111"
                                      borderColor="#333"
                                      zIndex={4}
                                      w="full"
                                    >
                                      <HStack p="2">
                                        <Tooltip label={t("send")}>
                                          <IconButton
                                            icon={
                                              <PiArrowUpBold color="white" />
                                            }
                                            bg="#1E1E1E"
                                            border="1px solid #333"
                                            borderRadius="6px"
                                            _hover={{ bg: "#333" }}
                                            onClick={() =>
                                              handleSendClick(
                                                item.networkKey,
                                                item.symbol
                                              )
                                            }
                                          />
                                        </Tooltip>
                                        <Tooltip label={t("swap")}>
                                          <IconButton
                                            icon={
                                              <PiArrowsLeftRightBold color="white" />
                                            }
                                            bg="#1E1E1E"
                                            border="1px solid #333"
                                            borderRadius="6px"
                                            _hover={{ bg: "#333" }}
                                            onClick={handleSwapClick}
                                          />
                                        </Tooltip>
                                        <Tooltip label={t("receive")}>
                                          <IconButton
                                            icon={
                                              <PiArrowDownBold color="white" />
                                            }
                                            bg="#1E1E1E"
                                            border="1px solid #333"
                                            borderRadius="6px"
                                            _hover={{ bg: "#333" }}
                                            onClick={() =>
                                              handleReceiveClick(
                                                item.networkKey
                                              )
                                            }
                                          />
                                        </Tooltip>
                                      </HStack>
                                    </PopoverContent>
                                  </Popover>
                                </Box>

                                <Flex
                                  display={{ base: "none", md: "flex" }}
                                  gap="3"
                                >
                                  <Tooltip label={t("send")}>
                                    <IconButton
                                      icon={<PiArrowUpBold color="white" />}
                                      bg="#1E1E1E"
                                      border="1px solid #333"
                                      borderRadius="6px"
                                      _hover={{ bg: "#333" }}
                                      onClick={() =>
                                        handleSendClick(
                                          item.networkKey,
                                          item.symbol
                                        )
                                      }
                                    />
                                  </Tooltip>
                                  <Tooltip label={t("swap")}>
                                    <IconButton
                                      icon={
                                        <PiArrowsLeftRightBold color="white" />
                                      }
                                      bg="#1E1E1E"
                                      border="1px solid #333"
                                      borderRadius="6px"
                                      _hover={{ bg: "#333" }}
                                      onClick={handleSwapClick}
                                    />
                                  </Tooltip>
                                  <Tooltip label={t("receive")}>
                                    <IconButton
                                      icon={<PiArrowDownBold color="white" />}
                                      bg="#1E1E1E"
                                      border="1px solid #333"
                                      borderRadius="6px"
                                      _hover={{ bg: "#333" }}
                                      onClick={() =>
                                        handleReceiveClick(item.networkKey)
                                      }
                                    />
                                  </Tooltip>
                                </Flex>
                              </Flex>
                            </Td>
                          </Tr>
                        );
                      })}
                  </Tbody>
                </Table>
              ) : (
                <Flex
                  my="20"
                  justifyContent="center"
                  alignItems="center"
                  textAlign="center"
                >
                  <Text
                    my="10"
                    w="100%"
                    align="center"
                    fontWeight="normal"
                    fontSize={isLgScreen ? "16px" : "14px"}
                  >
                    {t("noTokensAvailable")}
                  </Text>
                </Flex>
              )}
              {filteredTokens.length > 0 && renderToggleRowsButton()}
            </TableContainer>
          </TabPanel>
          <TabPanel p="0">
            <Nfts
              selectedNetworkName={selectedNetworkName}
              searchTerm={searchTerm}
              selectedNetwork={selectedNetwork}
              allTokens={filteredTokens}
              isLoading={isLoading}
              networks={networks}
              handleNFTSentAssets={handleNFTSentAssets}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default Assets;
