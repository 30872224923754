import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const theme = extendTheme({
  colors: {
    dark: {
      backgroundDark: "#111111",
    },
    switch: "#5263F0",
  },
  components: {
    Input: {
      baseStyle: {
        field: {
          "::placeholder": {
            color: "#888888",
          },
        },
      },
    },
    Switch: {
      baseStyle: (props) => ({
        track: {
          _checked: {
            backgroundColor: props.colorScheme === "custom" ? "#5263F0" : null,
          },
        },
      }),
    },
    Tabs: {
      variants: {
        customTab: {
          tablist: {
            position: "relative",
          },
          tab: {
            color: "#888888",
            _hover: {
              color: "#555555",
            },
            _selected: {
              color: "white",
              position: "relative",
              _after: {
                //custom indicator
                content: '""',
                position: "absolute",
                bottom: "0",
                left: "25%",
                right: "25%",
                height: "2px",
                backgroundColor: "white",
              },
            },
          },
        },
      },
    },
    Modal: {
      variants: {
        pvBlackGray: (props) => ({
          parts: ["dialog", "footer", "header", "overlay", "body"],
          dialog: {
            bg: "#000",
            border: "2px solid #333",
            width: "auto",
          },
          footer: {
            // bg: "#111",
            // borderTop: "2px solid #333",
            justifyContent: "space-between",
          },
          header: {
            textAlign: "center",
          },
          overlay: {
            bg: "blackAlpha.600",
            backdropFilter: "blur(2px)",
          },
          body: {
            px: "0",
            pt: "14px",
          },
        }),
      },
    },
    Table: {
      variants: {
        customtable: {
          tr: {
            borderBottom: "2px solid #333333",
            "&:last-child": {
              borderBottom: "none",
            },
          },
        },
        pvAssets: {
          th: {
            borderBottom: "1px solid #2B2B2B",
            color: "#888",
            fontSize: ["16px", "16px", "19px"],
            fontWeight: "thin",
            textTransform: "none",
          },
          tr: {
            borderBottom: "1px solid #2B2B2B",
            "&:last-child": {
              borderBottom: "none",
            },
          },
          td: {
            paddingY: "5",
            borderColor: "#2B2B2B",
            whiteSpace: "nowrap",
            fontSize: ["14px", "14px", "16px"],
            textOverflow: "ellipsis",
          },
        },
      },
    },
    Checkbox: {
      defaultProps: {
        colorScheme: "customCheckbox",
      },
      baseStyle: (props) => ({
        control: {
          _checked: {
            bg: props.colorScheme === "customCheckbox" ? "white" : null,
            _focus: {
              boxShadow: "none",
            },
            _hover: {
              borderColor: "transparent",
            },
            color: props.colorScheme === "customCheckbox" ? "black" : null,
            border: "none",
          },
        },
      }),
    },
    Text: {
      variants: {
        smallText: {
          fontSize: "xs",
          fontWeight: "400",
          color: "#888888",
        },
        captionText: {
          // color: 888888 fontsize: 12 weight: bold
          fontSize: "12px",
          fontWeight: "bold",
          color: "#888888",
        },
        bodyText: {
          // color: black fontsize: 16 weight: bold
          fontSize: "16px",
          fontWeight: "bold",
          color: "black",
        },
        bodyTextLight: {
          fontSize: ["12px", "12px", "14px"],
          fontWeight: "bold",
          color: "white",
        },
        bodyTextRegular: {
          fontSize: ["12px", "14px", "14px"],
          fontWeight: "regular",
          color: "white",
        },
        bodyTextDark: {
          fontSize: ["12px", "12px", "14px"],
          fontWeight: "regular",
          color: "#888888",
        },
        titleText: {
          // 28 bold white
          fontSize: "28px",
          fontWeight: "bold",
          color: "white",
        },
        heroText: {
          // 40 bold White
          fontSize: "40px",
          fontWeight: "bold",
          color: "white",
        },
        subtitleText: {
          // 18 bold white
          fontSize: ["14px", "16px", "18px"],
          fontWeight: "bold",
          color: "white",
        },
        highlightText: {
          fontSize: ["12px", "14px", "18px"],
          fontWeight: "regular",
          color: "#888888",
        },
        highlightTextBold: {
          fontSize: ["14px", "16px", "18px"],
          fontWeight: "bold",
          color: "#888888",
        },
        highlightTextRegular: {
          fontSize: ["14px", "16px", "18px"],
          fontWeight: "regular",
          color: "white",
        },

        // fontSize="18px" fontWeight="regular" color="white"
      },
    },
    Button: {
      variants: {
        pvWhite: (props) => ({
          bg: "#ffffff",
          color: "#000000",
          minWidth: "110px",
          width: "auto",
          height: ["2.5rem"],
          _hover: {
            bg: "#cccccc",
          },
        }),
        pvOutlined: (props) => ({
          bg: "#000000",
          color: "#ffffff",
          minWidth: "110px",
          width: "auto",
          height: ["2.5rem"],
          outline: "1px solid #333333",
          _hover: {
            bg: "#333333",
          },
          _active: {
            bg: "#222222",
          },
        }),
        pvGray: (props) => ({
          bg: "#1E1E1E",
          color: "#ffffff",
          height: ["48px"],
          outline: "1px solid #333333",
          _hover: {
            bg: "#333333",
          },
          _active: {
            bg: "#222222",
          },
        }),
        pvGrayNoneWidth: (props) => ({
          bg: "#1E1E1E",
          color: "#ffffff",
          outline: "1px solid #333333",
          _hover: {
            bg: "#333333",
          },
          _active: {
            bg: "#222222",
          },
        }),
        pvOutline: (props) => ({
          bg: "#111",
          color: "#ffffff",
          minWidth: "110px",
          borderRadius: "6px",
          width: "auto",
          height: "37px",
          outline: "1px solid #333333",
          _hover: {
            bg: "#333333",
          },
          _active: {
            bg: "#222222",
          },
        }),
      },
    },
    Link: {
      baseStyle: (props) => ({
        color: mode("#888888", "white")(props),
        fontSize: ["16px", "18px", "20px"],
        fontWeight: "bold",
      }),
    },
    Menu: {
      baseStyle: {
        list: {
          bg: "#111111",
        },
        item: {
          _hover: {
            bg: "#333333",
            color: "#f0f0f0",
          },
        },
      },
    },
  },
  styles: {
    global: {
      body: {
        backgroundColor: "#111111", // Directly setting the dark theme background color
        color: "white", // Assuming white text for dark theme
      },
    },
  },
  fonts: {
    heading: "Hanken Grotesk",
    body: "Hanken Grotesk",
  },
});

export default theme;
