import flag_AD from "../static/flags/ad.svg";
import flag_AE from "../static/flags/ae.svg";
import flag_AF from "../static/flags/af.svg";
import flag_AG from "../static/flags/ag.svg";
import flag_AI from "../static/flags/ai.svg";
import flag_AL from "../static/flags/al.svg";
import flag_AM from "../static/flags/am.svg";
import flag_AN from "../static/flags/an.svg";
import flag_AO from "../static/flags/ao.svg";
import flag_AQ from "../static/flags/aq.svg";
import flag_AR from "../static/flags/ar.svg";
import flag_AS from "../static/flags/as.svg";
import flag_AT from "../static/flags/at.svg";
import flag_AU from "../static/flags/au.svg";
import flag_AW from "../static/flags/aw.svg";
import flag_AX from "../static/flags/ax.svg";
import flag_AZ from "../static/flags/az.svg";
import flag_BA from "../static/flags/ba.svg";
import flag_BB from "../static/flags/bb.svg";
import flag_BD from "../static/flags/bd.svg";
import flag_BE from "../static/flags/be.svg";
import flag_BF from "../static/flags/bf.svg";
import flag_BG from "../static/flags/bg.svg";
import flag_BH from "../static/flags/bh.svg";
import flag_BI from "../static/flags/bi.svg";
import flag_BJ from "../static/flags/bj.svg";
import flag_BL from "../static/flags/bl.svg";
import flag_BM from "../static/flags/bm.svg";
import flag_BN from "../static/flags/bn.svg";
import flag_BO from "../static/flags/bo.svg";
import flag_BQ from "../static/flags/bq.svg";
import flag_BR from "../static/flags/br.svg";
import flag_BS from "../static/flags/bs.svg";
import flag_BT from "../static/flags/bt.svg";
import flag_BV from "../static/flags/bv.svg";
import flag_BW from "../static/flags/bw.svg";
import flag_BY from "../static/flags/by.svg";
import flag_BZ from "../static/flags/bz.svg";
import flag_CA from "../static/flags/ca.svg";
import flag_CC from "../static/flags/cc.svg";
import flag_CD from "../static/flags/cd.svg";
import flag_CF from "../static/flags/cf.svg";
import flag_CG from "../static/flags/cg.svg";
import flag_CH from "../static/flags/ch.svg";
import flag_CI from "../static/flags/ci.svg";
import flag_CK from "../static/flags/ck.svg";
import flag_CL from "../static/flags/cl.svg";
import flag_CM from "../static/flags/cm.svg";
import flag_CN from "../static/flags/cn.svg";
import flag_CO from "../static/flags/co.svg";
import flag_CR from "../static/flags/cr.svg";
import flag_CU from "../static/flags/cu.svg";
import flag_CV from "../static/flags/cv.svg";
import flag_CW from "../static/flags/cw.svg";
import flag_CX from "../static/flags/cx.svg";
import flag_CY from "../static/flags/cy.svg";
import flag_CZ from "../static/flags/cz.svg";
import flag_DE from "../static/flags/de.svg";
import flag_DJ from "../static/flags/dj.svg";
import flag_DK from "../static/flags/dk.svg";
import flag_DM from "../static/flags/dm.svg";
import flag_DO from "../static/flags/do.svg";
import flag_DZ from "../static/flags/dz.svg";
import flag_EC from "../static/flags/ec.svg";
import flag_EE from "../static/flags/ee.svg";
import flag_EG from "../static/flags/eg.svg";
import flag_EH from "../static/flags/eh.svg";
import flag_ER from "../static/flags/er.svg";
import flag_ES from "../static/flags/es.svg";
import flag_ET from "../static/flags/et.svg";
import flag_EU from "../static/flags/eu.svg";
import flag_FI from "../static/flags/fi.svg";
import flag_FJ from "../static/flags/fj.svg";
import flag_FK from "../static/flags/fk.svg";
import flag_FM from "../static/flags/fm.svg";
import flag_FO from "../static/flags/fo.svg";
import flag_FR from "../static/flags/fr.svg";
import flag_GA from "../static/flags/ga.svg";
import flag_GB_ENG from "../static/flags/gb-eng.svg";
import flag_GB_NIR from "../static/flags/gb-nir.svg";
import flag_GB_SCT from "../static/flags/gb-sct.svg";
import flag_GB_WLS from "../static/flags/gb-wls.svg";
import flag_GB from "../static/flags/gb.svg";
import flag_GD from "../static/flags/gd.svg";
import flag_GE from "../static/flags/ge.svg";
import flag_GF from "../static/flags/gf.svg";
import flag_GG from "../static/flags/gg.svg";
import flag_GH from "../static/flags/gh.svg";
import flag_GI from "../static/flags/gi.svg";
import flag_GL from "../static/flags/gl.svg";
import flag_GM from "../static/flags/gm.svg";
import flag_GN from "../static/flags/gn.svg";
import flag_GP from "../static/flags/gp.svg";
import flag_GQ from "../static/flags/gq.svg";
import flag_GR from "../static/flags/gr.svg";
import flag_GS from "../static/flags/gs.svg";
import flag_GT from "../static/flags/gt.svg";
import flag_GU from "../static/flags/gu.svg";
import flag_GW from "../static/flags/gw.svg";
import flag_GY from "../static/flags/gy.svg";
import flag_HK from "../static/flags/hk.svg";
import flag_HM from "../static/flags/hm.svg";
import flag_HN from "../static/flags/hn.svg";
import flag_HR from "../static/flags/hr.svg";
import flag_HT from "../static/flags/ht.svg";
import flag_HU from "../static/flags/hu.svg";
import flag_ID from "../static/flags/id.svg";
import flag_IE from "../static/flags/ie.svg";
import flag_IL from "../static/flags/il.svg";
import flag_IM from "../static/flags/im.svg";
import flag_IN from "../static/flags/in.svg";
import flag_IO from "../static/flags/io.svg";
import flag_IQ from "../static/flags/iq.svg";
import flag_IR from "../static/flags/ir.svg";
import flag_IS from "../static/flags/is.svg";
import flag_IT from "../static/flags/it.svg";
import flag_JE from "../static/flags/je.svg";
import flag_JM from "../static/flags/jm.svg";
import flag_JO from "../static/flags/jo.svg";
import flag_JP from "../static/flags/jp.svg";
import flag_KE from "../static/flags/ke.svg";
import flag_KG from "../static/flags/kg.svg";
import flag_KH from "../static/flags/kh.svg";
import flag_KI from "../static/flags/ki.svg";
import flag_KM from "../static/flags/km.svg";
import flag_KN from "../static/flags/kn.svg";
import flag_KP from "../static/flags/kp.svg";
import flag_KR from "../static/flags/kr.svg";
import flag_KW from "../static/flags/kw.svg";
import flag_KY from "../static/flags/ky.svg";
import flag_KZ from "../static/flags/kz.svg";
import flag_LA from "../static/flags/la.svg";
import flag_LB from "../static/flags/lb.svg";
import flag_LC from "../static/flags/lc.svg";
import flag_LI from "../static/flags/li.svg";
import flag_LK from "../static/flags/lk.svg";
import flag_LR from "../static/flags/lr.svg";
import flag_LS from "../static/flags/ls.svg";
import flag_LT from "../static/flags/lt.svg";
import flag_LU from "../static/flags/lu.svg";
import flag_LV from "../static/flags/lv.svg";
import flag_LY from "../static/flags/ly.svg";
import flag_MA from "../static/flags/ma.svg";
import flag_MC from "../static/flags/mc.svg";
import flag_MD from "../static/flags/md.svg";
import flag_ME from "../static/flags/me.svg";
import flag_MF from "../static/flags/mf.svg";
import flag_MG from "../static/flags/mg.svg";
import flag_MH from "../static/flags/mh.svg";
import flag_MK from "../static/flags/mk.svg";
import flag_ML from "../static/flags/ml.svg";
import flag_MM from "../static/flags/mm.svg";
import flag_MN from "../static/flags/mn.svg";
import flag_MO from "../static/flags/mo.svg";
import flag_MP from "../static/flags/mp.svg";
import flag_MQ from "../static/flags/mq.svg";
import flag_MR from "../static/flags/mr.svg";
import flag_MS from "../static/flags/ms.svg";
import flag_MT from "../static/flags/mt.svg";
import flag_MU from "../static/flags/mu.svg";
import flag_MV from "../static/flags/mv.svg";
import flag_MW from "../static/flags/mw.svg";
import flag_MX from "../static/flags/mx.svg";
import flag_MY from "../static/flags/my.svg";
import flag_MZ from "../static/flags/mz.svg";
import flag_NA from "../static/flags/na.svg";
import flag_NC from "../static/flags/nc.svg";
import flag_NE from "../static/flags/ne.svg";
import flag_NF from "../static/flags/nf.svg";
import flag_NG from "../static/flags/ng.svg";
import flag_NI from "../static/flags/ni.svg";
import flag_NL from "../static/flags/nl.svg";
import flag_NO from "../static/flags/no.svg";
import flag_NP from "../static/flags/np.svg";
import flag_NR from "../static/flags/nr.svg";
import flag_NU from "../static/flags/nu.svg";
import flag_NZ from "../static/flags/nz.svg";
import flag_OM from "../static/flags/om.svg";
import flag_PA from "../static/flags/pa.svg";
import flag_PE from "../static/flags/pe.svg";
import flag_PF from "../static/flags/pf.svg";
import flag_PG from "../static/flags/pg.svg";
import flag_PH from "../static/flags/ph.svg";
import flag_PK from "../static/flags/pk.svg";
import flag_PL from "../static/flags/pl.svg";
import flag_PM from "../static/flags/pm.svg";
import flag_PN from "../static/flags/pn.svg";
import flag_PR from "../static/flags/pr.svg";
import flag_PS from "../static/flags/ps.svg";
import flag_PT from "../static/flags/pt.svg";
import flag_PW from "../static/flags/pw.svg";
import flag_PY from "../static/flags/py.svg";
import flag_QA from "../static/flags/qa.svg";
import flag_RE from "../static/flags/re.svg";
import flag_RO from "../static/flags/ro.svg";
import flag_RS from "../static/flags/rs.svg";
import flag_RU from "../static/flags/ru.svg";
import flag_RW from "../static/flags/rw.svg";
import flag_SA from "../static/flags/sa.svg";
import flag_SB from "../static/flags/sb.svg";
import flag_SC from "../static/flags/sc.svg";
import flag_SD from "../static/flags/sd.svg";
import flag_SE from "../static/flags/se.svg";
import flag_SG from "../static/flags/sg.svg";
import flag_SH from "../static/flags/sh.svg";
import flag_SI from "../static/flags/si.svg";
import flag_SJ from "../static/flags/sj.svg";
import flag_SK from "../static/flags/sk.svg";
import flag_SL from "../static/flags/sl.svg";
import flag_SM from "../static/flags/sm.svg";
import flag_SN from "../static/flags/sn.svg";
import flag_SO from "../static/flags/so.svg";
import flag_SR from "../static/flags/sr.svg";
import flag_SS from "../static/flags/ss.svg";
import flag_ST from "../static/flags/st.svg";
import flag_SV from "../static/flags/sv.svg";
import flag_SX from "../static/flags/sx.svg";
import flag_SY from "../static/flags/sy.svg";
import flag_SZ from "../static/flags/sz.svg";
import flag_TC from "../static/flags/tc.svg";
import flag_TD from "../static/flags/td.svg";
import flag_TF from "../static/flags/tf.svg";
import flag_TG from "../static/flags/tg.svg";
import flag_TH from "../static/flags/th.svg";
import flag_TJ from "../static/flags/tj.svg";
import flag_TK from "../static/flags/tk.svg";
import flag_TL from "../static/flags/tl.svg";
import flag_TM from "../static/flags/tm.svg";
import flag_TN from "../static/flags/tn.svg";
import flag_TO from "../static/flags/to.svg";
import flag_TR from "../static/flags/tr.svg";
import flag_TT from "../static/flags/tt.svg";
import flag_TV from "../static/flags/tv.svg";
import flag_TW from "../static/flags/tw.svg";
import flag_TZ from "../static/flags/tz.svg";
import flag_UA from "../static/flags/ua.svg";
import flag_UG from "../static/flags/ug.svg";
import flag_UM from "../static/flags/um.svg";
import flag_US from "../static/flags/us.svg";
import flag_UY from "../static/flags/uy.svg";
import flag_UZ from "../static/flags/uz.svg";
import flag_VA from "../static/flags/va.svg";
import flag_VC from "../static/flags/vc.svg";
import flag_VE from "../static/flags/ve.svg";
import flag_VG from "../static/flags/vg.svg";
import flag_VI from "../static/flags/vi.svg";
import flag_VN from "../static/flags/vn.svg";
import flag_VU from "../static/flags/vu.svg";
import flag_WF from "../static/flags/wf.svg";
import flag_XK from "../static/flags/xk.svg";
import flag_WS from "../static/flags/ws.svg";
import flag_YE from "../static/flags/ye.svg";
import flag_YT from "../static/flags/yt.svg";
import flag_ZA from "../static/flags/za.svg";
import flag_ZM from "../static/flags/zm.svg";
import flag_ZW from "../static/flags/zw.svg";
export default {
  flag_AD,
  flag_AE,
  flag_AF,
  flag_AG,
  flag_AI,
  flag_AL,
  flag_AM,
  flag_AN,
  flag_AO,
  flag_AQ,
  flag_AR,
  flag_AS,
  flag_AT,
  flag_AU,
  flag_AW,
  flag_AX,
  flag_AZ,
  flag_BA,
  flag_BB,
  flag_BD,
  flag_BE,
  flag_BF,
  flag_BG,
  flag_BH,
  flag_BI,
  flag_BJ,
  flag_BL,
  flag_BM,
  flag_BN,
  flag_BO,
  flag_BQ,
  flag_BR,
  flag_BS,
  flag_BT,
  flag_BV,
  flag_BW,
  flag_BY,
  flag_BZ,
  flag_CA,
  flag_CC,
  flag_CD,
  flag_CF,
  flag_CG,
  flag_CH,
  flag_CI,
  flag_CK,
  flag_CL,
  flag_CM,
  flag_CN,
  flag_CO,
  flag_CR,
  flag_CU,
  flag_CV,
  flag_CW,
  flag_CX,
  flag_CY,
  flag_CZ,
  flag_DE,
  flag_DJ,
  flag_DK,
  flag_DM,
  flag_DO,
  flag_DZ,
  flag_EC,
  flag_EE,
  flag_EG,
  flag_EH,
  flag_ER,
  flag_ES,
  flag_ET,
  flag_EU,
  flag_FI,
  flag_FJ,
  flag_FK,
  flag_FM,
  flag_FO,
  flag_FR,
  flag_GA,
  flag_GB_ENG,
  flag_GB_NIR,
  flag_GB_SCT,
  flag_GB_WLS,
  flag_GB,
  flag_GD,
  flag_GE,
  flag_GF,
  flag_GG,
  flag_GH,
  flag_GI,
  flag_GL,
  flag_GM,
  flag_GN,
  flag_GP,
  flag_GQ,
  flag_GR,
  flag_GS,
  flag_GT,
  flag_GU,
  flag_GW,
  flag_GY,
  flag_HK,
  flag_HM,
  flag_HN,
  flag_HR,
  flag_HT,
  flag_HU,
  flag_ID,
  flag_IE,
  flag_IL,
  flag_IM,
  flag_IN,
  flag_IO,
  flag_IQ,
  flag_IR,
  flag_IS,
  flag_IT,
  flag_JE,
  flag_JM,
  flag_JO,
  flag_JP,
  flag_KE,
  flag_KG,
  flag_KH,
  flag_KI,
  flag_KM,
  flag_KN,
  flag_KP,
  flag_KR,
  flag_KW,
  flag_KY,
  flag_KZ,
  flag_LA,
  flag_LB,
  flag_LC,
  flag_LI,
  flag_LK,
  flag_LR,
  flag_LS,
  flag_LT,
  flag_LU,
  flag_LV,
  flag_LY,
  flag_MA,
  flag_MC,
  flag_MD,
  flag_ME,
  flag_MF,
  flag_MG,
  flag_MH,
  flag_MK,
  flag_ML,
  flag_MM,
  flag_MN,
  flag_MO,
  flag_MP,
  flag_MQ,
  flag_MR,
  flag_MS,
  flag_MT,
  flag_MU,
  flag_MV,
  flag_MW,
  flag_MX,
  flag_MY,
  flag_MZ,
  flag_NA,
  flag_NC,
  flag_NE,
  flag_NF,
  flag_NG,
  flag_NI,
  flag_NL,
  flag_NO,
  flag_NP,
  flag_NR,
  flag_NU,
  flag_NZ,
  flag_OM,
  flag_PA,
  flag_PE,
  flag_PF,
  flag_PG,
  flag_PH,
  flag_PK,
  flag_PL,
  flag_PM,
  flag_PN,
  flag_PR,
  flag_PS,
  flag_PT,
  flag_PW,
  flag_PY,
  flag_QA,
  flag_RE,
  flag_RO,
  flag_RS,
  flag_RU,
  flag_RW,
  flag_SA,
  flag_SB,
  flag_SC,
  flag_SD,
  flag_SE,
  flag_SG,
  flag_SH,
  flag_SI,
  flag_SJ,
  flag_SK,
  flag_SL,
  flag_SM,
  flag_SN,
  flag_SO,
  flag_SR,
  flag_SS,
  flag_ST,
  flag_SV,
  flag_SX,
  flag_SY,
  flag_SZ,
  flag_TC,
  flag_TD,
  flag_TF,
  flag_TG,
  flag_TH,
  flag_TJ,
  flag_TK,
  flag_TL,
  flag_TM,
  flag_TN,
  flag_TO,
  flag_TR,
  flag_TT,
  flag_TV,
  flag_TW,
  flag_TZ,
  flag_UA,
  flag_UG,
  flag_UM,
  flag_US,
  flag_UY,
  flag_UZ,
  flag_VA,
  flag_VC,
  flag_VE,
  flag_VG,
  flag_VI,
  flag_VN,
  flag_VU,
  flag_WF,
  flag_XK,
  flag_WS,
  flag_YE,
  flag_YT,
  flag_ZA,
  flag_ZM,
  flag_ZW,
};
