import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import { User } from "polyverse-sdk-dev/dist/api/user";

import { useGoogleLogin } from "@react-oauth/google";

import {
  useMediaQuery,
  Box,
  Flex,
  Image,
  Text,
  VStack,
  FormControl,
  Circle,
  Input,
  Button,
  Link,
  Checkbox,
  useToast,
  Divider,
} from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";
import { useNavigate, Link as RLink } from "react-router-dom";
import AuthBg from "./AuthBg";
import logo from "../../static/polywallet-logo.svg";
import footerText from "../../static/icons/footer-text.svg";
import { FcGoogle } from "react-icons/fc";
import { FaXTwitter } from "react-icons/fa6";

const SignUp = () => {
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery("(max-width: 480px)");
  const [isDesktop] = useMediaQuery("(min-width: 1025px)");

  const [accountCreated, setAccountCreated] = useState(false);

  const navigate = useNavigate("");
  const toast = useToast();

  const handleSignUp = async (values, actions) => {
    const emailParts = values.email.split("@");

    if (values.password.length < 8 || values.password.length > 50) {
      toast({
        title: "Error",
        description: t("login.passwordLengthError"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      actions.setSubmitting(false);
      return;
    }

    try {
      const addressType = checkAddressType(values.email);

      if (addressType === "phone") {
        // Telefon numarasının başındaki '+' işaretini '00' ile değiştir
        const phone = values.email.replace(/^\+/, "00");

        const signUpResponse = await User.create(
          "",
          values.password,
          phone, // Düzeltilmiş telefon numarası
          phone,
          "",
          2251,
          "wxcguoTzCmuuBdezpAVElaCeZd"
        );
      } else {
        const signUpResponse = await User.create(
          values.email,
          values.password,
          emailParts[0],
          "",
          "",
          2251,
          "wxcguoTzCmuuBdezpAVElaCeZd"
        );
      }

      setAccountCreated(true);
    } catch (error) {
      console.log("Error:", error);
      toast({
        title: t("login.createAccountFailed"),
        description:
          error.message || "An error occurred while account creating.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      actions.setSubmitting(false);
    }
  };

  const checkAddressType = (address) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // '+' işareti ile başlayabilir ve 10 ile 15 arasında rakam içerebilir
    const phoneRegex = /^\+?\d{10,15}$/;

    // İlk üç karakterin hepsi sayı mı veya '+' işareti ile mi başlıyor kontrol et
    const isPhone =
      address.length >= 3 &&
      (/^\d{3}/.test(address) || address.startsWith("+"));

    // İlk üç karakter sayıysa veya '+' ile başlıyorsa ve "@" içermiyorsa, telefon olarak kabul et
    if (isPhone && !address.includes("@")) {
      console.log("Telefon");
      return phoneRegex.test(address) ? "phone" : "invalid";
    }
    // Eğer "@" işareti içeriyorsa veya ilk üç karakter sayı değilse ve '+' ile başlamıyorsa, e-posta adresi olarak kabul et
    else if (address.includes("@") || (!isPhone && !address.startsWith("+"))) {
      return emailRegex.test(address) ? "email" : "invalid";
    }
    // Diğer durumlar için geçersiz olarak kabul et
    else {
      return "invalid";
    }
  };

  const validateForm = (values) => {
    const errors = {};

    const addressType = checkAddressType(values.email);

    // Eğer adres tipi email ise ve email boşsa
    if (addressType === "email" && !values.email) {
      errors.email = t("login.emailRequired");
    }
    // Eğer adres tipi email ise ve geçerli değilse
    else if (
      addressType === "email" &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = t("login.invalidEmail");
    }
    // Eğer adres tipi telefon ise ve geçerli değilse
    else if (addressType === "phone") {
      if (!values.email.startsWith("+")) {
        errors.email = t("login.phoneNrMustStart");
      } else if (!/^\+?\d{10,15}$/.test(values.email)) {
        errors.email = "Invalid phone number";
      }
    }
    // Eğer adres tipi geçerli değilse
    else if (addressType === "invalid") {
      errors.email = t("login.invalidPhoneNumberOrEmail");
    }

    // Parola doğrulamaları
    if (!values.password) {
      errors.password = t("login.passwordRequired");
    } else if (values.password.length < 8) {
      errors.password = t("login.passwordLeast");
    } else if (values.password.length > 50) {
      errors.password = t("login.passwordExceed");
    }

    return errors;
  };

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      getUserInfo(tokenResponse.access_token); // Kullanıcı bilgilerini getir
    },
  });

  const getUserInfo = async (accessToken) => {
    try {
      const response = await fetch(
        "https://www.googleapis.com/oauth2/v2/userinfo",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = await response.json();

      try {
        const responseOAuth2 = await User.oAuth2Google(
          2251,
          "wxcguoTzCmuuBdezpAVElaCeZd",
          accessToken,
          data.email,
          data.name,
          data.id
        );

        console.log(responseOAuth2);

        setAccountCreated(true);
      } catch (error) {
        console.log("Error:", error);
        toast({
          title: t("login.createAccountFailed"),
          description:
            error.message || "An error occurred while account creating.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("User info fetch failed", error);
    }
  };

  const loginX = async () => {
    try {
      var response = await User.TwitterAuth(2251, "wxcguoTzCmuuBdezpAVElaCeZd");
      //forward to twitter login page with use response
      window.location.href = response;
    } catch (error) {
      console.log("Error:", error);
      toast({
        title: "Login Failed",
        description: error.message || "An error occurred while logging in.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const getStyleValue = (desktopValue, mobileValue, isTablet) =>
    isDesktop ? desktopValue : isMobile ? mobileValue : isTablet;
  const paddingX = getStyleValue("200px", "30px", "20px");
  const paddingY = getStyleValue("25px", "70px", "50px");
  const boxWidth = getStyleValue("65%", "90%", "80%");
  const boxHeight = getStyleValue("580px", "80%", "590px");

  const boxStyles = {
    position: "absolute",
    width: boxWidth,
    height: boxHeight,
    px: paddingX,
    py: paddingY,
    borderRadius: "50px",
    border: "3px solid #333",
    background: "#111111B2",
    zIndex: 1,
  };

  return (
    <Flex justifyContent="center" alignItems="center">
      <Box {...boxStyles}>
        <Image
          src={logo}
          position="absolute"
          top={{ base: "30px", md: "30px", lg: "30px" }}
          left={{ base: "30px", md: "40px", lg: "45px" }}
          width={{ base: "100px", md: "130px", lg: "163px" }}
        />
        <Flex justifyContent="center" alignItems="center" direction="column">
          <VStack align="center" mt={{ base: "30px", md: "40px", lg: "50px" }}>
            {accountCreated ? (
              <Box mt={20}>
                <Text
                  fontWeight="700"
                  fontSize="2xl"
                  width={{ base: "250px", md: "350px", lg: "393px" }}
                >
                  Your account has been created!
                </Text>
                <Flex justifyContent="center" mt={5}>
                  <Text fontSize="2xl">🕺</Text>
                </Flex>
                <Flex justifyContent="center" mt={5}>
                  <Link as={RLink} to={"/login"}>
                    Return to Login
                  </Link>
                </Flex>
              </Box>
            ) : (
              <>
                <Text
                  fontWeight="700"
                  fontSize="2xl"
                  width={{ base: "250px", md: "350px", lg: "393px" }}
                  mb={{ base: "10px", md: "10px", lg: "15px" }}
                >
                  {t("login.createAccount")}
                </Text>

                <Formik
                  initialValues={{
                    username: "",
                    email: "",
                    password: "",
                    termsAccepted: false,
                  }}
                  validate={validateForm}
                  onSubmit={handleSignUp}
                >
                  {(props) => (
                    <Form noValidate>
                      <VStack alignItems="stretch">
                        <FormControl
                          id="email"
                          isInvalid={props.errors.email && props.touched.email}
                        >
                          <Field
                            as={Input}
                            name="email"
                            placeholder={t("login.emailOrPhone")}
                            background="white"
                            color="black"
                            width={{ base: "250px", md: "350px", lg: "393px" }}
                            height={{ base: "35px", md: "40px", lg: "50px" }}
                          />

                          <Box
                            height="2px"
                            mb={{ base: "10px", md: "15px", lg: "20px" }}
                          >
                            {props.errors.email && props.touched.email ? (
                              <Text fontSize="sm" color="red.500">
                                {props.errors.email}
                              </Text>
                            ) : null}
                          </Box>
                        </FormControl>

                        <FormControl
                          id="password"
                          isInvalid={
                            props.errors.password && props.touched.password
                          }
                        >
                          <Field
                            as={Input}
                            name="password"
                            type="password"
                            placeholder={t("login.password")}
                            maxLength="50"
                            minLength="8"
                            background="white"
                            color="black"
                            width={{ base: "250px", md: "350px", lg: "390px" }}
                            height={{ base: "35px", md: "40px", lg: "50px" }}
                          />
                          <Box
                            height="2px"
                            mb={{ base: "4px", md: "6px", lg: "8px" }}
                          >
                            {props.errors.password && props.touched.password ? (
                              <Text fontSize="sm" color="red.500">
                                {props.errors.password}
                              </Text>
                            ) : null}
                          </Box>
                        </FormControl>
                        <Flex
                          width={{ base: "250px", md: "350px", lg: "393px" }}
                          mb={{ base: "4px", md: "6px", lg: "8px" }}
                          mt={{ base: "4px", md: "6px", lg: "8px" }}
                        >
                          <FormControl
                            id="termsAccepted"
                            isInvalid={
                              props.errors.termsAccepted &&
                              props.touched.termsAccepted
                            }
                          >
                            <Field
                              as={Checkbox}
                              name="termsAccepted"
                              colorScheme="purple"
                            >
                              <Text as="span" fontWeight="400" fontSize="sm">
                                <Link
                                  href="tos"
                                  color="#753CC5"
                                  fontWeight="400"
                                  fontSize="sm"
                                  isExternal
                                >
                                  {t("agreementPrefix")}
                                </Link>{" "}
                                {t("and")}{" "}
                                <Link
                                  href="pp"
                                  color="#753CC5"
                                  fontWeight="400"
                                  fontSize="sm"
                                  isExternal
                                >
                                  {t("privacyPolicy")}
                                </Link>{" "}
                                {t("agreementSuffix")}
                              </Text>
                            </Field>
                            {props.errors.termsAccepted &&
                              props.touched.termsAccepted && (
                                <Text fontSize="sm" color="red.500">
                                  {props.errors.termsAccepted}
                                </Text>
                              )}
                          </FormControl>
                        </Flex>

                        <Button
                          width={{ base: "250px", md: "350px", lg: "393px" }}
                          height={{ base: "35px", md: "40px", lg: "50px" }}
                          type="submit"
                          mb={3}
                          bg="#33333380"
                          color="#FFFFFF99"
                          _hover={{ color: "white", bg: "#333" }}
                          _active={{ color: "white", bg: "#222" }}
                          isLoading={props.isSubmitting}
                        >
                          {t("login.signUp")}
                        </Button>

                        <Flex align="center" py="1" color="#888">
                          <Divider flex="1" borderColor="#888" />
                          <Text px="3" fontSize="sm" minWidth="fit-content">
                            {t("login.moreCreateAccountOptions")}
                          </Text>
                          <Divider flex="1" borderColor="#888" />
                        </Flex>

                        <Flex
                          justifyContent="center"
                          align="center"
                          pb="1"
                          gap={{ base: "4", md: "5" }}
                        >
                          <Circle bg="#1E1E1E" p="2" border="1px solid #333">
                            <FaXTwitter
                              size="24"
                              onClick={() => loginX()}
                              cursor={"pointer"}
                            />
                          </Circle>
                          <Circle bg="#1E1E1E" p="2" border="1px solid #333">
                            <FcGoogle
                              size="24"
                              onClick={() => login()}
                              cursor={"pointer"}
                            />
                          </Circle>
                        </Flex>
                      </VStack>
                    </Form>
                  )}
                </Formik>

                <Flex alignItems="center" textAlign="center" justify="center">
                  <Text fontWeight="400" fontSize="md">
                    {t("login.alreadyHaveAccount")}
                  </Text>
                  <Button
                    variant="link"
                    onClick={() => navigate("/Login")}
                    fontWeight="400"
                    fontSize="md"
                    color="#753CC5"
                    ml={{ base: "6px", md: "8px", lg: "10px" }}
                  >
                    {t("login.signIn")}
                  </Button>
                </Flex>
              </>
            )}
          </VStack>
        </Flex>

        <Image
          src={footerText}
          bottom="30px"
          right="50px"
          position="absolute"
          width={{ base: "100px", md: "120px", lg: "150px" }}
          height={{ base: "auto", md: "auto", lg: "20px" }}
        />
      </Box>
      <AuthBg />
    </Flex>
  );
};

export default SignUp;
