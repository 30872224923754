import { ChakraProvider, CSSReset } from "@chakra-ui/react";
import { GoogleOAuthProvider } from "@react-oauth/google";

import "./Translation";

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import theme from "./themes/theme";
import Wallet from "./pages/Wallet/Wallet";
import Swap from "./pages/Swap";
import Buy from "./pages/Buy";
import Main from "./pages/Main";
import Login from "./pages/Home/Login";
import SignUp from "./pages/Home/Signup";
import VerifyEmail from "./pages/Home/VerifyEmail";
import TwoFactorAuth from "./pages/Home/TwoFactorAuth";
import ResetPassword from "./pages/Home/ResetPassword";
import Account from "./pages/Account";
import Tools from "./pages/Tools";
import NftMint from "./pages/NftMint";
import Transfer from "./pages/Transfer/Transfer";
import ValidateTwitterAuth from "./pages/ValidateTwitterAuth";
import Portfolio from "./pages/Portfolio/Portfolio";
import ResetPasswordComplete from "./pages/Home/ResetPasswordComplete";
import KycVerify from "./pages/KycVerify";
import Index from "./pages/Index";
import NFTDrops from "./pages/NFTDrops";
import PolyAI from "./pages/PolyAI/PolyAI";
import GasMeter from "./pages/GasMeter";

function App() {
  return (
    <GoogleOAuthProvider clientId="600236510828-3v3onechb6bc1cfcrpfsl943896rcnjm.apps.googleusercontent.com">
      <ChakraProvider theme={theme}>
        <CSSReset />
        <Router>
          <Routes>
            <Route path="login" element={<Login />} />
            <Route path="signup" element={<SignUp />} />
            <Route path="resetpassword" element={<ResetPassword />} />
            <Route
              path="resetpasswordcomplete"
              element={<ResetPasswordComplete />}
            />
            <Route path="verifyemail" element={<VerifyEmail />} />
            <Route path="twofactorauth" element={<TwoFactorAuth />} />
            <Route path="/" element={<Index />} />
            <Route
              path="ValidateTwitterAuth"
              element={<ValidateTwitterAuth />}
            />
            <Route path="/" element={<Main />}>
              <Route path="wallet" element={<Wallet />} />
              <Route path="transfer" element={<Transfer />} />
              <Route path="swap" element={<Swap />} />
              <Route path="buy" element={<Buy />} />
              <Route path="portfolio" element={<Portfolio />} />
              <Route path="tools" element={<Tools />} />
              <Route path="poly-ai" element={<PolyAI />} />
              <Route path="gas-meter" element={<GasMeter />} />
              <Route path="nft-mint" element={<NftMint />} />
              <Route path="nft-drops" element={<NFTDrops />} />
              <Route path="account" element={<Account />} />
              <Route path="kyc" element={<KycVerify />} />
            </Route>
          </Routes>
        </Router>
      </ChakraProvider>
    </GoogleOAuthProvider>
  );
}
export default App;
