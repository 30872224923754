import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Çevirilerin tanımlandığı yer
const resources = {
  en: {
    translation: {
      wallet: "Wallet",
      portfolio: "Portfolio",
      tools: "Tools",
      swap: "Swap",
      buy: "Buy",
      account: "Account",
      support: "Support",
      _24HChange: "24H Change",
      _24H: "24H",
      send: "Send",
      receive: "Receive",
      notifications: "Notifications",
      received: "Received",
      sent: "Sent",
      incoming: "Incoming",
      outgoing: "Outgoing",
      "transaction.send": "Send {{displayText}} {{status}} on {{networkName}}",
      "transaction.receive":
        "Receive {{displayText}} {{status}} on {{networkName}}",
      "transaction.mint": "{{displayText}} {{status}} on {{networkName}}",
      "transaction.status.succeeded": "succeeded",
      "transaction.status.failed": "failed",
      "transaction.status.unknown": "unknown",
      details: "Details",
      network: "Network",
      transactionStatus: "Transaction Status",
      transactionHash: "Transaction Hash",
      tokenId: "Token ID",
      status: "Status",
      successful: "Successful",
      failed: "Failed",
      unknown: "Unknown",
      amount: "Amount",
      viewOnExplorer: "View on Explorer",
      noTokensAvailable: "No tokens available",
      tokens: "Tokens",
      nfts: "NFTs",
      balance: "Balance",
      price: "Price",
      gasToken: "Gas Token",
      gasTokenTooltip:
        "Gas Token is used to pay for gas fees, enabling you to perform actions on {{networkName}}",
      search: "Search",
      filter: "Filter",
      "filter.hideZeroBalance": "Hide zero balance",
      "filter.showOnlyNativeCurrency": "Show only native currency",
      "filter.allNetworks": "All Networks",
      sendNFT: "Send NFT",
      copiedClipboard: "Copied to clipboard",
      copied: "Copied",
      description: "Description",
      contractAddress: "Contract Address",
      standard: "Standart",
      backToDetails: "Back to Details",
      "message.welcomeNewUser":
        "Welcome to PolyWallet. Your generated password: {{password}}. You can login at wallet.polyverse.life",
      "message.newTransfer": "You've received a new transfer in PolyWallet!",
      to: "To",
      networkFee: "Network Fee",
      txSucceded: "Transaction succeeded",
      done: "Done",
      estGasFee: "Estimated Gas Fee",
      invalidDate: "Invalid Date",
      total: "Total",
      lastMonth: "Last Month",
      lastWeek: "Last Week",
      shortMonth: "M",
      shortWeek: "W",
      trackYourPortfolio: "Track Your Portfolio Now",
      deposit: "Deposit",
      date: "Date",
      noValueForTestNets: "No value for testnets",
      noNFTs: "No NFTs available.",
      collection: "Collection",
      floorPrice: "Floor Price",
      currency: "Currency",
      volume: "Volume",
      "tools.nftDrop.title": "NFT Drops",
      "tools.nftDrop.description":
        "Experience the thrill of exclusive NFT Drops! Participate directly through our wallet by simply",
      "tools.paymentRequest.title": "Payment Request",
      "tools.paymentRequest.description":
        "Generate a request that can be shared via a link or QR code, making it incredibly easy for the sender to fulfill your request with precision and speed.",
      "tools.giveaway.title": "Giveaway",
      "tools.giveaway.description":
        "Host your own Giveaway directly through our web3 wallet! As a wallet owner, effortlessly set up and manage your giveaway campaigns.",
      comingSoon: "Coming Soon!",
      toolsNotActivatedYet: "This tool is not active yet.",
      selectToken: "Select a Token",
      fromToken: "From",
      toToken: "To",
      slippageTolerance: "Slippage Tolerance",
      custom: "Custom",
      activities: "Activities",
      "invalidPhoneNumber.title": "Invalid phone number",
      "invalidPhoneNumber.description":
        "Phone numbers should start with the country code (+)",
      emailTransferNotification: "PolyWallet Transfer Notification",
      transferError: "Transfer Error",
      transferErrorDescription: "Transfer failed. Please try again later.",
      transferToPlaceHolder: "Mobile, email or wallet address",
      cancel: "Cancel",
      sentTo: "Sent to {{address}}",
      transactionSuccessful: " Transaction successful!",
      max: "Max",
      insufficient: "Insufficient",
      address: "Address",
      noTransactionsFound: "No transactions found",
      selectNetwork: "Please select a network",
      verified: "Verified",
      unverified: "Unverified",
      logOut: "Log Out",
      pleaseWait: "Please wait...",
      "message.waitEmailCooldown":
        "Wait for {{emailCooldown}} seconds before requesting a new code.",
      "message.vefiricationCodeSent": "Verification code sent to {{email}}",
      "message.verificationCodeFailed": "Verification code failed",
      "message.verificationFailed": "Verification failed",
      "message.verificationPleaseEnterCode":
        "Please enter the verification code.",
      "message.verifiedEmail": "Your email address has been verified.",
      "message.pleaseEnterPhone": "Please enter the phone number.",
      "message.verificationCodeSent": "Verification Code Sent",
      "message.verifiedPhone": "Your phone number has been verified.",
      verifyEmail: "Verify Email",
      safetyEmail: " Enhance the safety of your account by email verification",
      bindPhoneNumber: "Bind Phone Number",
      safetyPhone: "Enhance the safety of your account by phone verification",
      showTestNets: "Show Testnets",
      selectCurrency: "Select a Currency",
      noCurrencyFound: "Currencies could not be found",
      askGas: "Ask for gas",
      name: "Name",
      userID: "User ID",
      email: "Email",
      phoneNumber: "Phone Number",
      showTestnets: "Show Tesnets",
      profile: "Profile",
      security: "Security",
      phoneVerificationNeed: "Phone verification is required",
      pleaseVerifyPhoneTwoFactor:
        "Please verify your phone number to enable Two-Factor Authentication.",
      twoFactorDisabled: "Two-Factor Authentication is disabled",
      twoFactorDisabledDescription:
        "Your account now has an additional layer of security.",
      twoFactorDisabledError:
        "There was an issue enabling Two-Factor Authentication.",
      twoFactorEnabled: "Two-Factor Authentication is enabled",
      twoFactorEnabledDescription:
        "Your account now has an additional layer of security.",
      fieldsMustFilled: "All fields must be filled",
      newPasswordLeast: "New password must be at least 8 characters long.",
      newPasswordExceed: "New password must not exceed 50 characters.",
      newPasswordNotMatch: "New password does not match.",
      newPasswordChangedSuccessfully: "New password changed successfully.",
      twoFactorAuthTitle: "Two-Factor Authentication",
      twoFactorAuthDescription:
        "Enhance the safty of your account by Two-Factor authentication.",
      continue: "Continue",
      password: "Password",
      change: "Change",
      changePassword: "Change Password",
      termsOfUse: "Terms of Use",
      privacyPolicy: "Privacy Policy",
      helpCenter: "Help Center",
      faq: "FAQ",
      "login.title": "Welcome Back!",
      "login.description":
        "Welcome to PolyWallet. Please login with your personal account information",
      "login.forgotPassword": "Forgot your password?",
      "login.login": "Login",
      "login.moreLoginOptions": "More login options",
      "login.areYouNewUser": "Are you a new user?",
      "login.signUp": "Create an account",
      "login.passwordLengthError":
        "Password must be between 8 and 50 characters long.",
      "login.createAccountFailed": "Failed to create account",
      "login.emailRequired": "Email is required",
      "login.emailInvalid": "Email is invalid",
      "login.phoneNrMustStart":
        "Phone number must start with a '+' and include the country code",
      "login.invalidPhoneNumberOrEmail": "Invalid phone number or email",
      "login.passwordRequired": "Password is required",
      "login.passwordLeast": "Password must be at least 8 characters long",
      "login.passwordExceed": "Password must not exceed 50 characters",
      "login.createAccount": "Create Account",
      "login.emailOrPhone": "Email or Phone Number",
      "login.password": "Password",
      "login.moreCreateAccountOptions": "More create account options",
      "login.alreadyHaveAccount": "Already have an account?",
      "login.signIn": "Sign In",
      agreementPrefix: "I agree to the",
      termsOfService: "terms of service",
      and: "and",
      agreementSuffix: ".",
      usernameIsRequired: "Username is required",
      resetPasswordMessage:
        "You will receive an email with a link that resets your password.",
      resetPassword: "Reset Password",
      priceNotFound: "Price not found",
      passwordHasBeenChanged: "Password has been changed",
      newPassword: "New Password",
      required: "Required",
      invalidCodeTwoFactor: "Invalid code. Please enter numbers only.",
      loginFailed: "Login failed",
      twoFactorPlease:
        "Please enter the verification code sent to your mobile phone to complete the login process.",
      enterVerificationCode: "Enter Verification Code",
      sumbit: "Submit",
      emailVerified: "Email verified",
      emailVerifiedDescription: "Your email address has been verified.",
      error: "Error",
      verificationCodeLengthError: "Verification code must be 4 digits long.",
      verificationCodeRequired: "Verification code is required.",
      verificationCodeInvalid: "Verification code is invalid.",
      verifyYourEmail: "Verify Your Email",
      verifyYourEmailDescription:
        "A verification code has been sent to your email. Please verify to maintain full wallet functionality.",
      verifyCodeEnter: "Enter verification code",
      resendCode: "Resend code",
      contactUs: "Contact Us",
      community: "Community",
      contract: "Contract",
      "link.sss": "https://support.polywallet.app/en/",
      "link.contactUs": "mailto:support@polyverse.life",
      "link.x": "https://twitter.com/polywalletapp",
    },
  },
  tr: {
    translation: {
      wallet: "Cüzdan",
      portfolio: "Portföy",
      tools: "Araçlar",
      swap: "Takas",
      buy: "Kripto Al",
      account: "Hesap",
      support: "Destek",
      _24HChange: "24S Değişim",
      _24H: "24S",
      send: "Gönder",
      receive: "Al",
      notifications: "Bildirimler",
      received: "Alındı",
      sent: "Gönderildi",
      incoming: "Gelen",
      outgoing: "Giden",
      "transaction.send":
        "{{displayText}} {{networkName}} üzerinde {{status}} şekilde gönderildi",
      "transaction.receive":
        "{{displayText}} {{networkName}} üzerinde {{status}} şekilde alındı",
      "transaction.mint": "{{networkName}} üzerinde {{status}} {{displayText}}",
      "transaction.status.succeeded": "başarılı",
      "transaction.status.failed": "başarısız",
      "transaction.status.unknown": "bilinmeyen",
      details: "Detaylar",
      network: "Ağ",
      transactionStatus: "İşlem Durumu",
      transactionHash: "İşlem Kimliği",
      tokenId: "Token Kimliği",
      status: "Durum",
      successful: "Başarılı",
      failed: "Başarısız",
      unknown: "Bilinmeyen",
      amount: "Miktar",
      viewOnExplorer: "Tarayıcıda Görüntüle",
      noTokensAvailable: "Mevcut token yok",
      tokens: "Tokenlar",
      nfts: "NFTler",
      balance: "Bakiye",
      price: "Fiyat",
      gasToken: "Gas Token",
      gasTokenTooltip:
        "Gas Token, {{networkName}} üzerinde işlem yapmak için gaz ücretini ödemek sırasında kullanılır.",
      search: "Ara",
      filter: "Filtrele",
      "filter.hideZeroBalance": "Sıfır bakiyeyi gizle",
      "filter.showOnlyNativeCurrency": "Sadece yerel para birimini göster",
      "filter.allNetworks": "Tüm Ağlar",
      sendNFT: "NFT Gönder",
      copiedClipboard: "Panoya kopyalandı",
      copied: "Kopyalandı",
      description: "Açıklama",
      contractAddress: "Sözleşme Adresi",
      standard: "Standart",
      backToDetails: "Detaylara Geri Dön",
      "message.welcomeNewUser":
        "PolyWallet'e hoş geldiniz. Oluşturulan şifreniz: {{password}}. wallet.polyverse.life adresinden giriş yapabilirsiniz",
      "message.newTransfer": "PolyWallet'te yeni bir transfer aldınız!",
      to: "Alıcı",
      networkFee: "Ağ Ücreti",
      txSucceded: "İşlem başarılı",
      done: "Tamamlandı",
      estGasFee: "Tahmini Gaz Ücreti",
      invalidDate: "Geçersiz Tarih",
      total: "Toplam",
      lastMonth: "Geçen Ay",
      lastWeek: "Geçen Hafta",
      shortMonth: "A",
      shortWeek: "H",
      trackYourPortfolio: "Portföyünüzü Şimdi Takip Edin",
      deposit: "Yatır",
      date: "Tarih",
      noValueForTestNets: "Test ağları için değer yok",
      noNFTs: "Hesabınızda NFT yok.",
      collection: "Koleksiyon",
      floorPrice: "Minimum Fiyat",
      currency: "Para Birimi",
      volume: "Hacim",
      "tools.nftDrop.title": "NFT Drop'ları",
      "tools.nftDrop.description":
        "Özel NFT Drop'larının heyecanını yaşayın! cüzdanımız aracılığıyla katılın ve sadece",
      "tools.paymentRequest.title": "Ödeme Talebi",
      "tools.paymentRequest.description":
        "Ödeme talebi oluşturun ve gönderenin talebinizi hassasiyet ve hızla yerine getirmesini sağlayan bir bağlantı veya QR kodu aracılığıyla paylaşın.",
      "tools.giveaway.title": "Çekiliş",
      "tools.giveaway.description":
        "Kendi Çekilişinizi doğrudan web3 cüzdanımız aracılığıyla yapın! Cüzdan sahibi olarak, çekiliş kampanyalarınızı kolayca oluşturun ve yönetin.",
      comingSoon: "Çok Yakında!",
      toolsNotActivatedYet: "Bu araç henüz aktif değil.",
      selectToken: "Bir Token Seç",
      fromToken: "Değiştirilecek",
      toToken: "Dönüştürülen",
      slippageTolerance: "Kayma Toleransı",
      custom: "Özel",
      activities: "Hareketler",
      "invalidPhoneNumber.title": "Geçersiz telefon numarası",
      "invalidPhoneNumber.description":
        "Telefon numaraları ülke kodu (+) ile başlamalıdır",
      emailTransferNotification: "PolyWallet Transfer Bildirimi",
      transferError: "Transfer Hatası",
      transferErrorDescription:
        "Transfer başarısız oldu. Lütfen daha sonra tekrar deneyin.",
      transferToPlaceHolder: "Mobil, e-posta veya cüzdan adresi",
      cancel: "İptal",
      sentTo: "Gönderildi {{address}}",
      transactionSuccessful: " İşlem başarılı!",
      max: "Maksimum",
      insufficient: "Yetersiz",
      address: "Adres",
      noTransactionsFound: "İşlem bulunamadı",
      selectNetwork: "Lütfen bir ağ seçin",
      verified: "Doğrulandı",
      unverified: "Doğrulanmadı",
      logOut: "Çıkış Yap",
      pleaseWait: "Lütfen bekleyin...",
      "message.waitEmailCooldown":
        "Yeni bir kod talep etmeden önce {{emailCooldown}} saniye bekleyin.",
      "message.vefiricationCodeSent":
        "{{email}} adresine doğrulama kodu gönderildi",
      "message.verificationCodeFailed": "Doğrulama kodu başarısız oldu",
      "message.verificationFailed": "Doğrulama başarısız oldu",
      "message.verificationPleaseEnterCode": "Lütfen doğrulama kodunu girin.",
      "message.verifiedEmail": "E-posta adresiniz doğrulandı.",
      "message.pleaseEnterPhone": "Lütfen telefon numarasını girin.",
      "message.verificationCodeSent": "Doğrulama Kodu Gönderildi",
      "message.verifiedPhone": "Telefon numaranız doğrulandı.",
      verifyEmail: "E-posta Doğrula",
      safetyEmail: "E-posta doğrulaması ile hesabınızın güvenliğini artırın",
      bindPhoneNumber: "Telefon Numarası Bağla",
      safetyPhone: "Telefon doğrulaması ile hesabınızın güvenliğini artırın",
      showTestNets: "Test ağlarını göster",
      selectCurrency: "Bir Para Birimi Seç",
      noCurrencyFound: "Para birimleri bulunamadı",
      askGas: "Gaz Sor",
      name: "Ad",
      userID: "Kullanıcı Kimliği",
      email: "E-posta",
      phoneNumber: "Telefon Numarası",
      showTestnets: "Testnetleri Göster",
      profile: "Profil",
      security: "Güvenlik",
      phoneVerificationNeed: "Telefon doğrulaması gerekiyor",
      pleaseVerifyPhoneTwoFactor:
        "Lütfen iki faktörlü kimlik doğrulamasını etkinleştirmek için telefon numaranızı doğrulayın.",
      twoFactorDisabled: "İki faktörlü kimlik doğrulaması devre dışı bırakıldı",
      twoFactorDisabledDescription:
        "Hesabınız artık ek bir güvenlik katmanına sahip.",
      twoFactorDisabledError:
        "İki faktörlü kimlik doğrulaması etkinleştirilirken bir sorun oluştu.",
      twoFactorEnabled: "İki faktörlü kimlik doğrulaması etkinleştirildi",
      twoFactorEnabledDescription:
        "Hesabınız artık ek bir güvenlik katmanına sahip.",
      fieldsMustFilled: "Tüm alanlar doldurulmalıdır",
      newPasswordLeast: "Yeni şifre en az 8 karakter uzunluğunda olmalıdır.",
      newPasswordExceed: "Yeni şifre 50 karakteri geçmemelidir.",
      newPasswordNotMatch: "Yeni şifre eşleşmiyor.",
      newPasswordChangedSuccessfully: "Yeni şifre başarıyla değiştirildi.",
      twoFactorAuthTitle: "İki Faktörlü Kimlik Doğrulama",
      twoFactorAuthDescription:
        "Hesabınızın güvenliğini iki faktörlü kimlik doğrulama ile artırın.",
      continue: "Devam",
      password: "Şifre",
      change: "Değiştir",
      changePassword: "Şifre Değiştir",
      termsOfUse: "Kullanım Şartları",
      privacyPolicy: "Gizlilik Politikası",
      helpCenter: "Yardım Merkezi",
      faq: "Sıkça Sorulan Sorular",
      "login.title": "Tekrar Hoş Geldiniz!",
      "login.description":
        "PolyWallet'e hoş geldiniz. Lütfen kişisel hesap bilgilerinizle giriş yapın",
      "login.forgotPassword": "Şifrenizi mi unuttunuz?",
      "login.login": "Giriş Yap",
      "login.moreLoginOptions": "Daha fazla giriş seçeneği",
      "login.areYouNewUser": "Yeni bir kullanıcı mısınız?",
      "login.signUp": "Hesap oluştur",
      "login.passwordLengthError":
        "Şifre 8 ile 50 karakter arasında olmalıdır.",
      "login.createAccountFailed": "Hesap oluşturulamadı",
      "login.emailRequired": "E-posta gerekli",
      "login.emailInvalid": "E-posta geçersiz",
      "login.phoneNrMustStart":
        "Telefon numarası '+' ile başlamalı ve ülke kodunu içermelidir",
      "login.invalidPhoneNumberOrEmail":
        "Geçersiz telefon numarası veya e-posta",
      "login.passwordRequired": "Şifre gerekli",
      "login.passwordLeast": "Şifre en az 8 karakter uzunluğunda olmalıdır",
      "login.passwordExceed": "Şifre 50 karakteri geçmemelidir",
      "login.createAccount": "Hesap Oluştur",
      "login.emailOrPhone": "E-posta veya Telefon Numarası",
      "login.password": "Şifre",
      "login.moreCreateAccountOptions": "Daha fazla hesap oluşturma seçeneği",
      "login.alreadyHaveAccount": "Zaten bir hesabınız var mı?",
      "login.signIn": "Giriş Yap",
      agreementPrefix: "Hizmet şartlarını",
      termsOfService: "kabul ediyorum",
      and: "ve",
      agreementSuffix: "kabul ediyorum.",
      usernameIsRequired: "Kullanıcı adı gerekli",
      resetPasswordMessage:
        "Şifrenizi sıfırlayan bir bağlantı içeren bir e-posta alacaksınız.",
      resetPassword: "Şifreyi Sıfırla",
      priceNotFound: "Fiyat bulunamadı",
      passwordHasBeenChanged: "Şifre değiştirildi",
      newPassword: "Yeni Şifre",
      required: "Gerekli",
      invalidCodeTwoFactor: "Geçersiz kod. Lütfen yalnızca sayıları girin.",
      loginFailed: "Giriş başarısız",
      twoFactorPlease:
        "Lütfen giriş işlemini tamamlamak için mobil telefonunuza gönderilen doğrulama kodunu girin.",
      enterVerificationCode: "Doğrulama Kodunu Girin",
      sumbit: "Gönder",
      emailVerified: "E-posta doğrulandı",
      emailVerifiedDescription: "E-posta adresiniz doğrulandı.",
      error: "Hata",
      verificationCodeLengthError: "Doğrulama kodu 4 haneli olmalıdır.",
      verificationCodeRequired: "Doğrulama kodu gerekli.",
      verificationCodeInvalid: "Doğrulama kodu geçersiz.",
      verifyYourEmail: "E-postanızı Doğrulayın",
      verifyYourEmailDescription:
        "E-postanıza bir doğrulama kodu gönderildi. Tam cüzdan işlevselliğini korumak için lütfen doğrulayın.",
      verifyCodeEnter: "Doğrulama kodunu girin",
      resendCode: "Kodu yeniden gönder",
      contactUs: "Bize Ulaşın",
      community: "Topluluk",
      contract: "Sözleşme",
      "link.sss": "https://docs.polyverse.life/docs/PolyWallet/SSS/",
      "link.contactUs": "mailto:destek@polyverse.life",
      "link.x": "https://twitter.com/polywalletapptr",
    },
  },
};

const browserLang = navigator.language || navigator.userLanguage;
const defaultLang = browserLang.split("-")[0]; // Extract the base language, e.g., 'en' from 'en-US'

i18n
  .use(initReactI18next) //
  .init({
    resources,
    lng: defaultLang, // Varsayılan dil
    fallbackLng: "en",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
