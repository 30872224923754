import React from "react";
import { useNavigate } from "react-router-dom";

import { Box, Spinner, Flex } from "@chakra-ui/react";

const Index = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      navigate("/wallet");
    }
  }, [navigate]);

  return (
    <Flex height="100vh" alignItems="center" justifyContent="center">
      <Box>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="#472478"
          color="#753cc5"
          size="xl"
        />
      </Box>
    </Flex>
  );
};

export default Index;
