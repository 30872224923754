import React from "react";

import { useTranslation } from "react-i18next";

import { User } from "polyverse-sdk-dev/dist/api/user";
import { useGoogleLogin } from "@react-oauth/google";
import {
  useMediaQuery,
  Box,
  Flex,
  Image,
  Text,
  VStack,
  FormControl,
  Circle,
  Input,
  Button,
  useToast,
  Divider,
} from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";
import { useNavigate } from "react-router-dom";
import AuthBg from "./AuthBg";
import logo from "../../static/polywallet-logo.svg";
import footerText from "../../static/icons/footer-text.svg";
import { FcGoogle } from "react-icons/fc";
import { FaXTwitter } from "react-icons/fa6";

const Login = () => {
  const [isMobile] = useMediaQuery("(max-width: 480px)");
  const [isDesktop] = useMediaQuery("(min-width: 1025px)");

  const navigate = useNavigate("");
  const toast = useToast();
  const { t } = useTranslation();

  const handleLogin = async (values, actions) => {
    try {
      const addressType = checkAddressType(values.email);
      let loginResponse;

      if (addressType === "phone") {
        const phone = values.email.replace(/^\+/, "00");
        loginResponse = await User.login(
          2251,
          "wxcguoTzCmuuBdezpAVElaCeZd",
          "",
          values.password,
          phone
        );
      } else {
        loginResponse = await User.login(
          2251,
          "wxcguoTzCmuuBdezpAVElaCeZd",
          values.email,
          values.password
        );
      }

      loggedIn(
        loginResponse.token,
        loginResponse.address,
        values.email,
        loginResponse.id,
        loginResponse.phone,
        loginResponse.emailVerified,
        loginResponse.phoneVerified,
        loginResponse.isSmsAuthEnabled,
        loginResponse.name,
        loginResponse.kycVerified
      );

      if (loginResponse.isSmsAuthEnabled && loginResponse.token === null) {
        navigate("/twofactorauth");
      }
    } catch (error) {
      toast({
        title: "Login failed",
        description: error.message || "An error occurred while logging in.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    actions.setSubmitting(false);
  };

  const checkAddressType = (address) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // '+' işareti ile başlayabilir ve 10 ile 15 arasında rakam içerebilir
    const phoneRegex = /^\+?\d{10,15}$/;

    // İlk üç karakterin hepsi sayı mı veya '+' işareti ile mi başlıyor kontrol et
    const isPhone =
      address.length >= 3 &&
      (/^\d{3}/.test(address) || address.startsWith("+"));

    // İlk üç karakter sayıysa veya '+' ile başlıyorsa ve "@" içermiyorsa, telefon olarak kabul et
    if (isPhone && !address.includes("@")) {
      console.log("Telefon");
      return phoneRegex.test(address) ? "phone" : "invalid";
    }
    // Eğer "@" işareti içeriyorsa veya ilk üç karakter sayı değilse ve '+' ile başlamıyorsa, e-posta adresi olarak kabul et
    else if (address.includes("@") || (!isPhone && !address.startsWith("+"))) {
      return emailRegex.test(address) ? "email" : "invalid";
    }
    // Diğer durumlar için geçersiz olarak kabul et
    else {
      return "invalid";
    }
  };

  const validateForm = (values) => {
    const errors = {};

    const addressType = checkAddressType(values.email);

    // Konsola adres tipini yazdır
    console.log(`Address Type: ${addressType}`);

    // Eğer adres tipi email ise ve email boşsa
    if (addressType === "email" && !values.email) {
      errors.email = "Email is required";
    }
    // Eğer adres tipi email ise ve geçerli değilse
    else if (
      addressType === "email" &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }
    // Eğer adres tipi telefon ise ve geçerli değilse
    else if (addressType === "phone") {
      if (!values.email.startsWith("+")) {
        errors.email =
          "Phone number must start with a '+' and include the country code";
      } else if (!/^\+?\d{10,15}$/.test(values.email)) {
        errors.email = "Invalid phone number";
      }
    }
    // Eğer adres tipi geçerli değilse
    else if (addressType === "invalid") {
      errors.email = "Invalid email or phone number";
    }

    // Parola doğrulamaları
    if (!values.password) {
      errors.password = "Password is required";
    } else if (values.password.length < 8) {
      errors.password = "Password must be at least 8 characters long";
    } else if (values.password.length > 50) {
      errors.password = "Password must be no more than 50 characters long";
    }

    return errors;
  };

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      getUserInfo(tokenResponse.access_token); // Kullanıcı bilgilerini getir
    },
  });

  const getUserInfo = async (accessToken) => {
    try {
      const response = await fetch(
        "https://www.googleapis.com/oauth2/v2/userinfo",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = await response.json();

      try {
        const responseOAuth2 = await User.oAuth2Google(
          2251,
          "wxcguoTzCmuuBdezpAVElaCeZd",
          accessToken,
          data.email,
          data.name,
          data.id
        );

        console.log("responseOAuth2", responseOAuth2);

        loggedIn(
          responseOAuth2.token,
          responseOAuth2.address,
          responseOAuth2.email,
          responseOAuth2.id,
          responseOAuth2.emailVerified,
          responseOAuth2.phoneVerified,
          responseOAuth2.isSmsAuthEnabled,
          responseOAuth2.name,
          responseOAuth2.kycVerified
        );
      } catch (error) {
        console.log("Error:", error);
        toast({
          title: "Create Account Failed",
          description:
            error.message || "An error occurred while account creating.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("User info fetch failed", error);
    }
  };

  const loggedIn = (
    token,
    address,
    username,
    id,
    phone,
    emailVerified,
    phoneVerified,
    isSmsAuthEnabled,
    name,
    kycVerified
  ) => {
    localStorage.setItem("token", token);
    localStorage.setItem("userAddress", address);
    localStorage.setItem("username", username);
    localStorage.setItem("userid", id);
    localStorage.setItem("phone", phone);
    localStorage.setItem("emailVerified", emailVerified);
    localStorage.setItem("phoneVerified", phoneVerified);
    localStorage.setItem("isSmsAuthEnabled", isSmsAuthEnabled);
    localStorage.setItem("kycVerified", kycVerified);
    localStorage.setItem("name", name);

    //navigate to main page
    navigate("/");
  };

  const loginX = async () => {
    try {
      var response = await User.TwitterAuth(2251, "wxcguoTzCmuuBdezpAVElaCeZd");
      //forward to twitter login page with use response
      window.location.href = response;
    } catch (error) {
      console.log("Error:", error);
      toast({
        title: "Login Failed",
        description: error.message || "An error occurred while logging in.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const getStyleValue = (desktopValue, mobileValue, isTablet) =>
    isDesktop ? desktopValue : isMobile ? mobileValue : isTablet;

  const paddingX = getStyleValue("200px", "30px", "20px");
  const paddingY = getStyleValue("60px", "70px", "70px");
  const boxWidth = getStyleValue("65%", "90%", "80%");
  const boxHeight = getStyleValue("580px", "80%", "590px");

  const boxStyles = {
    position: "absolute",
    width: boxWidth,
    height: boxHeight,
    px: paddingX,
    py: paddingY,
    borderRadius: "50px",
    border: "3px solid #333",
    background: "#111111B2",
    zIndex: 1,
  };

  return (
    <Flex justifyContent="center" alignItems="center">
      <Box {...boxStyles}>
        <Image
          src={logo}
          position="absolute"
          top={{ base: "30px", md: "30px", lg: "30px" }}
          left={{ base: "30px", md: "40px", lg: "45px" }}
          width={{ base: "100px", md: "130px", lg: "163px" }}
        />
        <Flex justifyContent="center" alignItems="center" direction="column">
          <VStack align="center">
            <Text
              fontWeight="700"
              fontSize="2xl"
              width={{ base: "250px", md: "350px", lg: "393px" }}
            >
              {t("login.title")}
            </Text>
            <Text
              fontWeight="400"
              color="#888888"
              fontSize="sm"
              width={{ base: "250px", md: "350px", lg: "393px" }}
              mb={{ base: "10px", md: "10px", lg: "15px" }}
            >
              {t("login.description")}
            </Text>

            <Formik
              initialValues={{ email: "", password: "" }}
              validate={validateForm}
              onSubmit={handleLogin}
            >
              {(props) => (
                <Form>
                  <VStack alignItems="stretch">
                    <FormControl
                      id="email"
                      isInvalid={props.errors.email && props.touched.email}
                    >
                      <Field
                        as={Input}
                        name="email"
                        placeholder={t("email")}
                        background="white"
                        color="black"
                        width={{ base: "250px", md: "350px", lg: "393px" }}
                        height={{ base: "35px", md: "40px", lg: "50px" }}
                      />

                      <Box
                        height="2px"
                        mb={{ base: "10px", md: "15px", lg: "20px" }}
                      >
                        {props.errors.email && props.touched.email ? (
                          <Text fontSize="sm" color="red.500">
                            {props.errors.email}
                          </Text>
                        ) : null}
                      </Box>
                    </FormControl>

                    <FormControl
                      id="password"
                      isInvalid={
                        props.errors.password && props.touched.password
                      }
                    >
                      <Field
                        as={Input}
                        name="password"
                        type="password"
                        placeholder={t("password")}
                        maxLength="50"
                        background="white"
                        color="black"
                        width={{ base: "250px", md: "350px", lg: "390px" }}
                        height={{ base: "35px", md: "40px", lg: "50px" }}
                      />
                      <Box height="2px">
                        {props.errors.password && props.touched.password ? (
                          <Text fontSize="sm" color="red.500">
                            {props.errors.password}
                          </Text>
                        ) : null}
                      </Box>
                    </FormControl>
                    <Flex justifyContent="flex-end">
                      <Button
                        variant="link"
                        onClick={() => navigate("/ResetPassword")}
                        fontSize="sm"
                        fontWeight="400"
                        color="#FFF"
                        mb={{ base: "4px", md: "8px", lg: "13px" }}
                      >
                        {t("login.forgotPassword")}
                      </Button>
                    </Flex>
                    <Button
                      width={{ base: "250px", md: "350px", lg: "393px" }}
                      height={{ base: "35px", md: "40px", lg: "50px" }}
                      type="submit"
                      mb={2}
                      bg="#33333380"
                      color="#FFFFFF99"
                      _hover={{ color: "white", bg: "#333" }}
                      _active={{ color: "white", bg: "#222" }}
                    >
                      {t("login.login")}
                    </Button>

                    <Flex align="center" py="1" color="#888">
                      <Divider flex="1" borderColor="#888" />
                      <Text px="3" fontSize="sm" minWidth="fit-content">
                        {t("login.moreLoginOptions")}
                      </Text>
                      <Divider flex="1" borderColor="#888" />
                    </Flex>

                    <Flex
                      justifyContent="center"
                      align="center"
                      pb="1"
                      gap={{ base: "4", md: "5" }}
                    >
                      <Circle bg="#1E1E1E" p="2" border="1px solid #333">
                        <FaXTwitter
                          size="24"
                          onClick={() => loginX()}
                          cursor={"pointer"}
                        />
                      </Circle>
                      <Circle bg="#1E1E1E" p="2" border="1px solid #333">
                        <FcGoogle
                          size="24"
                          onClick={() => login()}
                          cursor={"pointer"}
                        />
                      </Circle>
                    </Flex>
                  </VStack>
                </Form>
              )}
            </Formik>

            <Flex alignItems="center" textAlign="center" justify="center">
              <Text fontWeight="400" fontSize="md">
                {t("login.areYouNewUser")}
              </Text>
              <Button
                variant="link"
                onClick={() => navigate("/SignUp")}
                fontSize="md"
                fontWeight="400"
                color="#753CC5"
                ml={{ base: "6px", md: "8px", lg: "10px" }}
              >
                {t("login.signUp")}
              </Button>
            </Flex>
          </VStack>
        </Flex>
        <Image
          src={footerText}
          bottom="30px"
          right="50px"
          position="absolute"
          width={{ base: "100px", md: "120px", lg: "150px" }}
          height={{ base: "auto", md: "auto", lg: "20px" }}
        />
      </Box>
      <AuthBg />
    </Flex>
  );
};

export default Login;
