import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import SumsubWebSdk from "@sumsub/websdk-react";
import { Sumsub } from "polyverse-sdk-dev/dist/api/sumsub";

function KycVerify() {
  const [accessToken, setAccessToken] = useState("");
  const userId = localStorage.getItem("userid");
  useEffect(() => {
    getAccessToken();
  }, []);

  const getAccessToken = async () => {
    try {
      console.log(userId);
      const accessTokenResponse = await Sumsub.getSumsubAccessToken(userId);
      console.log(accessTokenResponse);
      setAccessToken(accessTokenResponse.token);
    } catch (err) {
      console.error(err);
    }
  };

  // Yeni bir access token almak için API'ye istek yapacak fonksiyon
  const fetchNewAccessToken = async () => {
    try {
      const response = await Sumsub.getSumsubAccessToken(userId);
      return response.data;
    } catch (error) {
      console.error("Yeni token alınırken hata oluştu:", error);
      throw error;
    }
  };

  const accessTokenExpirationHandler = async () => {
    const newToken = await fetchNewAccessToken();
    return newToken;
  };

  const config = {};
  const options = {};

  const messageHandler = (message) => {
    // SDK'dan gelen mesajları işle
  };

  const errorHandler = (error) => {
    // SDK'dan gelen hataları işle
  };

  return (
    <Box>
      {accessToken && (
        <SumsubWebSdk
          accessToken={accessToken}
          expirationHandler={accessTokenExpirationHandler}
          config={config}
          options={options}
          onMessage={messageHandler}
          onError={errorHandler}
        />
      )}
    </Box>
  );
}

export default KycVerify;
