import React, { useState } from "react";

import * as data from "./data";

import {
  Box,
  Flex,
  HStack,
  Stack,
  Avatar,
  Text,
  Textarea,
  IconButton,
  useBreakpointValue,
} from "@chakra-ui/react";

import { PiArrowUpBold, PiBroom, PiLightbulbFilament } from "react-icons/pi";

// ChatActionButton Component regenerate
const ChatActionButton = (props) => {
  const { icon, ...rest } = props;
  const size = useBreakpointValue({ base: "md", md: "lg" });
  const iconSize = useBreakpointValue({ base: "16", md: "20" });

  return (
    <IconButton
      bg="#111"
      color="#888"
      border="2px solid #333"
      borderRadius="10px"
      size={size}
      icon={React.cloneElement(icon, { size: iconSize })}
      alignSelf="end"
      _hover={{ bg: "#222" }}
      {...rest}
    />
  );
};

// ChatMessage Component
const ChatMessage = (props) => {
  const { author, messages } = props;
  const stackDirection = useBreakpointValue({ base: "column", md: "row" });
  const gapSize = useBreakpointValue({ base: 2, md: 5 });

  return (
    <Flex direction={stackDirection} align="flex-start" gap={gapSize}>
      <Box pt="1">
        <Avatar size="sm" src={author.image} name={author.name} />
      </Box>
      <Stack spacing="1">
        <Text fontWeight="bold">{author.name}</Text>
        <Stack spacing={2}>
          {messages.map((message, index) => (
            <Box key={index} fontWeight="normal" color="#888" lineHeight="tall">
              {message}
            </Box>
          ))}
        </Stack>
      </Stack>
    </Flex>
  );
};

// ChatMessages Component
const ChatMessages = (props) => {
  return (
    <Stack
      maxW="full"
      mx="auto"
      px={{ base: 4, sm: 6, md: 8, lg: 10, xl: 12 }}
      spacing={{ base: 6, md: 8, lg: 10 }}
      {...props}
    />
  );
};

// ChatTextarea Component
const ChatTextarea = (props) => {
  const [message, setMessage] = useState("");
  const [rows, setRows] = useState(1);
  const lineHeight = useBreakpointValue({ base: 18, md: 20 });
  const maxRows = useBreakpointValue({ base: 3, md: 4 });

  const handleTextChange = (event) => {
    const textareaLineHeight = lineHeight;
    const previousRows = event.target.rows;
    event.target.rows = 1;

    const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

    if (currentRows !== previousRows) {
      setRows(currentRows);
    }
    if (currentRows >= maxRows) {
      event.target.rows = maxRows;
      event.target.style.overflowY = "auto";
    } else {
      event.target.style.overflowY = "hidden";
    }

    setMessage(event.target.value);
  };

  // Calculate the height of the Textarea based on the number of rows
  const textareaHeight = Math.min(rows * lineHeight, maxRows * lineHeight);

  return (
    <Textarea
      value={message}
      onChange={handleTextChange}
      name="message"
      placeholder="Ask me anything..."
      resize="none"
      rows={rows}
      minHeight={`${lineHeight * 2.5}px`}
      height={`${textareaHeight}px`}
      lineHeight={`${lineHeight}px`}
      overflowY={"hidden"}
      sx={{
        "&::-webkit-scrollbar": {
          display: "none", // Webkit tarayıcılarda scrollbar'ı kaldırır
        },
      }}
      {...props}
      _placeholder={{
        color: "#888",
        lineHeight: `${lineHeight}px`,
      }}
      px={4}
      py={4}
      border="2px solid #333"
      borderRadius="10px"
      bg="#111"
      _focus={{
        borderColor: "#222",
      }}
      _hover={{
        borderColor: "#444",
      }}
    />
  );
};

// PolyAI Component
const PolyAI = () => {
  return (
    <Flex
      direction="column"
      pos="relative"
      border="2px solid #333"
      borderRadius="10px"
      bg="#010101"
      overflow="hidden"
      height="100%"
    >
      <Box
        pos="absolute"
        top="0"
        insetX="0"
        bgGradient="linear(to-b, #010101 20%, rgba(0,0,0,0))"
        paddingY="4"
        zIndex="3"
      />
      <Box overflowY="auto" paddingTop="20" paddingBottom="40">
        <ChatMessages px={{ base: 4, md: 12, lg: 20, xl: 40 }}>
          {data.chats.map((chat, index) => (
            <ChatMessage
              key={index}
              author={data.users[chat.type]}
              messages={chat.messages}
            />
          ))}
        </ChatMessages>
      </Box>

      <Box
        pos="absolute"
        bottom="0"
        insetX="0"
        bgGradient="linear(to-t, #010101 80%, rgba(0,0,0,0))"
        paddingY="8"
        px={{ base: 4, md: 12, lg: 20, xl: 24 }}
      >
        <Stack direction="row">
          <ChatActionButton icon={<PiBroom size="20" />} />
          <Box as="form" pos="relative" w="full" mx="auto">
            <ChatTextarea
              focusBorderColor="#333"
              _hover={{ borderColor: "#444" }}
              bg="#111"
              border="2px solid #333"
              borderRadius="10px"
            />
            <Box
              bg="#1E1E1E"
              borderRadius="8px"
              pos="absolute"
              bottom="1.5"
              right="2"
              zIndex="2"
            >
              <IconButton
                width="38px"
                height="38px"
                minWidth="38px"
                minHeight="38px"
                type="submit"
                variant="text"
              >
                <PiArrowUpBold color="#888" size="18" />
              </IconButton>
            </Box>
          </Box>
          <ChatActionButton icon={<PiLightbulbFilament size="20" />} />
        </Stack>
      </Box>
    </Flex>
  );
};

export default PolyAI;
