import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  VStack,
  Image,
  Flex,
  Box,
  Divider,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Link,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";

import { shortenAddress } from "../../services/Helpers";

import { Wallet } from "polyverse-sdk-dev/dist/api/wallet";
import { User } from "polyverse-sdk-dev/dist/api/user";

import {
  PiArrowUpBold,
  PiArrowDownBold,
  PiCaretDownBold,
  PiCheckBold,
  PiCopySimpleBold,
} from "react-icons/pi";

function Activities() {
  const toast = useToast();
  const { t } = useTranslation();

  const [networks, setNetworks] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [transactions, setTransactions] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [processedActivity, setProcessedActivity] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const userAddress = localStorage.getItem("userAddress") || "";
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (selectedNetwork) {
      setIsLoading(true);
      fetchTransactions();
    }
  }, [selectedNetwork]);

  useEffect(() => {
    async function fetchNetworks() {
      try {
        const fetchedNetworks = await User.networks();
        let showTestNetValue = localStorage.getItem("showTestNet") === "true";
        const showTestNet =
          showTestNetValue === null ? false : showTestNetValue;
        const filteredNetworks = showTestNet
          ? fetchedNetworks
          : fetchedNetworks.filter((network) => !network.isTestNet);
        setNetworks(filteredNetworks);
      } catch (error) {
        console.error("Error fetching networks", error);
      }
    }
    fetchNetworks();
  }, []);

  const fetchTransactions = async () => {
    if (selectedNetwork) {
      const response = await Wallet.transactions(selectedNetwork.key);
      setTransactions(response);
      console.log(
        "Fetched Transactions for Network: ",
        selectedNetwork.key,
        response
      );
    } else {
      setTransactions(null);
    }
    setIsLoading(false);
  };

  const handleNetworkChange = (network) => {
    setSelectedNetwork(network);
    console.log("Network changed to: ", network);
  };

  const handleGridItemClick = (transaction) => {
    // Process transaction ve sonucu selectedActivity olarak ayarla
    const processedTransaction = processTransaction(transaction);

    setSelectedActivity(transaction);
    setProcessedActivity(processedTransaction);
    console.log("Processed Transaction: ", processedTransaction);
    console.log(processedTransaction.type);
    onOpen();
  };

  const formatDateForList = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", { month: "long", day: "numeric" });
  };

  // Ayrıntılar ekranı için tarih formatlama: Ay, gün, yıl ve saati göster
  const formatDateForDetails = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  const formatValue = (value, decimals = 18) => {
    const formattedValue = (value / Math.pow(10, decimals)).toFixed(2); // Adjust decimal places if needed
    // Eğer değer 0.01'den küçükse "<0.01" döndür, aksi takdirde normal formatlanmış değeri döndür
    return parseFloat(formattedValue) < 0.01 ? "<0.01" : formattedValue;
  };

  const shortenTickerSymbol = (symbol, maxLength = 8) => {
    if (symbol.length > maxLength) {
      return symbol.substring(0, maxLength) + "...";
    }
    return symbol;
  };
  const processTransaction = (transaction) => {
    if (!transaction.log_events) {
      if (transaction.decoded && transaction.decoded.params) {
        // Params'dan gerekli bilgileri al
        const fromParam = transaction.decoded.params.find(
          (p) => p.name === "from"
        );
        const toParam = transaction.decoded.params.find((p) => p.name === "to");
        const amountParam = transaction.decoded.params.find(
          (p) => p.name === "amount" || p.name === "value"
        );

        const isSend =
          fromParam &&
          fromParam.value.toLowerCase() === userAddress.toLowerCase();
        const transactionType = isSend ? "Send" : "Receive";
        const address = isSend ? toParam.value : fromParam.value;
        const value = amountParam
          ? formatValue(
              amountParam.value,
              transaction.sender_contract_decimals
            ) + ` ${transaction.sender_contract_ticker_symbol}`
          : "N/A";

        return { type: transactionType, address, value };
      } else {
        // Eğer decoded ve params yoksa, direkt işlemin kendisinden al
        const isSend =
          transaction.from_address.toLowerCase() === userAddress.toLowerCase();
        const transactionType = isSend ? "Send" : "Receive";
        const address = isSend
          ? transaction.to_address
          : transaction.from_address;
        const value =
          formatValue(transaction.value, 18) + ` ${selectedNetwork.symbol}`;

        return { type: transactionType, address, value };
      }
    }

    const transferEvent = transaction.log_events.find(
      (e) =>
        e.decoded &&
        (e.decoded.name === "Transfer" || e.decoded.name === "LogTransfer")
    );

    if (transferEvent) {
      const shortenedTickerSymbol = shortenTickerSymbol(
        transferEvent.sender_contract_ticker_symbol
      );
      const tokenIdParam = transferEvent.decoded.params.find(
        (p) => p.name === "tokenId"
      );
      const valueParam = transferEvent.decoded.params.find(
        (p) => p.name === "value" || p.name === "amount"
      );
      const fromParam = transferEvent.decoded.params.find(
        (p) => p.name === "from"
      );
      const toParam = transferEvent.decoded.params.find((p) => p.name === "to");

      if (
        fromParam &&
        fromParam.value === "0x0000000000000000000000000000000000000000"
      ) {
        let value = "N/A";
        if (tokenIdParam) {
          value = `${shortenedTickerSymbol} #${tokenIdParam.value}`;
        } else if (
          valueParam &&
          transferEvent.sender_contract_decimals !== undefined
        ) {
          value =
            formatValue(
              valueParam.value,
              transferEvent.sender_contract_decimals
            ) + ` ${shortenedTickerSymbol}`;
        }

        return {
          type: "Mint",
          address: "Minted",
          value: value,
        };
      }

      const isSend =
        fromParam &&
        fromParam.value.toLowerCase() === userAddress.toLowerCase();
      const transactionType = isSend ? "Send" : "Receive";
      const address = isSend ? toParam.value : fromParam.value;

      let value = "N/A";
      if (tokenIdParam) {
        value = `${shortenedTickerSymbol} #${tokenIdParam.value}`;
      } else if (
        valueParam &&
        transferEvent.sender_contract_decimals !== undefined
      ) {
        value =
          formatValue(
            valueParam.value,
            transferEvent.sender_contract_decimals
          ) + ` ${shortenedTickerSymbol}`;
      }

      return { type: transactionType, address, value: value || "N/A" };
    }

    return { type: "Unknown", address: "N/A", value: "N/A" };
  };

  const renderTransactions = () => {
    if (isLoading) {
      return <Spinner align="center" justifyContent="center" mt="10" />;
    }

    if (
      transactions &&
      Array.isArray(transactions.items) &&
      transactions.items.length > 0
    ) {
      return transactions.items.map((item, index) => {
        const { type, address, value } = processTransaction(item);

        let icon;
        switch (type) {
          case "Send":
            icon = <PiArrowUpBold />;
            break;
          case "Receive":
            icon = <PiArrowDownBold />;
            break;
          case "Mint":
            icon = <PiArrowDownBold />;
            break;
          default:
            icon = null;
        }

        return (
          <Flex
            key={index}
            onClick={() => handleGridItemClick(item, index)}
            cursor="pointer"
            _hover={{ bg: "#222" }}
            w="full"
            px={4}
            py={5}
            align="center"
            justify="space-between"
          >
            <Box flex="0 0 40px">{icon}</Box>
            <Box flex="3" minW="0" overflow="hidden" mr={2}>
              {" "}
              {/* Tip ve Tarih için azaltılmış flex değeri */}
              <Text fontSize={["md", "md", "lg"]} isTruncated>
                {type}
              </Text>
              <Text
                fontWeight="medium"
                fontSize={["xs", "sm", "sm"]}
                color="#5263F0"
                isTruncated
              >
                {formatDateForList(item.block_signed_at)}
              </Text>
            </Box>
            <Box flex="4" minW="0" overflow="hidden">
              {" "}
              {/* Sağdan negatif margin ekleyerek sola kaydır */}
              <Text
                fontSize={["xs", "sm", "sm"]}
                fontWeight="medium"
                textAlign="left" // Metni kutunun sol tarafına hizala
                isTruncated
              >
                {type === "Send"
                  ? `To: ${shortenAddress(address)}`
                  : `From: ${shortenAddress(address)}`}
              </Text>
              <Text
                fontSize={["xs", "sm", "sm"]}
                fontWeight="medium"
                textAlign="left" // Metni kutunun sol tarafına hizala
                isTruncated
                color="#888"
              >
                Txn: {shortenAddress(item.tx_hash)}
              </Text>
            </Box>

            <Box flex="3" minW="0" textAlign="right" mr={2}>
              {" "}
              {/* Değer için daha fazla yer */}
              <Text
                fontWeight="300"
                fontSize={["12px", "13px", "16px"]}
                isTruncated
              >
                {value}
              </Text>
            </Box>
          </Flex>
        );
      });
    } else {
      return (
        <Text my="4">
          {selectedNetwork ? t("noTransactionsFound") : t("selectNetwork")}
        </Text>
      );
    }
  };

  const DetailSection = ({ title, value, hasCopy = false, isLink, link }) => (
    <Box mb={4}>
      <Text fontWeight="bold">{title}:</Text>
      <Flex justify="space-between" align="center">
        {isLink && link ? (
          <Link href={link} isExternal>
            <Text fontSize="sm" color="#5263F0" isTruncated>
              {value}
            </Text>
          </Link>
        ) : (
          <Text
            fontWeight="normal"
            color="#888"
            fontSize={["13px", "13px", "15px"]}
            isTruncated
          >
            {value}
          </Text>
        )}
        {hasCopy && (
          <PiCopySimpleBold
            color="#888"
            ml="4"
            cursor="pointer"
            onClick={() => copyToClipboard(value)}
          />
        )}
      </Flex>
    </Box>
  );

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        toast({
          title: "Copied!",
          description: "Copied to clipboard.",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      },
      (err) => {
        toast({
          title: "Failed",
          description: "Failed to copy text to clipboard.",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    );
  };

  return (
    <>
      <Flex h="full" px={{ base: "4", sm: "6", md: "8", lg: "8" }}>
        <Menu matchWidth>
          <MenuButton
            w="full"
            h="9"
            as={Button}
            variant="outline"
            color="white"
            fontSize={["sm", "md", "md"]}
            border="2px solid #333"
            borderRadius="lg"
            bg="#111"
            _hover={{ bg: "#1E1E1E" }}
            _active={{ bg: "#111111" }}
          >
            <Flex justifyContent="space-between" align="center">
              <Flex align="center">
                {selectedNetwork ? (
                  <Image
                    src={`data:image/svg+xml;base64,${selectedNetwork.icon}`}
                    boxSize="18px"
                    mr="4"
                  />
                ) : (
                  ""
                )}
                <Box
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {selectedNetwork ? selectedNetwork.name : t("network")}
                </Box>
              </Flex>
              <PiCaretDownBold boxSize="3" />
            </Flex>
          </MenuButton>
          <MenuList
            px="2"
            bg="#111"
            border="2px solid #333"
            w="full"
            mt="2"
            maxH="220px"
            overflowY="auto"
          >
            {networks.map((network) => (
              <MenuItem
                my="1"
                key={network.name}
                borderRadius="lg"
                _hover={{ bg: "#1E1E1E" }}
                onClick={() => handleNetworkChange(network)}
                bg={selectedNetwork?.name === network.name ? "#1E1E1E" : "#111"}
              >
                <Flex align="center">
                  <Box w="4" mr="4">
                    {selectedNetwork?.name === network.name && (
                      <PiCheckBold boxSize="4" />
                    )}
                  </Box>
                  <Image
                    src={`data:image/svg+xml;base64,${network.icon}`}
                    boxSize="18px"
                    mr="2"
                  />
                  <Box
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {network.name}
                  </Box>
                </Flex>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Flex>
      <Divider border="1px solid #2B2B2B" mt={["4", "4", "5"]} />

      <VStack divider={<Divider borderColor="#2B2B2B" />} spacing={0}>
        {renderTransactions()}
      </VStack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent w="84%" bg="#111">
          <ModalHeader pt="6" align="center">
            Details
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody px={[5, 6, 8]} borderRadius="10px">
            {selectedActivity ? (
              <>
                <Flex justifyContent="space-between" mb="4">
                  <Text fontWeight="bold" fontSize={["md", "md", "lg"]}>
                    {selectedActivity
                      ? processedActivity.type
                      : "No Transaction Selected"}
                  </Text>
                  <Text
                    fontWeight="medium"
                    color="#888"
                    fontSize={["13px", "13px", "15px"]}
                  >
                    {selectedActivity
                      ? formatDateForDetails(selectedActivity.block_signed_at)
                      : "Loading..."}
                  </Text>
                </Flex>
                <Text fontWeight="medium" mb="6" fontSize={["lg", "lg", "xl"]}>
                  {processedActivity.type} {processedActivity.value}{" "}
                </Text>
                <DetailSection
                  title="Transaction Status"
                  value="View on block explorer"
                  isLink={true}
                  link={`${selectedNetwork.explorerUrl}tx/${selectedActivity.tx_hash}`}
                  hasCopy={false}
                />
                <DetailSection title="Network" value={selectedNetwork.name} />
                <DetailSection
                  title={
                    selectedActivity.from_address === userAddress
                      ? `To`
                      : `From`
                  }
                  value={
                    selectedActivity.from_address === userAddress
                      ? selectedActivity.to_address
                      : selectedActivity.from_address
                  }
                  hasCopy
                />
                <DetailSection
                  title="Txn Hash"
                  value={selectedActivity.tx_hash}
                  hasCopy
                />
              </>
            ) : (
              <Text>No activity selected.</Text>
            )}
          </ModalBody>
          <ModalFooter
            mt={["6", "10", "12"]}
            mb={["2", "3", "4"]}
            color="#42CF78"
            justifyContent="center"
            fontSize={["md", "md", "lg"]}
          >
            <Flex alignItems="center" justifyContent="center">
              <Box
                p="0.5"
                bg="#42CF78"
                borderRadius="6px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                mr="2"
              >
                <PiCheckBold boxSize="20px" color="white" />
              </Box>
              <Text>
                {selectedActivity?.successful === true
                  ? "Status Successful"
                  : selectedActivity?.successful === false
                  ? "Status Failed"
                  : "Status Unknown"}
              </Text>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Activities;
