import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  useMediaQuery,
  Box,
  Flex,
  Image,
  Progress,
  VStack,
  Text,
  Button,
  useToast,
  Spinner,
  AspectRatio,
  Badge,
  Divider,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { Contract } from "polyverse-sdk-dev/dist/api/contract";
import { System } from "polyverse-sdk-dev/dist/api/system";
function NftMint() {
  const queryParams = new URLSearchParams(window.location.search);
  const network = queryParams.get("network");
  const contract = queryParams.get("contract");

  const [isMobile] = useMediaQuery("(max-width: 480px)");
  const [canMint, setCanMint] = useState(false);
  const [dataStats, setDataStats] = useState({
    maxSupply: 0,
    totalSupply: 0,
    mintedSupply: 0,
    unmintedSupply: 0,
    name: "",
  });
  const [imgPreview, setImgPreview] = useState("");
  const [mintPrice, setMintPrice] = useState("");
  const [mintPriceEther, setMintPriceEther] = useState("");
  const address = localStorage.getItem("userAddress");
  const [isLoading, setIsLoading] = useState(false);
  const [canNotMessage, setCanNotMessage] = useState("");
  const [networkSymbol, setNetworkSymbol] = useState("");
  const [mintPhases, setMintPhases] = useState([]);
  const [currentPhase, setCurrentPhase] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const toast = useToast();

  useEffect(() => {
    const fetchNetworks = async () => {
      try {
        const networks = await System.networks();
        const queryParams = new URLSearchParams(window.location.search);
        const currentNetworkKey = queryParams.get("network");

        networks.forEach((network) => {
          if (network.key === currentNetworkKey) {
            setNetworkSymbol(network.symbol);
          }
        });
      } catch (error) {
        console.error("Networks fetching failed:", error);
      }
    };

    fetchNetworks();
  }, []);

  useEffect(() => {
    getAllMintPhases();
    getMintPrices();
    getStats();
    checkCanMint();
    getImage();
  }, [refreshData]);

  const getAllMintPhases = async () => {
    try {
      const allMintPhases = await Contract.execute(
        network,
        contract,
        "getMintPhases",
        undefined,
        "raw"
      );
      const now = Math.floor(Date.now() / 1000);
      const activePhase = allMintPhases.data.find(
        (phase) => now >= phase[0] && now <= phase[1]
      );

      setCurrentPhase(activePhase);
      setMintPhases(allMintPhases.data);
    } catch (err) {
      console.log(err);
    }
  };
  const getCurrentPhaseName = (phase) => {
    const phaseTypes = ["Public Phase", "Allowlist", "Owner Only"];
    return phase ? phaseTypes[phase[4]] : "No Active Phase";
  };
  const getNextPhase = (phases) => {
    const now = Math.floor(Date.now() / 1000);
    return phases.reduce((nextPhase, phase) => {
      const startsInFuture = phase[0] > now;
      if (startsInFuture && (!nextPhase || phase[0] < nextPhase[0])) {
        return phase;
      }
      return nextPhase;
    }, null);
  };

  const getImage = async () => {
    try {
      const resultURI = await Contract.execute(
        network,
        contract,
        "tokenURI",
        { tokenId: "0" },
        "raw"
      );
      if (resultURI.data) {
        const ipfsUrl = `https://api.polyverse.life/storage/IPFS/${resultURI.data.replace(
          "ipfs://",
          ""
        )}`;
        const resultIPFS = await axios.get(ipfsUrl);
        setImgPreview(
          `https://api.polyverse.life/nft/image?url=${resultIPFS.data.image}&width=600`
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getStats = async () => {
    try {
      const result1 = await Contract.execute(
        network,
        contract,
        "totalLazyMintedTokens"
      );
      const result2 = await Contract.execute(
        network,
        contract,
        "totalSupply",
        undefined,
        "raw"
      );
      const result3 = await Contract.execute(
        network,
        contract,
        "getMaxSupply",
        undefined,
        "raw"
      );
      const name = await Contract.execute(
        network,
        contract,
        "name",
        undefined,
        "raw"
      );

      setDataStats({
        maxSupply: result3.data,
        totalSupply: result1.data,
        mintedSupply: result2.data,
        unmintedSupply: parseInt(result1.data) - parseInt(result2.data),
        name: name.data,
      });
    } catch (err) {
      console.error(err.message);
    }
  };

  const weiToEther = (weiValue) => {
    return weiValue / 1e18; // 1e18, 10^18 anlamına gelir
  };

  const getMintPrices = async () => {
    try {
      const mintPriceResult = await Contract.execute(
        network,
        contract,
        "getMintPrice",
        undefined,
        "raw"
      );
      const mintPriceInEth = weiToEther(mintPriceResult.data);
      setMintPrice(mintPriceResult.data);
      setMintPriceEther(mintPriceInEth.toFixed(2)); // 2 ondalık basamak ile yuvarlayın
    } catch (err) {
      console.error(err.message);
    }
  };

  const checkCanMint = async () => {
    try {
      const canMintResult = await Contract.execute(
        network,
        contract,
        "checkCanMint",
        { recipient: address },
        "raw"
      );
      setCanMint(canMintResult.data === true);
    } catch (err) {
      setCanMint(false);
      setCanNotMessage(err.message.replace("Smart contract error:", ""));
      console.error(err.message);
    }
  };

  const handleMint = async () => {
    setIsLoading(true);
    try {
      const mintResult = await Contract.execute(
        network,
        contract,
        "mint",
        {
          recipient: address,
          value: mintPrice,
        },
        "raw"
      );
      console.log("minted", mintResult);
      toast({
        title: "Minted 🥳",
        description: "Minted successfully!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setRefreshData(!refreshData); // Verileri yenilemek için refreshData'yı değiştir
    } catch (err) {
      console.error(err);
      toast({
        title: "An error occurred",
        description: "Unable to complete the minting process.\n" + err.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const PhaseBox = ({
    startTime,
    endTime,
    maxPerWallet,
    totalAmount,
    phaseType,
  }) => {
    const phaseTypes = ["Public Phase", "Allowlist", "Owner Only"];
    const now = Math.floor(Date.now() / 1000); // Current UNIX timestamp
    const hasEnded = now > endTime; // Check if the end time is in the past
    const hasNotStarted = now < startTime;
    const formattedStartTime = format(
      new Date(startTime * 1000),
      "MMMM d, yyyy HH:mm"
    );

    return (
      <Box
        p={5}
        w={{ base: "300px", md: "300px", lg: "450px" }}
        bg="#111"
        border="1px solid #333"
        borderRadius="10px"
        mb={1}
      >
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <Text fontSize="16px" fontWeight="bold">
            {phaseTypes[phaseType] || "Unknown Phase"}
          </Text>
          <Badge colorScheme={hasEnded ? "red" : "green"}>
            {hasEnded
              ? "ENDED"
              : hasNotStarted
              ? `Starts: ${formattedStartTime}`
              : "Active"}
          </Badge>
        </Flex>
        <Divider borderColor="whiteAlpha.400" mb={2} marginTop={-3} />{" "}
        <Flex justifyContent="space-between" alignItems="center">
          <Box>
            <Text fontSize="sm" color="#5263F0">
              Price
            </Text>
            <Text fontSize="16px">
              {mintPriceEther} {networkSymbol}
            </Text>
          </Box>
          <Box>
            <Text fontSize="sm" color="#5263F0">
              Items
            </Text>
            <Text fontSize="16px">{totalAmount}</Text>
          </Box>
          <Box>
            <Text fontSize="sm" color="#5263F0">
              Max
            </Text>
            <Text fontSize="16px"> {maxPerWallet}</Text>
          </Box>
        </Flex>
      </Box>
    );
  };

  const MintPage = () => {
    // Mevcut fazın adını ve limitini al
    const currentPhaseName = getCurrentPhaseName(currentPhase);

    // Bir sonraki fazı al
    const nextPhase = getNextPhase(mintPhases);
    const nextPhaseStarts = nextPhase
      ? format(new Date(nextPhase[0] * 1000), "MMMM d 'at' HH:mm")
      : null;

    // Eğer mevcut faz yoksa ve bir sonraki faz varsa, bir sonraki fazın limitini al
    const displayMaxPerWallet = currentPhase
      ? currentPhase[2]
      : nextPhase
      ? nextPhase[2]
      : "N/A";

    let buttonText = "No Active Phase";
    let buttonDisabled = true;

    if (currentPhase) {
      buttonText = canMint ? "Mint" : "Cannot Mint";
      buttonDisabled = !canMint || isLoading;
    }

    return (
      <Flex justifyContent="center" alignItems="center" mt={3} w="full">
        <Box
          p={5}
          maxWidth={{ base: "full", md: "600px" }}
          bg="#111"
          border="1px solid #333"
          borderRadius="10px"
          w="full"
        >
          {currentPhase ? (
            <>
              <Text fontWeight="400" fontSize="md" color="#888">
                Limit {displayMaxPerWallet} per wallet
              </Text>
              <Text fontWeight="700" fontSize="lg">
                {currentPhaseName}
              </Text>
            </>
          ) : nextPhase ? (
            <>
              <Text fontWeight="400" fontSize="md" color="#888">
                Mint starts {nextPhaseStarts}
              </Text>
              <Text fontWeight="400" fontSize="md" color="#888">
                Limit {displayMaxPerWallet} per wallet
              </Text>
            </>
          ) : (
            <Badge colorScheme="red">ENDED</Badge>
          )}
          <Text fontWeight="700" fontSize="lg">
            {mintPriceEther} {networkSymbol}
          </Text>
          <Flex justifyContent="center" w="full" mt={4}>
            <Button
              bg="#111"
              color="#FFF"
              border="1px solid #333"
              width={{ base: "full", md: "368px" }}
              height="45px"
              _hover={{
                color: "white",
                bg: canMint && !isLoading ? "#111" : "#333",
              }}
              _active={{
                color: "white",
                bg: canMint && !isLoading ? "#222" : "#333",
              }}
              cursor={canMint && !isLoading ? "pointer" : "not-allowed"}
              onClick={canMint && !isLoading ? handleMint : undefined}
              disabled={buttonDisabled}
            >
              {isLoading ? <Spinner size="sm" color="white" /> : buttonText}
            </Button>
          </Flex>
        </Box>
      </Flex>
    );
  };

  const boxStyles = {
    bg: "#888",
    width: "full",
    height: "full",
    minHeight: "750px",
    overflowY: "auto",
    borderRadius: "8px",
    border: "2px solid #333333",
    background: "#010101",
    zIndex: 1,
  };
  return (
    <Flex>
      <Box {...boxStyles} p={5}>
        <VStack spacing={8}>
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(6, 1fr)" }}
            gap={4}
            w="full"
          >
            <GridItem colSpan={{ base: 1, md: 2 }}>
              <AspectRatio ratio={1} w="100%">
                <Image
                  position="relative"
                  objectFit="cover"
                  bg="blue"
                  src={imgPreview}
                  alt="NFT Preview"
                />
              </AspectRatio>
            </GridItem>

            <GridItem colSpan={{ base: 1, md: 4 }}>
              <VStack align="start" spacing={4}>
                <Text fontSize={"20px"}> {dataStats.name}</Text>

                <Flex justifyContent="space-between" w="full">
                  <Text fontWeight="normal" color="FFF">
                    {`${Math.round(
                      (dataStats.mintedSupply / dataStats.maxSupply) * 100
                    )}% minted`}
                  </Text>
                  <Text fontWeight="normal" color="#888">
                    {`${dataStats.mintedSupply}/${dataStats.maxSupply}`}
                  </Text>
                </Flex>

                <Progress
                  borderRadius="15px"
                  border="1px solid #333"
                  colorScheme="purple"
                  height="25px"
                  width="full"
                  value={(dataStats.mintedSupply / dataStats.maxSupply) * 100}
                />

                <MintPage isMobile={isMobile} />
              </VStack>
            </GridItem>
          </Grid>
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
            gap={4}
            w="full"
          >
            {mintPhases.map((phase, index) => (
              <GridItem colSpan={{ base: 1, md: 1 }} key={index}>
                <PhaseBox
                  startTime={phase[0]}
                  endTime={phase[1]}
                  maxPerWallet={phase[2]}
                  totalAmount={phase[3]}
                  phaseType={phase[4]}
                />
              </GridItem>
            ))}
          </Grid>
        </VStack>
      </Box>
    </Flex>
  );
}

export default NftMint;
