import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Flex,
  Button,
  IconButton,
  Icon,
  useBreakpointValue,
} from "@chakra-ui/react";

import { Wallet } from "polyverse-sdk-dev/dist/api/wallet";
import { User } from "polyverse-sdk-dev/dist/api/user";

import PortfolioAssets from "./PortfolioAssets";
import PortfolioBalance from "./PortfolioBalance";

import {
  PiArrowDownBold,
  PiArrowUpBold,
  PiArrowsLeftRightBold,
  PiPlusBold,
} from "react-icons/pi";

function Portfolio() {
  const isLgScreen = useBreakpointValue({ base: false, md: true, lg: true });

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [allTokens, setAllTokens] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [networks, setNetworks] = useState([]);

  const currency = localStorage.getItem("userCurrency") || "usd";

  useEffect(() => {
    async function fetchNetworks() {
      try {
        const fetchedNetworks = await User.networks();
        let showTestNetValue = localStorage.getItem("showTestNet");
        const showTestNet =
          showTestNetValue === null ? false : showTestNetValue === "true";
        const filteredNetworks = showTestNet
          ? fetchedNetworks
          : fetchedNetworks.filter((network) => !network.isTestNet);
        setNetworks(filteredNetworks);
      } catch (error) {
        console.error("Error fetching networks", error);
      }
    }
    fetchNetworks();
  }, []);

  useEffect(() => {
    async function fetchNetworksAndBalances() {
      setIsLoading(true);
      let tempAllTokens = [];

      // Bir Promise dizisi oluştur
      const balancePromises = networks.map(async (network) => {
        try {
          const balanceData = await Wallet.balance(
            network.key,
            currency,
            true,
            true,
            false
          );
          return [
            ...(balanceData.balance && typeof balanceData.balance === "object"
              ? [
                  {
                    ...balanceData.balance,
                    networkIcon: network.icon,
                    isMainBalance: true,
                    isTestNet: network.isTestNet,
                    networkName: network.name,
                  },
                ]
              : []),

            ...(balanceData.tokens && Array.isArray(balanceData.tokens)
              ? balanceData.tokens.map((token) => ({
                  ...token,
                  isMainBalance: false,
                  networkIcon: network.icon,
                  networkName: network.name,
                  isTestNet: network.isTestNet,
                }))
              : []),
          ];
        } catch (error) {
          console.error(
            "Error fetching balance for network:",
            network.name,
            error
          );
          return [];
        }
      });

      try {
        const allResults = await Promise.all(balancePromises);
        tempAllTokens = allResults.flat();

        setAllTokens(tempAllTokens);
      } catch (error) {
        console.error("Error fetching all balances", error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchNetworksAndBalances();
  }, [currency, networks]);

  const buttons = [
    {
      label: t("send"),
      icon: PiArrowUpBold,
      onClick: () => handleTabChange("send"),
    },
    {
      label: t("receive"),
      icon: PiArrowDownBold,
      onClick: () => handleTabChange("receive"),
    },
    {
      label: t("swap"),
      icon: PiArrowsLeftRightBold,
      onClick: () => navigate("/swap"),
    },
    { label: t("buy"), icon: PiPlusBold, onClick: () => navigate("/buy") },
  ];

  const handleTabChange = (tabName) => {
    navigate(`/transfer?tab=${tabName}`);
  };

  return (
    <>
      <Flex
        justifyContent="center"
        alignItems="center"
        gap={["4", "4", "6", "16"]}
        mb="4"
      >
        {buttons.map((button, index) =>
          isLgScreen ? (
            <Button
              fontSize={{ md: "sm", lg: "md" }}
              bg="#111"
              border="1px solid #333"
              key={index}
              variant="pvGray"
              onClick={button.onClick}
              _hover={{ bg: "#333" }}
              _active={{ bg: "#222" }}
              leftIcon={<Icon as={button.icon} color="white" />}
            >
              {button.label}
            </Button>
          ) : (
            <IconButton
              key={index}
              border="2px solid #333"
              p="3"
              icon={<Icon as={button.icon} color="white" />}
              variant="ghost"
              _hover={{ bg: "#333" }}
              _active={{ bg: "#222" }}
              onClick={button.onClick}
              aria-label={button.label}
            />
          )
        )}
      </Flex>
      <PortfolioBalance />
      <PortfolioAssets
        allTokens={allTokens}
        isLoading={isLoading}
        networks={networks}
      />
    </>
  );
}

export default Portfolio;
