import React from "react";

import { Box, IconButton, Image, Flex, useMediaQuery } from "@chakra-ui/react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import image1 from "../../static/1.png";
import image2 from "../../static/2.png";
import image3 from "../../static/3.png";
import image4 from "../../static/4.png";

import Assets from "./Assets";

import { PiCaretLeftBold, PiCaretRightBold } from "react-icons/pi";

function Wallet() {
  const images = [image1, image2, image3, image4];
  const [isMobile] = useMediaQuery("(max-width: 480px)");

  const settings = {
    dots: false,
    infinite: true,
    speed: 1100,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2800,
    arrows: isMobile ? false : true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Flex justifyContent="center" width="full">
      <Box width="full" p="0px">
        <Slider {...settings} className="custom-slider">
          {images.map((src, index) => (
            <Box key={index} px={["0px", "5px", "12px", "20px"]}>
              <Image
                src={src}
                alt={`Image ${index + 1}`}
                objectFit="cover"
                width="100%" /* Set width to 100% */
                height="auto"
                borderRadius="9px"
              />
            </Box>
          ))}
        </Slider>
        <Assets />
      </Box>
    </Flex>
  );
}

function NextArrow(props) {
  const { onClick } = props;
  return (
    <IconButton
      borderRadius="4"
      height="45px"
      bg="#010101"
      aria-label="Next"
      p="0"
      icon={<PiCaretRightBold color="white" />}
      onClick={onClick}
      size="xs"
      position="absolute"
      right="2"
      top="50%"
      transform="translateY(-50%)"
      zIndex="2"
      _hover={{ bg: "#1A1A1A" }}
    />
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <IconButton
      borderRadius="4"
      height="45px"
      bg="#010101"
      width="20px"
      size="xs"
      aria-label="Previous"
      icon={<PiCaretLeftBold color="white" p="0" />}
      onClick={onClick}
      position="absolute"
      left="2"
      top="50%"
      transform="translateY(-50%)"
      zIndex="2"
      _hover={{ bg: "#1A1A1A" }}
    />
  );
}

export default Wallet;
