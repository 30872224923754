import React, { useState } from "react";

import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { User } from "polyverse-sdk-dev/dist/api/user";

import {
  useMediaQuery,
  Box,
  Flex,
  Image,
  Text,
  VStack,
  FormControl,
  Input,
  Button,
  useToast,
} from "@chakra-ui/react";

import { Formik, Field, Form } from "formik";
import AuthBg from "./AuthBg";
import logo from "../../static/polywallet-logo.svg";
import footerText from "../../static/icons/footer-text.svg";

const ResetPasswordComplete = () => {
  const toast = useToast();
  const location = useLocation();
  const { t } = useTranslation();

  const [isMobile] = useMediaQuery("(max-width: 480px)");
  const [isDesktop] = useMediaQuery("(min-width: 1025px)");

  const [showMessage, setShowMessage] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const resetKey = queryParams.get("resetKey");
  const projectId = queryParams.get("projectId");

  const handleResetPasswordCompleteEmail = async (values, actions) => {
    if (values.password.length < 8 || values.password.length > 50) {
      toast({
        title: "Error",
        description: "Password must be between 8 and 50 characters long.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      actions.setSubmitting(false);
      return;
    }

    try {
      const response = await User.resetPasswordCompleteEmail(
        projectId,
        email,
        resetKey,
        values.password
      );
      setShowMessage(true);
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    actions.setSubmitting(false);
  };

  const validateForm = (values) => {
    const errors = {};
    if (!values.password) {
      errors.password = t("login.passwordRequired");
    } else if (values.password.length < 8) {
      errors.password = t("login.passwordLeast");
    } else if (values.password.length > 50) {
      errors.password = t("login.passwordExceed");
    }
    return errors;
  };

  const getStyleValue = (desktopValue, mobileValue, isTablet) =>
    isDesktop ? desktopValue : isMobile ? mobileValue : isTablet;

  const paddingX = getStyleValue("200px", "30px", "20px");
  const paddingY = getStyleValue("140px", "160px", "140px");
  const boxWidth = getStyleValue("65%", "90%", "80%");
  const boxHeight = getStyleValue("580px", "80%", "590px");

  const boxStyles = {
    position: "absolute",
    width: boxWidth,
    height: boxHeight,
    px: paddingX,
    py: paddingY,
    borderRadius: "50px",
    border: "3px solid #333",
    background: "#111111B2",
    zIndex: 1,
  };

  return (
    <Flex justifyContent="center" alignItems="center" position="relative">
      <Box {...boxStyles}>
        <Image
          src={logo}
          position="absolute"
          top={{ base: "30px", md: "30px", lg: "30px" }}
          left={{ base: "30px", md: "40px", lg: "45px" }}
          width={{ base: "100px", md: "130px", lg: "163px" }}
        />

        <Flex justifyContent="center" alignItems="center" direction="column">
          <VStack align="center" spacing="8">
            <Text
              direction="column"
              textAlign="center"
              fontWeight="700"
              fontSize={{ base: "20px", md: "25px", lg: "30px" }}
              width={{ base: "250px", md: "350px", lg: "393px" }}
              //mb="50px"
            >
              {t("resetPassword")}
            </Text>

            <Flex
              // mt={{ base: "3px", md: "5px", lg: "8px" }}
              direction="column"
              alignItems="center"
              textAlign="stretch"
            >
              <Formik
                initialValues={{ password: "" }}
                validate={validateForm}
                onSubmit={handleResetPasswordCompleteEmail}
              >
                {(props) => (
                  <Form>
                    <VStack alignItems="stretch" spacing="50px">
                      {!showMessage && (
                        <>
                          {" "}
                          <FormControl
                            id="password"
                            isInvalid={
                              props.errors.password && props.touched.password
                            }
                          >
                            <Field
                              as={Input}
                              name="password"
                              type="password"
                              placeholder={t("newPassword")}
                              maxLength="50"
                              minLength="8"
                              background="white"
                              color="black"
                              width={{
                                base: "250px",
                                md: "350px",
                                lg: "393px",
                              }}
                              height={{ base: "35px", md: "40px", lg: "50px" }}
                            />

                            <Box height="2px">
                              {props.errors.password &&
                              props.touched.password ? (
                                <Text
                                  fontSize={{
                                    base: "8px",
                                    md: "10px",
                                    lg: "12px",
                                  }}
                                  color="red.500"
                                >
                                  {props.errors.password}
                                </Text>
                              ) : null}
                            </Box>
                          </FormControl>
                          <Button
                            width={{ base: "250px", md: "350px", lg: "393px" }}
                            height={{ base: "35px", md: "40px", lg: "50px" }}
                            type="submit"
                            bg="rgba(51, 51, 51, 0.50)"
                            color="#FFFFFF"
                            opacity="60%"
                            isLoading={props.isSubmitting}
                          >
                            {t("change")}
                          </Button>
                        </>
                      )}

                      {showMessage && (
                        <Text
                          color="white"
                          textAlign="center"
                          fontSize="md"
                          width={{ base: "250px", md: "350px", lg: "400px" }}
                        >
                          {t("passwordHasBeenChanged")}
                        </Text>
                      )}
                    </VStack>
                  </Form>
                )}
              </Formik>
            </Flex>
          </VStack>
        </Flex>

        <Image
          src={footerText}
          bottom="30px"
          right="50px"
          position="absolute"
          width={{ base: "100px", md: "120px", lg: "150px" }}
          height={{ base: "auto", md: "auto", lg: "20px" }}
        />
      </Box>
      <AuthBg />
    </Flex>
  );
};

export default ResetPasswordComplete;
