import { React, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";

import { TbGasStation } from "react-icons/tb";
import {
  PiArrowsLeftRightBold,
  PiPlusBold,
  PiQuestionBold,
  PiArrowsDownUpBold,
  PiUserBold,
  PiWalletBold,
  PiCheckBold,
  PiCopySimpleBold,
  PiUploadSimpleBold,
  PiListBold,
  PiSealCheckBold,
} from "react-icons/pi";
import { FiPieChart, FiTool } from "react-icons/fi";
import { RiRobot2Line } from "react-icons/ri";
import {
  useMediaQuery,
  Box,
  Grid,
  GridItem,
  VStack,
  Icon,
  Divider,
  Text,
  Image,
  Button,
  IconButton,
  Avatar,
  Flex,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  Spacer,
  PopoverTrigger,
  PopoverContent,
  Popover,
  PopoverBody,
  PopoverFooter,
  useClipboard,
} from "@chakra-ui/react";

import LogoTextIcon from "./../static/polywallet-logo.svg";
import LogoIcon from "./../static/icons/logo_icon.svg";
import FooterText from "./../static/icons/footer-text.svg";

import Notifications from "./Notifications";

import QRCode from "react-qr-code";

// SidebarItem component
const SidebarItem = ({
  icon,
  children,
  to,
  showText,
  fontSize,
  onClick,
  isActive,
}) => {
  const [isMobile] = useMediaQuery("(max-width: 480px)");
  const isExternalLink = to.startsWith("http://") || to.startsWith("https://");
  const LinkElement = isExternalLink ? "a" : Link;
  const linkProps = isExternalLink
    ? { href: to, target: "_blank", rel: "noopener noreferrer" }
    : { to };

  return (
    <LinkElement {...linkProps} onClick={onClick}>
      <Flex justifyContent="center" width="full">
        <Box
          bg={isActive ? "#1E1E1E" : "transparent"}
          px={isMobile ? "0px" : "24px"}
          py={"10px"}
          display="flex"
          alignItems="center"
          justifyContent={showText ? "flex-start" : "center"}
          color="white"
          width={isMobile ? "90%" : "90%"}
          position="relative"
          _hover={{
            bg: "#1E1E1E",
          }}
          transition="all 0.2s ease-in-out"
          borderRadius={isMobile ? "20px" : "25px"}
        >
          <Icon
            as={icon}
            style={{
              marginRight: showText ? "20px" : "0",
              width: "22px",
              height: "22px",
            }}
            aria-label={children}
          />

          {showText && (
            <Text fontSize={fontSize} fontWeight="bold">
              {children}
            </Text>
          )}
        </Box>
      </Flex>
    </LinkElement>
  );
};

// Main component
const Main = () => {
  const { t } = useTranslation();

  const token = localStorage.getItem("token");
  if (!token) {
    window.location.href = "/login";
  }

  const [isMobile] = useMediaQuery("(max-width: 480px)");
  const [isDesktop] = useMediaQuery("(min-width: 1025px)");

  const location = useLocation();
  const navigate = useNavigate();

  const isActive = (itemPath) => location.pathname === itemPath;

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  let sidebarWidth = isDesktop ? "240px" : isMobile ? "0" : "94px";

  const user = localStorage.getItem("username");
  const kycVerified = localStorage.getItem("kycVerified") === "true";

  const userAddress = localStorage.getItem("userAddress");
  const { hasCopied, onCopy } = useClipboard(userAddress);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  const handleTransferClick = () => {
    navigate("/transfer");
  };

  const handleLogoClick = () => {
    console.log("Logo clicked");
    navigate("/wallet");
  };

  function shortenAddress(address) {
    const match = address.match(/^0x[a-fA-F0-9]{4}/);
    const lastFour = address.slice(-4);
    return `${match[0]}…${lastFour}`;
  }

  const sidebarItems = [
    { icon: PiWalletBold, label: t("wallet"), to: "/wallet" },
    { icon: FiPieChart, label: t("portfolio"), to: "/portfolio" },
    { icon: FiTool, label: t("tools"), to: "/tools" },
    { icon: PiArrowsLeftRightBold, label: t("swap"), to: "/swap" },
    { icon: PiPlusBold, label: t("buy"), to: "/buy" },
    /* { icon: RiRobot2Line, label: t("PolyAI"), to: "/poly-ai" }, */
    { icon: PiUserBold, label: t("account"), to: "/account" },
  ];
  const bottomSidebarItems = [
    /*     { icon: TbGasStation, label: t("Gas Meter"), to: "/gas-meter" }, */
    {
      icon: PiQuestionBold,
      label: t("support"),
      to: "https://support.polywallet.app",
    },
  ];
  return (
    <Box position="relative" h="100vh">
      <Grid
        h="100vh"
        templateAreas={`"header header"
                        "main main"
                        "footer footer"`}
        gridTemplateRows={{ base: "80px 1fr 60px", md: "100px 1fr 60px" }}
        // Grid Sizes
        gridTemplateColumns="1fr"
        ml={sidebarWidth}
        transition="margin 0.2s"
        color="white"
        fontWeight="bold"
      >
        <GridItem
          bg="#010101"
          area="header"
          zIndex="2"
          h="100%"
          //boxShadow="0 0px 14px 1px black"
        >
          <Flex
            alignItems="center"
            justifyContent={isMobile ? "space-between" : "end"}
            h="100%"
            mx="30px"
          >
            {isMobile && (
              <>
                <IconButton
                  icon={<PiListBold color="white" w="22px" h="22px" />}
                  _hover={{ bg: "#333333" }}
                  _active={{ bg: "#222222" }}
                  aria-label="Open menu"
                  variant="ghost"
                  onClick={toggleMobileMenu}
                />
                <Drawer
                  isOpen={mobileMenuOpen}
                  placement="left"
                  onClose={toggleMobileMenu}
                >
                  <DrawerOverlay />
                  <DrawerContent
                    maxW="100px"
                    bg="#010101"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Image
                      src={LogoIcon}
                      mt="28px"
                      mb="60px"
                      w="40px"
                      h="40px"
                      onClick={handleLogoClick}
                      cursor={"pointer"}
                    />
                    <DrawerBody
                      px={4}
                      width="full"
                      display="flex"
                      flexDirection="column"
                      height="100%"
                    >
                      {sidebarItems.slice(0, -1).map((item, index) => (
                        <Box mb="12px" key={index}>
                          <SidebarItem
                            icon={item.icon}
                            to={item.to}
                            showText={false}
                            isActive={isActive(item.to)}
                            onClick={() => isMobile && setMobileMenuOpen(false)}
                          />
                        </Box>
                      ))}
                      <Box flex="1"></Box>{" "}
                      {sidebarItems.length > 0 && (
                        <Box mb="12px">
                          <SidebarItem
                            icon={sidebarItems[sidebarItems.length - 1].icon}
                            to={sidebarItems[sidebarItems.length - 1].to}
                            showText={false}
                            onClick={() => isMobile && setMobileMenuOpen(false)}
                          />
                        </Box>
                      )}
                    </DrawerBody>
                  </DrawerContent>
                </Drawer>
              </>
            )}
            {!isMobile && (
              <VStack
                position="absolute"
                left={0}
                top={0}
                bottom={0}
                width={sidebarWidth}
                bg="#010101"
                zIndex="docked"
                color="white"
                align="stretch"
                spacing="4px" //sidebar title spacing
                display="flex"
                flexDirection="column"
                height="100%"
              >
                {isDesktop ? (
                  <Image
                    src={LogoTextIcon}
                    mt="28px"
                    px="30px"
                    mb="60px"
                    onClick={handleLogoClick}
                    sx={{ cursor: "pointer" }}
                  />
                ) : (
                  <Box
                    display="flex"
                    justifyContent="center"
                    width="full"
                    mb="60px"
                  >
                    <Image
                      src={LogoIcon}
                      w="40px"
                      h="40px"
                      mt="28px"
                      color="white"
                      onClick={handleLogoClick}
                      cursor={"pointer"}
                    />
                  </Box>
                )}
                {sidebarItems.map((item, index) => (
                  <Box key={index}>
                    <SidebarItem
                      icon={item.icon}
                      to={item.to}
                      showText={isDesktop}
                      fontSize="18px"
                      isActive={isActive(item.to)}
                    >
                      {item.label}
                    </SidebarItem>
                    {item.label === t("wallet") && (
                      <Divider borderColor="#2B2B2B" my="2" />
                    )}
                    {item.label === t("PolyAI") && (
                      <Divider borderColor="#2B2B2B" my="2" />
                    )}
                  </Box>
                ))}

                <Spacer />
                <Divider mb="2" borderColor="#2B2B2B" />

                {bottomSidebarItems.map((item, index) => (
                  <Box
                    key={`bottom-${index}`}
                    mb={index === bottomSidebarItems.length - 1 ? "22px" : "0"}
                  >
                    <SidebarItem
                      icon={item.icon}
                      to={item.to}
                      showText={isDesktop}
                      fontSize="18px"
                      isActive={isActive(item.to)}
                    >
                      {item.label}
                    </SidebarItem>
                  </Box>
                ))}
              </VStack>
            )}

            <Flex w="auto" justifyContent="end" align="center">
              {isMobile ? (
                <IconButton
                  variant={"pvGrayNoneWidth"}
                  onClick={handleTransferClick}
                  icon={<PiArrowsDownUpBold />}
                  height={isMobile ? "42px" : "48px"}
                  width={isMobile ? "42px" : "48px"}
                />
              ) : (
                <Button
                  variant="pvGray"
                  onClick={handleTransferClick}
                  leftIcon={<PiArrowsDownUpBold />}
                  height={isMobile ? "42px" : "48px"}
                >
                  {t("send")} / {t("receive")}
                </Button>
              )}

              <Notifications />
              <Popover
                isOpen={isPopoverOpen}
                onClose={() => setPopoverOpen(false)}
              >
                <PopoverTrigger>
                  <Button
                    mr={isMobile ? "0" : "4"}
                    p="0"
                    variant="ghost"
                    _hover={{ bg: "transparent" }}
                    _active={{ bg: "transparent" }}
                    onClick={() => setPopoverOpen(true)}
                  >
                    <Avatar
                      width="40px"
                      height="40px"
                      bg="#753CC5"
                      _hover={{ bg: "#AC79F3" }}
                    />
                  </Button>
                </PopoverTrigger>
                <PopoverContent
                  mt={["3", "4", "5"]}
                  border="1px solid #333"
                  borderRadius="6px"
                  mr="5"
                  bg="#111"
                  width="auto"
                  maxW="230px"
                  pt="2"
                >
                  <PopoverBody>
                    <Text
                      px="2"
                      mb="1"
                      mr="6"
                      fontSize={isMobile ? "16px" : "18px"}
                      isTruncated
                    >
                      {user}
                    </Text>
                    <Box
                      p="1"
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Flex
                        alignItems="center"
                        justifyContent="center"
                        bg="white"
                        borderRadius="md"
                        p="1"
                        m="3"
                        w="100px"
                        h="100px"
                      >
                        <QRCode
                          value={userAddress || ""}
                          size={90}
                          bgColor="white"
                          fgColor="black"
                        />
                      </Flex>
                      <Flex alignItems="center" justifyContent="space-between">
                        <Text
                          fontWeight="normal"
                          fontSize={isMobile ? "13px" : "15px"}
                          mr="1"
                        >
                          {shortenAddress(userAddress)}
                        </Text>
                        <IconButton
                          icon={
                            hasCopied ? (
                              <PiCheckBold h="5" w="5" color="white" />
                            ) : (
                              <PiCopySimpleBold h="5" w="5" color="white" />
                            )
                          }
                          size="5"
                          variant="ghost"
                          _hover={{ bg: "#333" }}
                          onClick={onCopy}
                        />
                      </Flex>
                    </Box>
                    {/* Account Button */}
                    <Button
                      p="2"
                      w="full"
                      _hover={{ bg: "#333" }}
                      _active={{ bg: "#222" }}
                      variant="ghost"
                      color="white"
                      onClick={() => {
                        navigate("/account");
                        setPopoverOpen(false);
                      }}
                    >
                      <Text
                        fontWeight="medium"
                        fontSize={isMobile ? "14px" : "16px"}
                      >
                        {t("account")}
                      </Text>
                      <Spacer />
                      {kycVerified ? (
                        <>
                          <Text
                            color="#5263F0"
                            fontSize={isMobile ? "13px" : "14px"}
                            mr={1}
                          >
                            {t("verified")}
                          </Text>
                          <PiSealCheckBold
                            color="5263F0"
                            width="20px"
                            height="20px"
                          />
                        </>
                      ) : (
                        <Text
                          color="#F14F44"
                          fontWeight="normal"
                          fontSize={isMobile ? "15px" : "15px"}
                        >
                          {t("unverified")}
                        </Text>
                      )}
                    </Button>
                  </PopoverBody>
                  <PopoverFooter px="3" borderTop="1px solid #333">
                    <Button
                      justifyContent="start"
                      width="full"
                      _hover={{ bg: "#333" }}
                      _active={{ bg: "#222" }}
                      color="white"
                      variant="ghost"
                      px="2"
                      onClick={handleLogout}
                      fontWeight="medium"
                      fontSize={isMobile ? "14px" : "16px"}
                    >
                      <PiUploadSimpleBold
                        color="white"
                        style={{ transform: "rotate(90deg)" }}
                      />
                      <Text ml="1">{t("logOut")}</Text>
                    </Button>
                  </PopoverFooter>
                </PopoverContent>
              </Popover>
            </Flex>
          </Flex>
        </GridItem>

        <GridItem
          bg="#111111"
          zIndex="1"
          area="main"
          overflowY="auto"
          py={isMobile ? "4" : "6"}
          px={isMobile ? "6" : "8"}
        >
          <Outlet />
        </GridItem>

        <GridItem bg="#010101" area="footer" h="60px">
          <Flex
            pr="10%"
            textAlign="center"
            alignItems="center"
            justifyContent="end"
            h="100%"
          >
            <Image src={FooterText} h={["16px", "18px", "20px"]} />
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Main;
