import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";

import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Image,
  Flex,
  Box,
  Divider,
  Text,
  useClipboard,
  IconButton,
} from "@chakra-ui/react";

import { shortenAddress } from "../../services/Helpers";
import { System } from "polyverse-sdk-dev/dist/api/system";
import { PiCaretDownBold, PiCheckBold, PiCopySimpleBold } from "react-icons/pi";

function Receive() {
  const location = useLocation();
  const { t } = useTranslation();

  const [networks, setNetworks] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const userAddress = localStorage.getItem("userAddress");
  const { hasCopied, onCopy } = useClipboard(userAddress);

  useEffect(() => {
    const fetchNetworksAndSetSelected = async () => {
      try {
        const networksData = await System.networks();

        let showTestNetValue = localStorage.getItem("showTestNet") === "true";

        const showTestNet =
          showTestNetValue === null ? false : showTestNetValue;

        const filteredNetworks = showTestNet
          ? networksData
          : networksData.filter((network) => !network.isTestNet);

        setNetworks(filteredNetworks);
        // URL'deki query parametrelerini al
        const queryParams = new URLSearchParams(location.search);
        const networkFromURL = queryParams.get("network");

        let networkToSelect;
        if (networkFromURL) {
          // Eğer URL'den bir network alındıysa, bu network'u listede bul
          networkToSelect = networksData.find(
            (network) => network.key === networkFromURL
          );
        }

        // Eğer URL'den uygun bir network bulunamazsa veya URL'de network yoksa, varsayılan olarak 'eth' ağını seç
        if (!networkToSelect) {
          networkToSelect = networksData.find((n) => n.key === "eth");
        }

        if (networkToSelect) {
          setSelectedNetwork(networkToSelect);
        }
      } catch (error) {
        console.error("Error fetching networks:", error);
      }
    };

    fetchNetworksAndSetSelected();
  }, [location]); // Bağımlılık olarak location ekleniyor

  useEffect(() => {
    async function fetchNetworks() {
      try {
        const fetchedNetworks = await System.networks();
        let showTestNetValue = localStorage.getItem("showTestNet") === "true";
        const showTestNet =
          showTestNetValue === null ? false : showTestNetValue;
        const filteredNetworks = showTestNet
          ? fetchedNetworks
          : fetchedNetworks.filter((network) => !network.isTestNet);
        setNetworks(filteredNetworks);
      } catch (error) {
        console.error("Error fetching networks", error);
      }
    }
    fetchNetworks();
  }, []);

  const handleNetworkSelect = (network) => {
    setSelectedNetwork(network);
  };

  return (
    <>
      <Menu matchWidth>
        <MenuButton
          w="full"
          h="9"
          as={Button}
          variant="outline"
          color="white"
          fontSize={["sm", "md", "md"]}
          border="2px solid #333"
          borderRadius="lg"
          bg="#111"
          _hover={{ bg: "#1E1E1E" }}
          _active={{ bg: "#111111" }}
        >
          <Flex justifyContent="space-between" align="center">
            {selectedNetwork ? selectedNetwork.name : "Select Network"}
            <PiCaretDownBold boxSize="3" />
          </Flex>
        </MenuButton>
        <MenuList
          bg="#111"
          border="2px solid #333"
          w="full"
          mt="2"
          maxH="250px"
          overflowY="auto"
        >
          {networks.map((network) => (
            <MenuItem
              key={network.name}
              borderRadius="lg"
              _hover={{ bg: "#1E1E1E" }}
              _active={{ bg: "#1E1E1E" }}
              onClick={() => handleNetworkSelect(network)}
              bg={selectedNetwork?.name === network.name ? "#1E1E1E" : "#111"}
            >
              <Flex align="center">
                <Box w="4" mr="2">
                  {selectedNetwork?.name === network.name && (
                    <PiCheckBold color="white" boxSize="4" />
                  )}
                </Box>
                <Image
                  src={`data:image/svg+xml;base64,${network.icon}`}
                  boxSize="18px"
                  mr="2"
                />
                {network.name}
              </Flex>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      <Flex justifyContent="center" mt={["6", "8", "10"]}>
        <Box
          align="center"
          bg="#111"
          borderRadius="md"
          border="1px solid #333"
          w="250px"
          h="292px"
        >
          <Flex
            alignItems="center"
            justifyContent="center"
            bg="white"
            borderRadius="md"
            p="2"
            m="7"
            w="155px"
            h="155px"
          >
            <QRCode
              value={userAddress || ""}
              size={140}
              bgColor="white"
              fgColor="black"
            />
          </Flex>
          <Divider borderColor="#333" />
          <Box p="4">
            <Text fontSize="sm" align="start">
              {selectedNetwork
                ? `${selectedNetwork.name} ${t("address")}`
                : t("address")}
            </Text>
            <Flex alignItems="center" justifyContent="space-between">
              <Text color="#888" fontSize="sm">
                {shortenAddress(userAddress)}
              </Text>
              <IconButton
                icon={
                  hasCopied ? (
                    <PiCheckBold color="white" h="5" w="5" />
                  ) : (
                    <PiCopySimpleBold h="5" w="5" color="white" />
                  )
                }
                size="5"
                variant="ghost"
                _hover={{ bg: "#333" }}
                onClick={onCopy}
              />
            </Flex>
          </Box>
        </Box>
      </Flex>
    </>
  );
}

export default Receive;
