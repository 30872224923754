// AnimatedGradientTick.js
import React from "react";
import { Box, keyframes } from "@chakra-ui/react";
import ConfirmTickIcon from "./../../static/icons/confirm-tick.svg"; // Update the path as necessary

const gradient = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const ConfirmTick = () => {
  return (
    <Box
      position="relative"
      w={["90px", "100px", "110px"]}
      h={["90px", "100px", "110px"]}
      bgGradient="linear(to-l, #0CEBCC, #5263F0, #DB72EE, #FD9F65)"
      backgroundSize="200% 200%"
      animation={`${gradient} 3s ease infinite`}
      borderRadius="full"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        as="img"
        src={ConfirmTickIcon}
        alt="Confirm Tick"
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        boxSize="100%" // Adjust the size as needed
      />
    </Box>
  );
};

export default ConfirmTick;
