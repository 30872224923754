import React, { useEffect, useState, useCallback } from "react";
import numeral from "numeral";

import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Image,
  Flex,
  Box,
  Text,
  Avatar,
  Input,
  Spinner,
  Center,
  FormLabel,
  useToast,
  Tooltip,
} from "@chakra-ui/react";

import { System } from "polyverse-sdk-dev/dist/api/system";
import { User } from "polyverse-sdk-dev/dist/api/user";
import { Wallet } from "polyverse-sdk-dev/dist/api/wallet";
import { Security } from "polyverse-sdk-dev/dist/api/security";

import ConfirmTick from "./ConfirmTick";

import {
  PiCaretDownBold,
  PiCheckBold,
  PiShieldCheckBold,
  PiShieldSlashBold,
  PiShieldWarningBold,
} from "react-icons/pi";

function Send() {
  const { t } = useTranslation();
  const toast = useToast();

  const currency = localStorage.getItem("userCurrency") || "usd";

  const [networks, setNetworks] = useState([]);
  const [tokens, setTokens] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState("");
  const [selectedToken, setSelectedToken] = useState("");
  const [amount, setAmount] = useState("");
  const [address, setAddress] = useState("");
  const [tokenBalances, setTokenBalances] = useState({});
  const [isBalanceInsufficient, setIsBalanceInsufficient] = useState(false);
  const [gasFee, setGasFee] = useState("");
  const [isTokensLoading, setIsTokensLoading] = useState(false);
  const [totalFeeInUSDT, setTotalFeeInUSDT] = useState(null);
  const [isCalculating, setIsCalculating] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [securityInfo, setSecurityInfo] = useState({
    rate: null,
    riskResult: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  const [formattedAmount, setFormattedAmount] = useState("");

  const [isFocused, setIsFocused] = useState(false);
  const navigate = useNavigate();
  const maxLength = 10;
  const [isReadyToSend, setIsReadyToSend] = useState(false);

  const getFontSize = (valueLength) => {
    if (valueLength <= 5) {
      return ["2xl", "3xl", "4xl"];
    } else if (valueLength <= 7) {
      return ["xl", "2xl", "3xl"];
    } else {
      return ["xl", "2xl", "3xl"];
    }
  };

  const location = useLocation();

  useEffect(() => {
    const fetchAndSetNetworksAndTokens = async () => {
      try {
        const networksData = await User.networks();
        let showTestNetValue = localStorage.getItem("showTestNet") === "true";
        const showTestNet =
          showTestNetValue === null ? false : showTestNetValue;
        const filteredNetworks = showTestNet
          ? networksData
          : networksData.filter((network) => !network.isTestNet);
        setNetworks(filteredNetworks);

        // URL'deki query parametrelerini al
        const queryParams = new URLSearchParams(location.search);
        const networkFromURL = queryParams.get("network");
        const tokenFromURL = queryParams.get("token");

        // Eğer URL'den bir network alındıysa, bu network'u listede bul ve ayarla
        if (networkFromURL) {
          const matchingNetwork = networksData.find(
            (network) => network.key === networkFromURL
          );
          if (matchingNetwork) {
            setSelectedNetwork(matchingNetwork);
            await checkBalanceAndTokens(matchingNetwork.key);
          }
        }

        // Token kontrolü ve ayarlaması
        if (tokenFromURL && tokens.length > 0) {
          const matchingToken = tokens.find(
            (token) => token.symbol === tokenFromURL
          );
          if (matchingToken) {
            setSelectedToken(matchingToken.symbol);
          }
        }
      } catch (error) {
        console.error("Network and Token fetch error:", error);
      }
    };

    fetchAndSetNetworksAndTokens();
  }, [location]);

  //bakiyeden fazla amount girilmesi kontrolü
  useEffect(() => {
    if (selectedToken && amount > 0) {
      const currentBalance = tokenBalances[selectedToken] || 0;
      setIsBalanceInsufficient(amount > currentBalance);
    }
  }, [amount, selectedToken, tokenBalances]);

  useEffect(() => {
    const isAmountValid = amount && amount > 0;
    const addressType = checkAddressType(address);

    const readyToSend =
      selectedNetwork &&
      selectedToken &&
      address &&
      isAmountValid &&
      (addressType === "web3" ||
        addressType === "phone" ||
        addressType === "email");

    setIsReadyToSend(readyToSend);

    if (readyToSend && addressType === "web3") {
      if (selectedToken === tokens[0].symbol) {
        calculateTransferGas(selectedNetwork.key, address, amount);
      } else {
        const tokenData = tokens.find(
          (token) => token.symbol === selectedToken
        );
        if (tokenData) {
          calculateTransferTokenGas(
            selectedNetwork.key,
            tokenData.contract,
            address,
            amount
          );
        }
      }
    } else {
      setGasFee("");
      setTotalFeeInUSDT(null);
    }
  }, [selectedNetwork, selectedToken, amount, address, tokens]);

  useEffect(() => {
    setFormattedAmount(formatCurrency(amount));
  }, [amount]);

  const formatCurrency = (value) => {
    if (typeof value !== "string") {
      value = String(value);
    }
    // Determine if value contains a decimal point and how many decimal places
    const parts = value.split(".");
    const decimals = parts[1]?.length || 0;
    if (decimals === 0) {
      // If there are no decimals, format as a number with two decimal places
      return numeral(value).format("0,0.00");
    } else if (decimals <= 9) {
      // If there are decimals, format with up to nine decimal places without adding extra
      return numeral(value).format(`0,0.${"0".repeat(decimals)}`);
    } else {
      // If more than nine decimal places, truncate to nine
      return numeral(value).format("0,0.000000000");
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
    // Format the amount on blur
    setAmount(formatCurrency(amount));
  };

  const handleChange = (event) => {
    if (isFocused) {
      let value = event.target.value.replace(/[^0-9.]/g, "");
      if (value.length <= maxLength) {
        setAmount(value); // Update the raw amount if within max length
      }
    }
  };

  //Hesaplanan gas fee'nin usdt cinsinden verilmesi
  useEffect(() => {
    const fetchPriceAndCalculateFee = async () => {
      if (selectedNetwork && gasFee) {
        setIsCalculating(true);
        try {
          const pair = `${selectedNetwork.symbol} USDT`;
          const price = await checkPrice(pair);

          if (price) {
            const totalFeeInUSDTValue = gasFee * price;
            // Check if the amount is valid before setting the total fee
            if (amount > 0 && amount !== "" && amount !== null) {
              setTotalFeeInUSDT(totalFeeInUSDTValue.toFixed(2));
            } else {
              setTotalFeeInUSDT(null); // If the amount is invalid, don't show calculation result
            }
          } else {
            setTotalFeeInUSDT(null); // If price information can't be fetched, show nothing
          }
        } catch (error) {
          console.error("Error in fetching price and calculating fee: ", error);
          setTotalFeeInUSDT(null); // reset total fee
        } finally {
          setIsCalculating(false);
        }
      } else {
        setTotalFeeInUSDT(null);
      }
    };

    fetchPriceAndCalculateFee();
  }, [selectedNetwork, gasFee, amount]);

  //#region handlers
  const handleNetworkChange = (network) => {
    if (network.key !== selectedNetwork.key) {
      setSelectedNetwork(network);
      console.log("network ", network);
      setSelectedToken("");
      checkBalanceAndTokens(network.key); // Burada network objesinin "name" özelliğini kullanın
    }
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);

    const addressType = checkAddressType(e.target.value);
    if (addressType === "phone" && !e.target.value.startsWith("+")) {
      // "+" işareti ile başlamayan telefon numarası için açıklayıcı bir hata toast mesajı göster
      toast({
        title: t("invalidPhoneNumber.title"),
        description: t("invalidPhoneNumber.description"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  //max butonuna basınca bakiyeyi amount alanına yerlestir
  const handleMaxAmount = () => {
    const isNativeCurrency = (selectedTokenSymbol) => {
      const tokenData = tokens.find(
        (token) => token.symbol === selectedTokenSymbol
      );
      return tokenData && tokenData.contract === null;
    };

    // Sonra maxAmount hesaplarken bu koşulu kontrol edin
    const originalAmount = tokenBalances[selectedToken] || 0;
    let maxAmount;

    if (isNativeCurrency(selectedToken)) {
      // Eğer native currency ise, %0.4 azalt
      maxAmount = originalAmount - originalAmount * 0.05;
    } else {
      // Eğer ERC20 token ise, orijinal miktarı kullan
      maxAmount = originalAmount;
    }
    setAmount(maxAmount);
    console.log(maxAmount, tokenBalances[selectedToken]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const addressType = checkAddressType(address);
    try {
      if (addressType === "web3") {
        await performTransfer(address);
      } else if (addressType === "email" || addressType === "phone") {
        if (addressType === "phone") {
          if (!address.startsWith("+")) {
            toast({
              title: t("invalidPhoneNumber.title"),
              description: t("invalidPhoneNumber.description"),
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          } else {
            // "+" ile başlayan telefon numarasını "00" ile başlayan formata dönüştür
            const modifiedAddress = address.replace("+", "00");

            const profileResult = await handleProfile(
              addressType,
              modifiedAddress
            ); // modifiedAddress'i kullan
            if (profileResult.walletAddress) {
              await performTransfer(
                profileResult.walletAddress,
                profileResult.isNewUser,
                profileResult.generatedPassword
              );
              console.log(profileResult.walletAddress);
            }
          }
        } else {
          const profileResult = await handleProfile(addressType, address);
          if (profileResult.walletAddress) {
            await performTransfer(
              profileResult.walletAddress,
              profileResult.isNewUser,
              profileResult.generatedPassword
            );
            console.log(profileResult.walletAddress);
          }
        }
      } else {
        console.error("Invalid address type.");
      }
    } catch (error) {
      console.error("Transfer error:", error);
    } finally {
      setIsLoading(false); // İşlem bittiğinde
    }
  };
  //#endregion

  useEffect(() => {
    async function fetchNetworks() {
      try {
        const fetchedNetworks = await User.networks();
        let showTestNetValue = localStorage.getItem("showTestNet") === "true";
        const showTestNet =
          showTestNetValue === null ? false : showTestNetValue;
        const filteredNetworks = showTestNet
          ? fetchedNetworks
          : fetchedNetworks.filter((network) => !network.isTestNet);
        setNetworks(filteredNetworks);
      } catch (error) {
        console.error("Error fetching networks", error);
      }
    }
    fetchNetworks();
  }, []);

  const checkBalanceAndTokens = async (networkKey) => {
    setIsTokensLoading(true);
    try {
      const balanceResponse = await Wallet.balance(networkKey, currency);
      const erc20Tokens = balanceResponse.tokens
        .filter((token) => token.type === "ERC20")
        .map((token) => ({
          symbol: token.symbol,
          contract: token.contract,
          balance: token.balance,
        }));

      // Ana para biriminin bakiye bilgisini ekleyin
      erc20Tokens.unshift({
        symbol: balanceResponse.balance.symbol,
        contract: null,
        balance: balanceResponse.balance.balance,
      });

      setTokens(erc20Tokens);
      const newBalances = {};
      erc20Tokens.forEach((token) => {
        newBalances[token.symbol] = token.balance;
      });
      setTokenBalances(newBalances);

      // URL'den alınan token değerini kontrol edin
      const queryParams = new URLSearchParams(location.search);
      const tokenFromURL = queryParams.get("token");
      if (tokenFromURL) {
        const matchingToken = erc20Tokens.find(
          (token) => token.symbol === tokenFromURL
        );
        if (matchingToken) {
          setSelectedToken(matchingToken.symbol);
        }
      }

      setIsTokensLoading(false);
    } catch (error) {
      console.error("Checking balance and tokens error:", error);
      setIsTokensLoading(false);
    }
  };

  //tranfer tipine karar ver ve transferi tamamlayıp ilgili bildirim kanalını çalıştır
  const performTransfer = async (
    walletAddress,
    isNewUser,
    generatedPassword
  ) => {
    const isMainCurrency = selectedToken === tokens[0].symbol;
    const contract = tokens.find(
      (token) => token.symbol === selectedToken
    )?.contract;

    try {
      if (isMainCurrency) {
        await transfer(selectedNetwork.key, walletAddress, amount);
      } else {
        await transferToken(
          selectedNetwork.key,
          contract,
          walletAddress,
          amount
        );
      }
      // Transfer başarılıysa email-sms gönderimi
      const message = isNewUser
        ? t("message.welcomeNewUser", { password: generatedPassword })
        : t("message.newTransfer");
      sendNotification(address, message);

      // Başarılı işlem mesajı ve durum sıfırlama işlemleri
    } catch (error) {
      console.error("Transfer error:", error);
      // Hata mesajı ve durum sıfırlama işlemleri
    }
  };

  //profileByEmail ve profileByPhone fonksiyonları için ortak işleyiş
  const handleProfile = async (type, value) => {
    try {
      const profileData =
        type === "email"
          ? await User.profileByEmail(2251, "wxcguoTzCmuuBdezpAVElaCeZd", value)
          : await User.profileByPhone(
              2251,
              "wxcguoTzCmuuBdezpAVElaCeZd",
              value
            );

      return { walletAddress: profileData.walletAddress, isNewUser: false };
    } catch (error) {
      console.error(`${type} query error:`, error);
      //profile bulunamazsa varolan bilgilerle userCreate ile yeni user yarat
      if (error.message.includes("Sequence contains no elements")) {
        return userCreate(
          type === "email" ? value : null,
          type === "phone" ? value : null
        );
      }
      return { walletAddress: null, isNewUser: false };
    }
  };

  const userCreate = async (email, phone) => {
    const generatedPassword = generateRandomPassword();
    const name = email
      ? email.split("@")[0]
      : `User${phone.substring(0, 3)}...${phone.slice(-3)}`;

    const userData = {
      email,
      password: generatedPassword,
      name,
      phone,
      projectId: 2251,
      projectSecret: "wxcguoTzCmuuBdezpAVElaCeZd",
    };

    try {
      const createdUser = await User.create(
        userData.email,
        userData.password,
        userData.name,
        userData.phone,
        "",
        userData.projectId,
        userData.projectSecret
      );

      if (createdUser && createdUser.walletAddress) {
        return {
          walletAddress: createdUser.walletAddress,
          isNewUser: true,
          generatedPassword,
        };
      }
      return { walletAddress: null, isNewUser: false };
    } catch (error) {
      console.error("User creation error:", error);
    }
    return null;
  };

  //#region notifications
  const sendNotification = async (address, message) => {
    const addressType = checkAddressType(address);
    if (addressType === "email") {
      await sendEmail(address, message);
    } else if (addressType === "phone") {
      await sendSMS(address, message);
    }
  };

  const sendEmail = async (email, message) => {
    try {
      const emailResponse = await System.email(
        email,
        t("emailTransferNotification"),
        message
      );
      console.log("Email sent successfully", emailResponse);
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  const sendSMS = async (phone, message) => {
    try {
      const smsResponse = await System.SMS(phone, message);
      console.log("SMS sent successfully:", smsResponse);
    } catch (error) {
      console.error("Error sending SMS:", error);
    }
  };
  //#endregion

  //#region gasprices
  const calculateTransferGas = async (network, walletAddress, amount) => {
    try {
      const transferResponse = await Wallet.transfer(
        network,
        walletAddress,
        amount,
        undefined,
        true
      );
      setGasFee(transferResponse.gas.estimateTransactionFee);
    } catch (error) {
      console.error("Transfer error:", error);
      setGasFee(""); // Hata durumunda gas ücretini sıfırla
    }
  };

  const calculateTransferTokenGas = async (
    network,
    contract,
    walletAddress,
    amount
  ) => {
    try {
      const transferTokenResponse = await Wallet.transferToken(
        network,
        contract,
        walletAddress,
        amount,
        undefined,
        true
      );
      setGasFee(transferTokenResponse.gas.estimateTransactionFee);
    } catch (error) {
      console.error("TransferToken error:", error);
      setGasFee(""); // Hata durumunda gas ücretini sıfırla
    }
  };
  //#endregion

  //#region transfers
  const transfer = async (network, walletAddress, amount) => {
    try {
      const transferResponse = await Wallet.transfer(
        network,
        walletAddress,
        amount,
        undefined,
        false
      );
      handleProceedToConfirm();
      console.log("Transfer successful:", transferResponse);
    } catch (error) {
      toast({
        title: t("transferError"),
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error("Transfer error:", error);
    }
  };

  const transferToken = async (network, contract, walletAddress, amount) => {
    try {
      const transferTokenResponse = await Wallet.transferToken(
        network,
        contract,
        walletAddress,
        amount,
        undefined,
        false
      );
      handleProceedToConfirm();
      console.log("TransferToken successful:", transferTokenResponse);
    } catch (error) {
      toast({
        title: t("transferError"),
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error("TransferToken error:", error);
    } finally {
    }
  };
  //#endregion

  const checkPrice = async (pair) => {
    try {
      const priceResponse = await System.price(pair);

      // priceResponse dizisinin ilk elemanını kontrol et
      if (priceResponse && priceResponse.length > 0) {
        const priceData = priceResponse[0];
        console.log(`Price: ${priceData.price}`);
        return priceData.price; // Fiyatı döndür
      } else {
        console.error("Fiyat bilgisi bulunamadı.");
        return null;
      }
    } catch (error) {
      console.error("Price Error: ", error);
      return null;
    }
  };

  const generateRandomPassword = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 8; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  //address tipi check etme
  const checkAddressType = (address) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const walletAddressRegex = /^0x[a-fA-F0-9]{40}$/;

    if (emailRegex.test(address)) {
      return "email";
    } else if (walletAddressRegex.test(address)) {
      return "web3";
    } else if (
      !isNaN(address) &&
      address.length >= 10 &&
      address.length <= 15
    ) {
      return "phone";
    } else {
      //console.error("Invalid address format.");
      return null;
    }
  };
  const handleProceedToConfirm = () => {
    // Perform any validation or state updates you need
    setIsConfirming(true);
  };

  const handleBackToSend = () => {
    setIsConfirming(false);
    setAmount(""); // Miktarı sıfırla
    setAddress(""); // Adresi sıfırla
    setTokens([]); // Token'ları sıfırla
    setSelectedNetwork(""); // Seçili ağı sıfırla
    setSelectedToken(""); // Seçili token'ı sıfırla
    setTokenBalances({}); // Token bakiyelerini sıfırla
    setIsBalanceInsufficient(false); // Bakiye yetersizlik durumunu sıfırla
    setGasFee(""); // Gaz ücretini sıfırla
    setIsTokensLoading(false); // Token yükleniyor durumunu sıfırla
    setTotalFeeInUSDT(null); // Toplam ücreti sıfırla
    setIsCalculating(false); // Hesaplama durumunu sıfırla
    setSecurityInfo({ rate: null, riskResult: null }); // Güvenlik bilgisini sıfırla
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const SecurityCheck = useCallback(
    async (walletAddress) => {
      try {
        const networkKey = selectedNetwork.key;
        const addressCheckResult = await Security.AddressCheck(
          networkKey,
          walletAddress,
          2251,
          "wxcguoTzCmuuBdezpAVElaCeZd" // Gerçek uygulama için projenizin gizli anahtarını buraya girin
        );
        // State'i güncelleyin
        setSecurityInfo({
          rate: addressCheckResult.rate,
          riskResult: addressCheckResult.riskResult,
        });
      } catch (err) {
        console.error(err);
      }
    },
    [selectedNetwork]
  );
  useEffect(() => {
    if (selectedNetwork && checkAddressType(address) === "web3") {
      SecurityCheck(address);
    }
  }, [address, selectedNetwork, SecurityCheck]);

  const getSecurityIcon = () => {
    if (securityInfo.rate === null) {
      return null; // Eğer rate null ise hiçbir şey gösterme
    } else if (securityInfo.rate <= 3) {
      return <PiShieldSlashBold color="red" size="20" />; // 0-3
    } else if (securityInfo.rate <= 7) {
      return <PiShieldWarningBold color="yellow" size="20" />; // 4-7
    } else {
      return <PiShieldCheckBold color="green" size="20" />; // 8-10
    }
  };

  return (
    <>
      {!isConfirming ? (
        <>
          <Menu matchWidth>
            <MenuButton
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              w="full"
              h="10"
              as={Button}
              variant="outline"
              color="white"
              fontSize={["sm", "md", "md"]}
              border="2px solid #333"
              borderRadius="lg"
              bg="#111"
              _hover={{ bg: "#1E1E1E" }}
              _active={{ bg: "#111111" }}
            >
              <Flex justifyContent="space-between" align="center">
                <Flex align="center">
                  {selectedNetwork ? (
                    <Image
                      src={`data:image/svg+xml;base64,${selectedNetwork.icon}`}
                      boxSize="18px"
                      mr="4"
                    />
                  ) : (
                    ""
                  )}
                  <Box
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    fontWeight="400"
                    fontSize="15px"
                  >
                    {selectedNetwork ? selectedNetwork.name : t("network")}
                  </Box>
                </Flex>
                <PiCaretDownBold boxSize="3" />
              </Flex>
            </MenuButton>
            <MenuList
              px="2"
              bg="#111"
              border="2px solid #333"
              w="full"
              mt="2"
              maxH="220px"
              overflowY="auto"
            >
              {networks.map((network) => (
                <MenuItem
                  my="1"
                  key={network.name}
                  borderRadius="lg"
                  _hover={{ bg: "#1E1E1E" }}
                  onClick={() => handleNetworkChange(network)}
                  bg={
                    selectedNetwork?.name === network.name ? "#1E1E1E" : "#111"
                  }
                >
                  <Flex
                    align="center"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    <Box w="4" mr="4">
                      {selectedNetwork?.name === network.name && (
                        <PiCheckBold color="white" boxSize="4" />
                      )}
                    </Box>
                    <Image
                      src={`data:image/svg+xml;base64,${network.icon}`}
                      boxSize="18px"
                      mr="2"
                    />
                    <Box
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      {network.name}
                    </Box>
                  </Flex>
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
          <Menu matchWidth>
            <MenuButton
              mt="4"
              w="full"
              h="10"
              as={Button}
              variant="outline"
              color="white"
              fontSize={["sm", "md", "md"]}
              border="2px solid #333"
              borderRadius="lg"
              bg="#111"
              _hover={{ bg: "#1E1E1E" }}
              _active={{ bg: "#111111" }}
              isDisabled={!selectedNetwork}
            >
              <Flex justifyContent="space-between" align="center">
                <Flex
                  align="center"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {selectedToken ? (
                    <Avatar
                      color="white"
                      src={
                        selectedToken.icon
                          ? `data:image/svg+xml;base64,${selectedToken.icon}`
                          : null
                      }
                      name={selectedToken}
                      size="xs"
                      w="18px"
                      h="18px"
                      mr="4"
                      bg={
                        selectedToken.icon ? "transparent" : selectedToken.color
                      }
                      style={{ fontSize: "10px" }}
                    />
                  ) : (
                    ""
                  )}
                  <Box
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    mr="2"
                    fontWeight="400"
                    fontSize="15px"
                  >
                    {selectedToken ? selectedToken : t("selectToken")}
                  </Box>
                </Flex>
                <PiCaretDownBold boxSize="3" />
              </Flex>
            </MenuButton>

            <MenuList
              px="2"
              bg="#111"
              border="2px solid #333"
              w="full"
              mt="1"
              maxH="220px"
              overflowY="auto"
            >
              {isTokensLoading ? (
                <Flex justify="center" align="center" h="20">
                  <Spinner size="md" color="#753CC5" />
                </Flex>
              ) : (
                tokens.map((token) => (
                  <MenuItem
                    my="1"
                    key={token.symbol}
                    borderRadius="lg"
                    bg={selectedToken === token.symbol ? "#1E1E1E" : "#111"}
                    _hover={{ bg: "#1E1E1E" }}
                    onClick={() => setSelectedToken(token.symbol)}
                  >
                    <Flex align="center" width="100%">
                      <Box w="4" mr="4">
                        {selectedToken === token.symbol && (
                          <PiCheckBold color="white" boxSize="4" />
                        )}
                      </Box>
                      <Avatar
                        src={
                          token.icon
                            ? `data:image/svg+xml;base64,${token.icon}`
                            : undefined
                        }
                        name={token.symbol}
                        size="xs"
                        w="18px"
                        h="18px"
                        mr="2"
                        bg={token.icon ? "transparent" : selectedToken.color}
                      />
                      <Box flex="1" overflow="hidden">
                        <Text isTruncated>{token.symbol}</Text>
                      </Box>
                    </Flex>
                  </MenuItem>
                ))
              )}
            </MenuList>
          </Menu>
          <Text
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            mt="2"
            align="end"
            color="#888"
            fontWeight="bold"
            fontSize="sm"
          >
            {selectedToken
              ? `Balance: ${
                  tokenBalances[selectedToken] !== undefined
                    ? tokenBalances[selectedToken].toFixed(5)
                    : "0.00"
                } ${selectedToken}`
              : "\u00A0"}
          </Text>
          <Flex
            justifyContent="center"
            mt={["8", "10"]}
            mb={["6", "8"]}
            position="relative"
          >
            <Input
              value={isFocused ? amount : formatCurrency(amount)}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              placeholder="0"
              textAlign="center"
              fontSize={getFontSize(amount.length)}
              fontWeight="bold"
              variant="unstyled"
              w="full"
            />
            <Button
              fontSize="sm"
              variant="pvGray"
              bg="#111"
              onClick={handleMaxAmount}
            >
              {t("max")}
            </Button>
          </Flex>
          <Flex
            direction="column"
            fontSize={["13px", "14px"]}
            fontWeight="medium"
            mb="4"
          >
            <Flex justifyContent="space-between">
              {(isCalculating || totalFeeInUSDT) && (
                <Text>Estimated Gas Fee:</Text>
              )}

              <Flex justifyContent="flex-end">
                {isCalculating ? (
                  <Text>Checking...</Text>
                ) : totalFeeInUSDT ? (
                  <Text>
                    {Number(gasFee).toFixed(8)} {selectedNetwork.symbol}
                  </Text>
                ) : (
                  <Text>{"\u00A0"}</Text>
                )}
              </Flex>
            </Flex>

            {totalFeeInUSDT && (
              <Flex justifyContent="flex-end" mt={1}>
                <Text
                  color="#888"
                  fontWeight="medium"
                  fontSize={["12px", "13px"]}
                >
                  {Number(totalFeeInUSDT).toFixed(4)} USDT
                </Text>
              </Flex>
            )}
          </Flex>
          <Flex align="center" mb="1">
            <FormLabel fontSize={"sm"} m="0" p="0">
              {t("to")}
            </FormLabel>
            {securityInfo.rate !== null && (
              <Tooltip
                placement="auto"
                label={`Rate: ${securityInfo.rate}, Risk: ${securityInfo.riskResult}`}
                fontSize="md"
              >
                <Box ml={2}>{getSecurityIcon()}</Box>
              </Tooltip>
            )}
          </Flex>

          <Input
            bg="#111"
            borderColor="#333"
            h="12"
            mt="1"
            mb="5"
            fontSize={["sm", "md"]}
            _hover={{ borderColor: "#666" }}
            placeholder={t("transferToPlaceHolder")}
            onChange={handleAddressChange}
          />

          <Flex
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Button
              mt="auto"
              mb={["2", "4"]}
              bg="#111"
              h="12"
              w="80%"
              color={
                isReadyToSend && !isBalanceInsufficient && !isLoading
                  ? "white"
                  : "#888"
              }
              variant="pvGray"
              fontWeight="medium"
              fontSize={["md", "lg"]}
              onClick={handleSubmit}
              disabled={isBalanceInsufficient || isLoading}
              style={{
                cursor:
                  isBalanceInsufficient || isLoading
                    ? "not-allowed"
                    : "pointer",
              }}
            >
              {isLoading ? (
                <Spinner size="sm" color="white" />
              ) : isBalanceInsufficient ? (
                `${t("insufficient")} ${t("balance")}`
              ) : (
                t("send")
              )}
            </Button>

            <Text
              mt={-1}
              mb={["2", "4"]}
              fontSize="15"
              color="#888"
              _hover={{ textDecoration: "underline" }}
              cursor="pointer"
              onClick={handleCancel} // Cancel işlemi için bir fonksiyon
            >
              {t("cancel")}
            </Text>
          </Flex>
        </> // Confirm - Transaction Details
      ) : (
        <Flex
          px={["6", "10", "12"]}
          justifyContent="center"
          align="center"
          textAlign="center"
          h="full"
          mt="8"
          direction="column"
        >
          <Center>
            <ConfirmTick />
          </Center>
          <Text fontSize="3xl" mt="6">
            {amount} {selectedToken}
          </Text>
          <Text
            color="#888"
            fontWeight="medium"
            fontSize={["sm", "sm", "md"]}
            mt="1"
          >
            {t("sentTo", { address })}
          </Text>
          <Text fontSize={["md", "md", "lg"]} mt="4" mb={["16", "16", "20"]}>
            {t("transactionSuccessful")}
          </Text>
          <Button
            bg="#111"
            w="full"
            variant="pvGray"
            onClick={handleBackToSend}
          >
            {t("done")}
          </Button>
        </Flex>
      )}
    </>
  );
}

export default Send;
